/** @format */

import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import {
    Autocomplete,
    Box,
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import './index.scss';
import TextField from '@mui/material/TextField';
import { saveOrUpdateRequest } from '../../services/apiUtils';
import { Alert } from '../../utils/toastr';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { renderSvg, svgIconName } from '../../assets/img/imageExt';

const style = {
    position: 'absolute' as 'absolute',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '90%',
    top: '50%',
    left: '43%',
    transform: 'translate(-39%, -48%)',
    width: '67%',
    paddingTop: '3%',
    paddingBottom: '2%',
    overflowX: 'auto',
    borderRadius: 4,
};

const useStyles = makeStyles({
    root: {
        padding: '4px 12px',
        borderRadius: '3px',
        backgroundColor: '#10384f',
        color: 'white',
        '&:hover': {
            padding: '4px 12px',
            borderRadius: '3px',
            backgroundColor: '#2e7d32',
            color: 'white',
        },
    },
    menu: {
        display: 'block',
        lineHeight: '12.5px',
        padding: '4px',
        paddingLeft: '15px',
    },
    checkbox: {
        display: 'flex',
        padding: '3px',
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            height: 66,
        },
    },
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function EditSuperAdminDetails(props: any) {
    const classes = useStyles();

    const [flag, setFlag] = React.useState(true);
    const [buttonText, setButtonText] = React.useState('edit details');
    const [textFieldDisable, setTextFieldDisable] = React.useState(true);

    const [roleID, setRoleID] = useState<any>(props.userDetail.role);
    const [userType, setUserType] = useState<String>(props.userDetail.usertype);
    const [status, setStatus] = useState<String>(props.userDetail.status);

    const [firstName, setFirstName] = useState<String>(
        props.userDetail.firstname
    );
    const [lastName, setLastName] = useState<String>(props.userDetail.lastname);
    const [email, setEmail] = useState<any>(props.userDetail.email);
    const [lob, setLob] = useState<any>(props.userDetail.lineofbusiness);

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState<any>();


    const [roleDetailsArray, setRoleDetailsArray] = useState<any[]>([]);

    useEffect(() => {
        setFirstName(props.userDetail.firstname);
        setLastName(props.userDetail.lastname);
        setEmail(props.userDetail.email);
        setLob(lob);
        setUserType(props.userDetail.usertype);
        setRoleID(props.userDetail.role);
        setStatus(props.userDetail.status);

        setFlag(true);
        setButtonText('edit details');
        setTextFieldDisable(true);
        let roleDetailArray: any = [];
        props?.userDetail?.assignedcountries?.forEach((detail: any) => {
            roleDetailArray.push({
                country: detail.countrycode,
                lob: detail.lineofbusiness,
                lobArr: props.lobMap.get(detail.countrycode),
            });
        });
        
        setRoleDetailsArray(roleDetailArray);
    }, [props.userDetail]);

    const handleFirstNameChange = (event: any) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: any) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handleRoleChange = (event: any) => {
        setRoleID(event.target.value);
    };

    const handlePasswordChange= (event:any) => {
        setPassword(event.target.value)
    }

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value);
    };
    const handleCountryChange = (event: any, index: any) => {
        let tempRoleDetailsArray = [...roleDetailsArray];
        tempRoleDetailsArray.forEach((roleDetail: any, i: any) => {
            let temproleDetail = { ...roleDetail };
            if (index === i) {
                temproleDetail.country = event.target.value;
                temproleDetail.lob = [];
                temproleDetail.lobArr = props.lobMap.get(event.target.value);
            }
            tempRoleDetailsArray[i] = temproleDetail;
        });
        setRoleDetailsArray(tempRoleDetailsArray);
    };

    const handleLobChange = (event: any, index: any) => {
        let tempRoleDetailsArray = [...roleDetailsArray];
        tempRoleDetailsArray.forEach((roleDetail: any, i: any) => {
            let temproleDetail = { ...roleDetail };
            if (index === i) {
                temproleDetail.lob = event.target.value;
            }
            tempRoleDetailsArray[i] = temproleDetail;
        });
        setRoleDetailsArray(tempRoleDetailsArray);
    };

    const handleTypeChange = (event: any) => {
        setUserType(event.target.value);
    };

    const handleClick = () => {
        setButtonText(buttonText === 'save' ? 'edit details' : 'save');
        setFlag(!flag);

        if (buttonText === 'save') {
            let errorMessage = validateError();
            if (errorMessage === '') {
                saveUser();
            } else {
                props.setErrorMsgAdmin(errorMessage);
                setButtonText(errorMessage ? 'save' : 'edit details');
            }
        }
        if (buttonText === 'edit details') {
            setTextFieldDisable(false);
            props.valueMap?.get(props.userDetail.lineofbusiness);
        }
    };

    function saveUser() {
        let assignedcountries: any = [];
        roleDetailsArray.forEach((roledetail: any) => {
            assignedcountries.push({
                countrycode: roledetail.country,
                country: props.countryMap.get(roledetail.country),
                lineofbusiness: roledetail.lob,
            });
        });

        var param = {
            countrycode: 'NA',
            userid: props.userDetail.userid,
            firstname: firstName,
            lastname: lastName,
            email: email,
            usertype: userType,
            role: roleID,
            status: status,
            defaultlanguage: 'ENGLISH',
            lob: lob,
            password:password,
            assignedcountries: assignedcountries,
        };

        saveOrUpdateRequest({
            url: 'superadmin/update',
            method: 'PUT',
            data: param,
        }).then((response: any) => {
            if (response && response.status === 200) {
                Alert('success', 'User Data is Saved');
                props.setEditUserDetailsModal(false);
                props.AdminListData();
            } else {
                Alert('message', response.status);
            }
        });
    }
    const handleClosePopup = () => {
        setButtonText('edit details');
        setFlag(true);
        setTextFieldDisable(true);
        props.setErrorMsgAdmin('');
        props.setEditUserDetailsModal(false);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const validateError = () => {
        let error = [];
        let errorMessage = '';
        var mailReg = new RegExp(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        );
        /*
    The combination means, email address must start with “_A-Za-z0-9-\+” ,
    optional follow by “.[_A-Za-z0-9-]“, and end with a “@” symbol.
    The email’s domain name must start with “A-Za-z0-9-”,
    follow by first level Tld (.com, .net) “.[A-Za-z0-9]” and
    optional follow by a second level Tld (.com.au, .com.my) “\.[A-Za-z]{2,}”,
     where second level Tld must start with a dot “.”
      and length must equal or more than 2 characters */

        if (firstName.length === 0) {
            error.push('Firstname is Required');
        }

        if (lastName.length === 0) {
            error.push('Lastname is Required');
        }

        if (!mailReg.test(email) || email.length === 0) {
            error.push('Invalid EmailID');
        }

        error.forEach((err: string) => {
            errorMessage += err + ',';
        });

        return errorMessage;
    };

    const handleAddRow = () => {
        let roleDetailArray = [...roleDetailsArray];
        roleDetailArray.push({
            country: 'select',
            lob: [],
            lobArr: [],
        });
        setRoleDetailsArray(roleDetailArray);
    };

    const handleRemoveRow = () => {
        let roleDetailArray = [...roleDetailsArray];
        roleDetailArray.pop();
        setRoleDetailsArray(roleDetailArray);
    };

    function displayAddRemoveButton(index: any) {
        if (index === roleDetailsArray.length - 1) {
            return (
                <div>
                    <IconButton onClick={handleAddRow}>
                        {renderSvg(svgIconName.add_icon, 50)}
                    </IconButton>
                    {index !== 0 && (
                        <IconButton onClick={handleRemoveRow}>
                            {renderSvg(svgIconName.remove_icon, 50)}
                        </IconButton>
                    )}
                </div>
            );
        }
    }

    return (
        <>
            <Modal open={props.editUserDetailsModal}>
                <Box sx={style}>
                    <div className='closeheading-usermgt1'>
                        <div>User Details</div>
                        <div>
                            <CloseIcon
                                className='advisory_close_icon'
                                onClick={handleClosePopup}
                            />
                        </div>
                    </div>
                    <div>
                        <span
                            style={{
                                color: 'red',
                                fontFamily: 'appRegular',
                                fontSize: 13,
                            }}>
                            {props.errorMsgAdmin}
                        </span>
                    </div>

                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div>
                            <div className='usermgt-headerfont'>First Name</div>
                            <div>
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='usermgt-headerfont'>Last Name</div>
                            <div>
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='usermgt-headerfont'>Email</div>
                            <div>
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div>
                            <div className='usermgt-headerfont'>User Type</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled={textFieldDisable}
                                        className='usermgt-textfont'
                                        InputProps={{
                                            classes: {
                                                input: props.classes.resize,
                                            },
                                        }}
                                        value={props.userDetail.usertype}
                                    />
                                ) : (
                                    <Select
                                        className='usermgmt-user-type-width'
                                        value={userType}
                                        onChange={handleTypeChange}>
                                        {props.userTypeArray &&
                                            props.userTypeArray.map(
                                                (user: any) => (
                                                    <MenuItem value={user} className={classes.menu}>
                                                        {user}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className='usermgt-headerfont'>Status</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled={textFieldDisable}
                                        className='usermgt-textfont'
                                        InputProps={{
                                            classes: {
                                                input: props.classes.resize,
                                            },
                                        }}
                                        value={props.userDetail.status}
                                    />
                                ) : (
                                    <Select
                                        className='usermgmt-user-type-width'
                                        value={status}
                                        onChange={handleStatusChange}>
                                        <MenuItem value='select' disabled className={classes.menu}>
                                            <span className='select'>
                                                Select a Status
                                            </span>
                                        </MenuItem>
                                        {props.statusArray &&
                                            props.statusArray.map(
                                                (userstatus: any) => (
                                                    <MenuItem className={classes.menu}
                                                        value={userstatus}>
                                                        {userstatus}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className='usermgt-headerfont'>Role</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled={textFieldDisable}
                                        className='usermgt-textfont'
                                        InputProps={{
                                            classes: {
                                                input: props.classes.resize,
                                            },
                                        }}
                                        value={props.userDetail.rolename}
                                    />
                                ) : (
                                    <Select
                                        className='usermgmt-user-type-width'
                                        value={roleID}
                                        onChange={handleRoleChange}>
                                        <MenuItem value='select' disabled className={classes.menu}>
                                            <span className='select'>
                                                Select a Role
                                            </span>
                                        </MenuItem>
                                        {props.roleAdminArray &&
                                            props.roleAdminArray.map(
                                                (role: any) => (
                                                    <MenuItem className={classes.menu}
                                                        value={role.rolelabel}>
                                                        {role.description}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                )}
                            </div>
                        </div>
                    </Stack>

                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div>
                            <div className='usermgt-headerfont'>
                                Change password
                            </div>
                            <div>
                            {buttonText === 'edit details' ? (
                                    <FormControl
                                        className='usermgt-password'
                                        sx={{ m: 1, margin: 0 }}
                                        variant='outlined'>
                                        <OutlinedInput
                                            id='outlined-adornment-password'
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            disabled={textFieldDisable}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge='end'>
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                ) : (
                                    <FormControl
                                        className='usermgt-password'
                                        sx={{ m: 1, margin: 0 }}
                                        variant='outlined'>
                                        <OutlinedInput
                                            id='outlined-adornment-password'
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            value={password}
                                            onChange={handlePasswordChange}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge='end'>
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    </Stack>

                    <Stack spacing={{ xs: 2, sm: 2 }} direction='row'>
                        <div className='header-user-font'>
                            <div className='usermgt-headerfont'>Country</div>
                            <div className='usermgt-headerfont'>
                                Business Unit
                            </div>
                        </div>
                    </Stack>
                    {roleDetailsArray &&
                        roleDetailsArray.map((roleDetail: any, index: any) => (
                            <Stack
                                spacing={{ xs: 2, sm: 4 }}
                                direction='row'
                                useFlexGap
                                flexWrap='wrap'
                                className='stack-country-gap'>
                                <div>
                                    <div>
                                        {buttonText === 'edit details' ? (
                                            <TextField
                                                disabled={textFieldDisable}
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                value={roleDetail.country}
                                            />
                                        ) : (
                                            <Select
                                                className='usermgmt-user-type-width'
                                                value={roleDetail.country}
                                                onChange={(event: any) =>
                                                    handleCountryChange(
                                                        event,
                                                        index
                                                    )
                                                }>
                                                <MenuItem className={classes.menu}
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Country
                                                    </span>
                                                </MenuItem>
                                                {props.countryAdminArray &&
                                                    props.countryAdminArray.map(
                                                        (countrylist: any) => (
                                                            <MenuItem className={classes.menu}
                                                                value={
                                                                    countrylist.countrycode
                                                                }>
                                                                {
                                                                    countrylist.country
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    {buttonText === 'edit details' ? (
                                        <TextField
                                            disabled={textFieldDisable}
                                            className='usermgt-textfont'
                                            InputProps={{
                                                classes: {
                                                    input: props.classes.resize,
                                                },
                                            }}
                                            value={roleDetail.lob}
                                        />
                                    ) : (
                                        <Select
                                            multiple
                                            value={roleDetail.lob}
                                            onChange={(event: any) =>
                                                handleLobChange(event, index)
                                            }
                                            renderValue={(selected) =>
                                                selected
                                                    ? selected.join(',')
                                                    : ''
                                            }
                                            className='usermgmt-user-type-width'>
                                            <MenuItem value='select' disabled className={classes.checkbox}>
                                                <span className='select'>
                                                    Select a BU
                                                </span>
                                            </MenuItem>
                                            {roleDetail.lobArr &&
                                                roleDetail.lobArr.map(
                                                    (lob: any) => (
                                                        <MenuItem value={lob} className={classes.checkbox}>
                                                            <Checkbox className={classes.checkbox}
                                                                icon={icon}
                                                                checkedIcon={
                                                                    checkedIcon
                                                                }
                                                                checked={
                                                                    roleDetail.lob.indexOf(
                                                                        lob
                                                                    ) > -1
                                                                }
                                                            />
                                                            <span className='select'>
                                                                {lob}
                                                            </span>
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    )}
                                </div>
                                {buttonText === 'save'
                                    ? displayAddRemoveButton(index)
                                    : ''}
                            </Stack>
                        ))}

                    <Stack
                        className='button-edit-stack'
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div className='button-editusermgt'>
                            <Button
                                className={classes.root}
                                onClick={handleClick}
                                variant='contained'
                                color={flag ? 'primary' : 'secondary'}>
                                {flag ? <EditIcon /> : <DoneIcon />}
                                &nbsp; {buttonText}
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}

export default EditSuperAdminDetails;
