/** @format */

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import './CustomTabs.scss';

interface Props {
    tabData: Array<{ label: string; value: string }>;
    getSeletedValue: (value: string) => any;
    myValue?: number;
}
export default function CustomTabs({
    tabData,
    getSeletedValue,
    myValue,
}: Props) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const selectedObj: any = tabData[newValue];
        getSeletedValue(selectedObj.value);
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                // value={value}
                value={myValue !== undefined ? myValue : value}
                aria-label='Tabs where selection follows focus'
                selectionFollowsFocus>
                {tabData?.length > 0 &&
                    tabData.map((tab: any) => {
                        return (
                            <Tab
                                key={tab?.value}
                                label={tab?.label}
                                sx={{ textTransform: 'capitalize' }}
                            />
                        );
                    })}
            </Tabs>
        </Box>
    );
}
