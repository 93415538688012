/** @format */

import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './stageinfo.scss';
import Tooltip from '@mui/material/Tooltip';
import { TextField, Button, Stack } from '@mui/material';
import { renderSvg, svgIconName } from '../../../assets/img/imageExt';
import IconButton from '@mui/material/IconButton';
import { getRequest, saveOrUpdateRequest } from '../../../services/apiUtils';
import { Alert } from '../../../utils/toastr';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { connect } from 'react-redux';
import {
    addSubStageRow,
    removeSubStageRow,
    resetRowSubStage,
    updateSubStageRow,
    reset,
    updateNotifications,
} from '../../../redux/actions/create-crop/createCrop';
import { useNavigate } from 'react-router-dom';
import NotificationModal from './NotificationModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

function Stageinfo(props: any) {
    const [cropStageArray, setCropStageArray] = React.useState<any[]>([]);
    const [cropSubStageArray, setCropSubStageArray] = React.useState<any[]>([]);
    const [notificationInitArray, setNotificationInitArray] = React.useState<
        any[]
    >([]);
    const [cropStageMap, setCropStageMap] = React.useState<Map<any, any>>(
        new Map()
    );
    const [startEndDateMap, setStartEndDateMap] = React.useState<Map<any, any>>(
        new Map()
    );
    const [
        subStageNotificationMap,
        setSubStageNotificationMap,
    ] = React.useState<Map<any, any>>(new Map());

    const [double, setDouble] = React.useState(false);

    var history = useNavigate();

    const handlePageBack = () => {
        props.goBackOnePage(0);
    };

    useEffect(() => {
        getSelectedStageData(props.selectedCrop.cropId);
        getLookup();
    }, []);

    const getLookup = () => {
        let lsData: any = getLocalStorageData('userData');

        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
        };
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let languages = response?.body?.data?.languages;
                    let notificationArray: any[] = [];
                    languages.forEach((language: any) => {
                        notificationArray.push({
                            value: '',
                            language: language,
                        });
                    });
                    setNotificationInitArray(notificationArray);
                    if (props.subStages[0].notifications.length === 0) {
                        props.updateSubStageRow(
                            notificationArray,
                            'notifications',
                            0
                        );
                    }
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getSelectedStageData = (cropid: any) => {
        let lsData: any = getLocalStorageData('userData');

        let loggedUser = JSON.parse(lsData);

        let queryParams: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
        };

        if (cropid !== '') {
            queryParams = {
                ...queryParams,
                ...{ cropid: cropid },
            };
        }

        getRequest({
            url: 'cropcalendar/lookup/master',
            method: 'GET',
            queryParams: queryParams,
        })
            .then((response: any) => {
                let tempMap = new Map();
                let tempSubstageMap = new Map();
                let subStageandLabelMap = new Map();

                setCropStageArray(response.body.data.cropstages);

                let tmpCropStageArray = new Map();

                response.body.data.cropstages.forEach((data: any) => {
                    tmpCropStageArray.set(data.cropstagesid, data);
                });
                setCropStageMap(tmpCropStageArray);

                response.body.data.cropsubstages?.forEach((value: any) => {
                    let dateMap = new Map();
                    dateMap.set('startdate', value.startdate);
                    dateMap.set('enddate', value.enddate);
                    tempMap.set(value.cropsubstageslabel, dateMap);
                    subStageandLabelMap.set(
                        value.cropsubstageid,
                        value.cropsubstageslabel
                    );
                });
                setStartEndDateMap(tempMap);

                response.body.data.cropnotifications?.forEach((value: any) => {
                    const label = subStageandLabelMap.get(value.cropsubstageid);
                    if (tempSubstageMap.has(label)) {
                        let notifList = tempSubstageMap.get(label);
                        notifList.push({
                            'value': value.notificationvalue,
                            'language': value.notificationlanguage,
                        });

                        tempSubstageMap.set(label, notifList);
                    } else {
                        let notifList = [];
                        notifList.push({
                            'value': value.notificationvalue,
                            'language': value.notificationlanguage,
                        });

                        tempSubstageMap.set(label, notifList);
                    }
                });

                setSubStageNotificationMap(tempSubstageMap);
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getSelectedSubStageData = (selectedStage: any, index: any) => {
        let lsData: any = getLocalStorageData('userData');

        let loggedUser = JSON.parse(lsData);

        let queryParams: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
        };

        if (props.selectedCrop.cropId !== '') {
            queryParams = {
                ...queryParams,
                ...{ cropid: props.selectedCrop.cropId },
            };
        }

        if (selectedStage !== '') {
            queryParams = {
                ...queryParams,
                ...{ cropstagesid: selectedStage },
            };
        }

        getRequest({
            url: 'cropcalendar/lookup/master',
            method: 'GET',
            queryParams: queryParams,
        })
            .then((response: any) => {
                setCropSubStageArray(response.body.data.cropsubstages);
                props.updateSubStageRow(
                    response.body.data.cropsubstages,
                    'crop-sub-stage-data',
                    index
                );
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    function saveCropCalendar() {
        let lsData: any = getLocalStorageData('userData');

        let loggedUser = JSON.parse(lsData);

        let tempArray: any[] = [];
        let subStageError = false;
        let subStageFeilds: any[] = [];
        props.subStages?.forEach((subStage: any) => {
            if (subStage.cropstagesid === 0) {
                subStageError = true;
                subStageFeilds.push('crop stage');
            }

            if (subStage.cropsubstageslabel === 'select') {
                subStageError = true;
                subStageFeilds.push('crop sub stage');
            }

            if (subStage.startdate === 0) {
                subStageError = true;
                subStageFeilds.push('start date');
            }

            if (subStage.enddate === 0) {
                subStageError = true;
                subStageFeilds.push('end date');
            }

            if (subStage.notifications.value === '') {
                subStageError = true;
                subStageFeilds.push('notification');
            }

            if (
                subStage.nomessages === false &&
                subStage.everyday === false &&
                subStage.startday === false &&
                subStage.endday === false
            ) {
                subStageError = true;
                subStageFeilds.push('please schedule message notification');
            }

            tempArray.push({
                season: props.season,
                seasonyear: props.year,
                seasonstartdate: props.seasonStartDate,
                seasonenddate: props.seasonEndDate,
                cropstagesid: subStage.cropstagesid,
                stagestartdate: subStage.stagestartdate,
                stageenddate: subStage.stageenddate,
                cropstageslabel: subStage.cropstageslabel,
                cropstagedescription: subStage.cropstagedescription,
                variety: props.variety,
                cropsubstageslabel: subStage.cropsubstageslabel,
                cropsubstagedescription: subStage.cropsubstagedescription,
                startdate: subStage.startdate,
                enddate: subStage.enddate,
                nomessages: subStage.nomessages,
                everyday: subStage.everyday,
                startday: subStage.startday,
                endday: subStage.endday,
                notifications: subStage.notifications,
            });
        });
        let passData: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            cropid: props.selectedCrop.cropId,
            geolevel1code: props.geolevel1Details.geolevel1Code,
            cropcalendarlabel: props.calendarname,
            cropsubstages: tempArray,
        };

        if (subStageError) {
            let erroredFeilds: string = '';
            subStageFeilds.forEach((stage: string) => {
                erroredFeilds += ',' + stage;
            });

            Alert('error', 'Missing values in below fields ' + erroredFeilds);
        } else {
            saveOrUpdateRequest({
                url: 'cropcalendar/create',
                method: 'POST',
                data: passData,
            })
                .then((response: any) => {
                    if (response?.code === 200) {
                        setDouble(true);
                        Alert('success', 'Crop calendar created successfully');
                        props.reset();
                        props.resetRowSubStage();

                        history('/crop-calendar');
                    }

                    if (response?.status !== 200) {
                        Alert('error', response?.message);
                    }
                })
                .catch((error: any) => {
                    Alert('error', 'Data fields are missing');
                });
        }
    }

    return (
        <div className='createcrop-container'>
            {props.subStages &&
                props.subStages.map((subStage: any, index: any) => {
                    return (
                        <div className='createcrop-container'>
                            <Stack
                                direction='row'
                                spacing={0}
                                className='stack-column'>
                                <div className='createcropstage-width'>
                                    <div className='label-header-stage'>
                                        Crop Stage
                                    </div>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={subStage.cropstagesid}
                                        className='crop-stage-div'
                                        onChange={(event) => {
                                            getSelectedSubStageData(
                                                event.target.value,
                                                index
                                            );
                                            let cropStageData = cropStageMap.get(
                                                event.target.value
                                            );
                                            props.updateSubStageRow(
                                                event.target.value,
                                                'crop-stage',
                                                index
                                            );
                                            props.updateSubStageRow(
                                                cropStageData.startdate,
                                                'stagestart-date',
                                                index
                                            );
                                            props.updateSubStageRow(
                                                cropStageData.enddate,
                                                'stageend-date',
                                                index
                                            );
                                            props.updateSubStageRow(
                                                cropStageData.cropstageslabel,
                                                'cropstageslabel',
                                                index
                                            );
                                            props.updateSubStageRow(
                                                cropStageData.cropstagedescription,
                                                'cropstagedescription',
                                                index
                                            );
                                        }}>
                                        <MenuItem value={0}>
                                            <div className='select-createcrop'>
                                                <span className='select'>
                                                    Select a Crop Stage
                                                </span>
                                            </div>
                                        </MenuItem>
                                        {cropStageArray &&
                                            cropStageArray.map(
                                                (cropStage: any) => {
                                                    return (
                                                        <MenuItem
                                                            className='menuitem'
                                                            value={
                                                                cropStage.cropstagesid
                                                            }>
                                                            {
                                                                cropStage.cropstageslabel
                                                            }
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                    </Select>
                                </div>
                                <div className='createcropstage-date'>
                                    <div className='date-info-createcrop'>
                                        <div className='label-header-stage'>
                                            Stage Start Day
                                        </div>

                                        <Tooltip
                                            title={
                                                <div>
                                                    Stage Start Day from Sowing
                                                    Date
                                                </div>
                                            }
                                            arrow>
                                            <div className='hover'>
                                                {renderSvg(
                                                    svgIconName.information,
                                                    20
                                                )}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        type='text'
                                        className='sub-stage-start-date-div'
                                        id='outlined-basic'
                                        variant='outlined'
                                        onChange={(event) => {
                                            const regex = /^[0-9 ()+-]+$/;
                                            if (
                                                event.target.value === '' ||
                                                regex.test(event.target.value)
                                            ) {
                                                props.updateSubStageRow(
                                                    event.target.value,
                                                    'stagestart-date',
                                                    index
                                                );
                                            }
                                        }}
                                        value={subStage.stagestartdate}
                                    />
                                </div>
                                <div className='createcropstage-date'>
                                    <div className='date-info-createcrop'>
                                        <div className='label-header-stage'>
                                            Stage End Day
                                        </div>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Stage End Day from Sowing
                                                    Date
                                                </div>
                                            }
                                            arrow>
                                            <div className='hover'>
                                                {renderSvg(
                                                    svgIconName.information,
                                                    20
                                                )}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        type='text'
                                        id='outlined-basic'
                                        className='sub-stage-end-date-div'
                                        variant='outlined'
                                        onChange={(event) => {
                                            const regex = /^[0-9\b]+$/;
                                            if (
                                                event.target.value === '' ||
                                                regex.test(event.target.value)
                                            ) {
                                                props.updateSubStageRow(
                                                    event.target.value,
                                                    'stageend-date',
                                                    index
                                                );
                                            }
                                        }}
                                        value={subStage.stageenddate}
                                    />
                                </div>

                                <div className='createcropstage-width'>
                                    <div className='label-header-stage'>
                                        Crop Sub-Stage
                                    </div>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={subStage.cropsubstageslabel}
                                        className='crop-stage-substage-div'
                                        onChange={(event) => {
                                            const dateMap = startEndDateMap?.get(
                                                event.target.value
                                            );
                                            props.updateSubStageRow(
                                                event.target.value,
                                                'crop-sub-stage',
                                                index
                                            );
                                            props.updateSubStageRow(
                                                dateMap.get('startdate'),
                                                'start-date',
                                                index
                                            );
                                            props.updateSubStageRow(
                                                dateMap.get('enddate'),
                                                'end-date',
                                                index
                                            );

                                            subStageNotificationMap
                                                .get(event.target.value)
                                                .forEach((val: any) => {
                                                    props.updateNotifications(
                                                        val.value,
                                                        index,
                                                        val.language
                                                    );
                                                });
                                        }}>
                                        <MenuItem value='select'>
                                            <div className='select-createcrop'>
                                                <span className='select'>
                                                    Select a Crop SubStage
                                                </span>
                                            </div>
                                        </MenuItem>
                                        {subStage.cropSubStageData &&
                                            subStage.cropSubStageData.map(
                                                (cropSubStage: any) => {
                                                    return (
                                                        <MenuItem
                                                            className='menuitem'
                                                            value={
                                                                cropSubStage.cropsubstageslabel
                                                            }>
                                                            {
                                                                cropSubStage.cropsubstageslabel
                                                            }
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                    </Select>
                                </div>
                                <div className='createcropstage-date'>
                                    <div className='date-info-createcrop'>
                                        <div className='label-header-stage'>
                                            SubStage Start Day
                                        </div>

                                        <Tooltip
                                            title={
                                                <div>
                                                    SubStage Start Day from
                                                    Sowing Date
                                                </div>
                                            }
                                            arrow>
                                            <div className='hover'>
                                                {renderSvg(
                                                    svgIconName.information,
                                                    20
                                                )}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        type='text'
                                        className='sub-stage-start-date-div'
                                        id='outlined-basic'
                                        variant='outlined'
                                        onChange={(event) => {
                                            const regex = /^[0-9 ()+-]+$/;
                                            if (
                                                event.target.value === '' ||
                                                regex.test(event.target.value)
                                            ) {
                                                props.updateSubStageRow(
                                                    event.target.value,
                                                    'start-date',
                                                    index
                                                );
                                            }
                                        }}
                                        value={subStage.startdate}
                                    />
                                </div>
                                <div className='createcropstage-date'>
                                    <div className='date-info-createcrop'>
                                        <div className='label-header-stage'>
                                            SubStage End Day
                                        </div>
                                        <Tooltip
                                            title={
                                                <div>
                                                    SubStage End Day from Sowing
                                                    Date
                                                </div>
                                            }
                                            arrow>
                                            <div className='hover'>
                                                {renderSvg(
                                                    svgIconName.information,
                                                    20
                                                )}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        type='text'
                                        id='outlined-basic'
                                        className='sub-stage-end-date-div'
                                        variant='outlined'
                                        onChange={(event) => {
                                            const regex = /^[0-9 ()+-]+$/;
                                            if (
                                                event.target.value === '' ||
                                                regex.test(event.target.value)
                                            ) {
                                                props.updateSubStageRow(
                                                    event.target.value,
                                                    'end-date',
                                                    index
                                                );
                                            }
                                        }}
                                        value={subStage.enddate}
                                    />
                                </div>
                            </Stack>
                            <Stack
                                direction='row'
                                spacing={3}
                                className='stack-column'>
                                <div className='createcrop-notif-text'>
                                    <NotificationModal
                                        notificationArray={
                                            subStage.notifications
                                        }
                                        index={index}
                                        updateSubStageRow={
                                            props.updateSubStageRow
                                        }
                                    />
                                </div>
                                <div className='createcrop-schedule-width'>
                                    <div className='label-header-stage'>
                                        Schedule
                                    </div>
                                    <FormGroup>
                                        <div>
                                            <div className='message-stage'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                props.updateSubStageRow(
                                                                    event.target
                                                                        .checked,
                                                                    'nomessages',
                                                                    index
                                                                );
                                                            }}
                                                            checked={
                                                                subStage.nomessages
                                                            }
                                                        />
                                                    }
                                                    label='No messages'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                props.updateSubStageRow(
                                                                    event.target
                                                                        .checked,
                                                                    'everyday',
                                                                    index
                                                                );
                                                            }}
                                                            checked={
                                                                subStage.everyday
                                                            }
                                                        />
                                                    }
                                                    label='Everyday'
                                                />
                                            </div>
                                            <div className='message-stage1'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                props.updateSubStageRow(
                                                                    event.target
                                                                        .checked,
                                                                    'startday',
                                                                    index
                                                                );
                                                            }}
                                                            checked={
                                                                subStage.startday
                                                            }
                                                        />
                                                    }
                                                    label='StartDay'
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                props.updateSubStageRow(
                                                                    event.target
                                                                        .checked,
                                                                    'endday',
                                                                    index
                                                                );
                                                            }}
                                                            checked={
                                                                subStage.endday
                                                            }
                                                        />
                                                    }
                                                    label='EndDay'
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className='add-remove'>
                                    {index === props.subStages.length - 1 && (
                                        <div className='add-remove-div'>
                                            <IconButton
                                                onClick={() => {
                                                    props.addSubStageRow();
                                                    const len =
                                                        props.subStages.length;

                                                    props.updateSubStageRow(
                                                        notificationInitArray,
                                                        'notifications',
                                                        len
                                                    );
                                                }}>
                                                {renderSvg(
                                                    svgIconName.add_icon,
                                                    50
                                                )}
                                            </IconButton>

                                            {index > 0 && (
                                                <IconButton
                                                    onClick={() => {
                                                        props.removeSubStageRow();
                                                    }}>
                                                    {renderSvg(
                                                        svgIconName.remove_icon,
                                                        50
                                                    )}
                                                </IconButton>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Stack>
                        </div>
                    );
                })}

            <div className='threeButtonsContainer'>
                <span style={{ display: 'flex' }}>
                    <span className='role_def_back_icon'>
                        {renderSvg(svgIconName.down_arrow, 10)}
                    </span>
                    <a onClick={handlePageBack} className='roleDef_goBack_text'>
                        Go back
                    </a>
                </span>
                <span className='threeButtonsReset'>
                    <Button
                        style={{
                            width: '181px',
                            height: '35px',
                        }}
                        className='roleDef_reset_btn'
                        onClick={() => {
                            props.resetRowSubStage();
                            props.updateSubStageRow(
                                notificationInitArray,
                                'notifications',
                                0
                            );
                        }}>
                        Reset
                    </Button>
                </span>
                <span>
                    <Button
                        onClick={() => saveCropCalendar()}
                        disabled={double}
                        style={{
                            borderRadius: '20px',
                            backgroundColor: '#7eb343',
                            width: 'fitContent',
                            height: '35px',
                            paddingLeft: '20px',
                            paddingRight: '44px',
                            border: 0,
                            boxShadow: '0px 3px 6px #c7c7c729',
                        }}
                        className='roleDef_next_btn'>
                        Create
                    </Button>
                </span>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    console.log(state.crop.cropSubstages)
    return {
        selectedCrop: state.crop.selectedCrop,
        subStages: state.crop.cropSubstages,
        season: state.crop.season,
        year: state.crop.year,
        seasonStartDate: state.crop.seasonStartDate,
        seasonEndDate: state.crop.seasonEndDate,
        fromDate: state.crop.fromDate,
        toDate: state.crop.toDate,
        countryDetails: state.crop.countryDetails,
        geolevel1Details: state.crop.geolevel1Details,
        variety: state.crop.variety,
        calendarname: state.crop.calendarname,
    };
};

const mapDispatchToProps = {
    addSubStageRow: () => addSubStageRow(),
    removeSubStageRow: () => removeSubStageRow(),
    updateSubStageRow: (value: any, label: any, index: any) =>
        updateSubStageRow(value, label, index),
    resetRowSubStage: () => resetRowSubStage(),
    reset: () => reset(),
    updateNotifications: (value: any, mainIdx: any, language: any) =>
        updateNotifications(value, mainIdx, language),
};

export default connect(mapStateToProps, mapDispatchToProps)(Stageinfo);
