/** @format */

import React from 'react';

import { Card, Button, CardContent, TextField } from '@mui/material';
import { saveOrUpdateRequest } from '../../services/apiUtils';
import { Alert } from '../../utils/toastr';
import { getLocalStorageData } from '../../utils/useLocalStorage';

function CropCalendarModal(props: any) {
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const [notificationMsg, setNotificationMsg] = React.useState<String>(
        props.not.notificationvalue
    );

    function UpdateNotification() {
        let passData: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            notificationid: props.not.notificationid,
            value: notificationMsg,
            language: props.not.notificationlanguage,
        };

        saveOrUpdateRequest({
            url: 'cropcalendar/update',
            method: 'PUT',
            data: passData,
        }).then((response: any) => {
            if (response.code === 200) {
                
                let tempNotificationArray = props.notificationArray
                tempNotificationArray = tempNotificationArray.map((eachNotification:any)=>{
                        if(eachNotification.notificationlanguage === props.not.notificationlanguage){
                            let tempNotification = eachNotification
                            tempNotification.notificationvalue = notificationMsg
                            return tempNotification
                        }
                        return eachNotification
                })

                props.setNotification(tempNotificationArray)

                Alert('success', 'Notification saved');
            }
        });
    }

    return (
        <Card>
            {loggedUser.roleid === 'ADMIN' || loggedUser.roleid === 'SUPER_ADMIN' ? (
                <CardContent>
                    <TextField
                        className='notif-textfield'
                        id='outlined-multiline-flexible'
                        label='Message'
                        value={notificationMsg}
                        onChange={(event: any) => {
                            setNotificationMsg(event.target.value);
                        }}
                        multiline
                        maxRows={4}
                    />
                    <Button
                        variant='contained'
                        color='success'
                        onClick={() => UpdateNotification()}>
                        Save
                    </Button>
                </CardContent>
            ) : (
                <CardContent>{notificationMsg}</CardContent>
            )}
        </Card>
    );
}

export default CropCalendarModal;
