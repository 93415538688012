/** @format */

const INITIAL_STATE: any = {
    defaultCropList: [],
    calendarname:'',
    selectedCrop: { cropName: 'select a crop', cropId: 'select' },
    variety: 'select',
    season: 'select',
    year: '',
    seasonStartDate: null,
    seasonEndDate: null,
    fromDate: null,
    toDate: null,
    countryDetails: { countryCode: 'select', countryName: 'Select a Country' },
    geolevel1Details: { geolevel1Code: 'select', geolevel1Name: 'Select a State' },
    allGeoLevels: [],
    firstOrResetData: [],
    cropSubstages: [
        {
            cropstagesid: 0,
            stagestartdate: 0,
            stageenddate: 0,
            cropstageslabel: '',
            cropstagedescription: '',
            cropsubstageslabel: 'select',
            cropsubstagedescription: 'select',
            startdate: 0,
            enddate: 0,
            nomessages: false,
            everyday: false,
            startday: false,
            endday: false,
            notifications: [],
            cropSubStageData: [],
        },
    ],
};

const createCropReducer = (state = INITIAL_STATE, action: any): any => {
    const { type, payload } = action;
    switch (type) {
        case 'ADD_SUB_STAGE_ROW':
            let tempCropSubstages = [...state.cropSubstages];

            tempCropSubstages.push({
                cropstagesid: 0,
                stagestartdate: 0,
                stageenddate: 0,
                cropstageslabel: '',
                cropstagedescription: '',
                cropsubstageslabel: 'select',
                cropsubstagedescription: 'select',
                startdate: 0,
                enddate: 0,
                nomessages: false,
                everyday: false,
                startday: false,
                endday: false,
                notifications: [],
                cropSubStageData: [],
            });

            return { ...state, cropSubstages: [...tempCropSubstages] };
        case 'REMOVE_SUB_STAGE_ROW':
            let removeCropSubstages = [...state.cropSubstages];
            let tempRemoveArray: any[] = [];

            removeCropSubstages.forEach((stage: any, index: any) => {
                if (index !== removeCropSubstages.length - 1) {
                    tempRemoveArray.push(stage);
                }
            });

            return { ...state, cropSubstages: [...tempRemoveArray] };
        case 'UPDATE_SUB_STAGE_ROW':
            console.log(payload)
            let updateCropStageArray = [...state.cropSubstages];
            updateCropStageArray = updateCropStageArray.map(
                (data: any, idx: any) => {
                    if (idx === payload.i) {
                        return {
                            cropstagesid:
                                payload.label === 'crop-stage'
                                    ? payload.input
                                    : data.cropstagesid,
                            stagestartdate:
                                payload.label === 'stagestart-date'
                                    ? payload.input
                                    : data.stagestartdate,
                            stageenddate:
                                payload.label === 'stageend-date'
                                    ? payload.input
                                    : data.stageenddate,
                            cropstageslabel:
                                payload.label === 'cropstageslabel'
                                    ? payload.input
                                    : data.cropstageslabel,
                            cropstagedescription:
                                payload.label === 'cropstagedescription'
                                    ? payload.input
                                    : data.cropstagedescription,
                            cropsubstageslabel:
                                payload.label === 'crop-sub-stage'
                                    ? payload.input
                                    : data.cropsubstageslabel,
                            cropsubstagedescription:
                                payload.label === 'crop-sub-stage'
                                    ? payload.input
                                    : data.cropsubstagedescription,
                            startdate:
                                payload.label === 'start-date'
                                    ? payload.input
                                    : data.startdate,
                            enddate:
                                payload.label === 'end-date'
                                    ? payload.input
                                    : data.enddate,
                            nomessages:
                                payload.label === 'nomessages'
                                    ? payload.input
                                    : data.nomessages,
                            everyday:
                                payload.label === 'everyday'
                                    ? payload.input
                                    : data.everyday,
                            startday:
                                payload.label === 'startday'
                                    ? payload.input
                                    : data.startday,
                            endday:
                                payload.label === 'endday'
                                    ? payload.input
                                    : data.endday,
                            notifications:
                                payload.label === 'notifications'
                                    ? payload.input
                                    : data.notifications,
                            cropSubStageData:
                                payload.label === 'crop-sub-stage-data'
                                    ? payload.input
                                    : data.cropSubStageData,
                        };
                    }
                    return data;
                }
            );
            return { ...state, cropSubstages: [...updateCropStageArray] };
        case 'UPDATE_NOTIFICATIONS':
            let updatedCropStageArray = [...state.cropSubstages];
            let tempNotifications = [
                ...updatedCropStageArray[payload.mainIdx].notifications,
            ];

            tempNotifications = tempNotifications.map((data: any, idx: any) => {
                if (data.language === payload.language) {
                    return {
                        value: payload.input,
                        language: data.language,
                    };
                }
                return data;
            });

            updatedCropStageArray = updatedCropStageArray.map(
                (data: any, idx: any) => {
                    if (idx === payload.mainIdx) {
                        return {
                            cropstagesid: data.cropstagesid,
                            stagestartdate: data.stagestartdate,
                            stageenddate: data.stageenddate,
                            cropstageslabel: data.cropstageslabel,
                            cropstagedescription: data.cropstagedescription,
                            cropsubstageslabel: data.cropsubstageslabel,
                            cropsubstagedescription:
                                data.cropsubstagedescription,
                            startdate: data.startdate,
                            enddate: data.enddate,
                            schedule: data.schedule,
                            nomessages: data.nomessages,
                            everyday: data.everyday,
                            startday: data.startday,
                            endday: data.endday,
                            notifications: [...tempNotifications],
                            cropSubStageData: data.cropSubStageData,
                        };
                    }
                    return data;
                }
            );
            return { ...state, cropSubstages: [...updatedCropStageArray] };
        case 'RESET_SUB_STAGE_ROW':
            return { ...state, cropSubstages: INITIAL_STATE.cropSubstages };
        case 'setDefaultCropList': {
            return { ...state, defaultCropList: [...payload] };
        }
        case 'typecalendarname':
            return { ...state, calendarname: payload };
        case 'chooseCrop':
            let cropId = payload.cropid;
            let cropName = payload.cropname;
            let tempCropInfo = { cropName, cropId };
            return { ...state, selectedCrop: { ...tempCropInfo } };
        case 'chooseVariety':
            return { ...state, variety: payload };
        case 'chooseSeason':
            return { ...state, season: payload };
        case 'chooseYear':
            const year = payload;
            const fromDate = year + '-01-01';
            const toDate = year + '-12-31';
            const seasonStartDate = fromDate;
            const seasonEndDate = toDate;
            return {
                ...state,
                year,
                fromDate,
                toDate,
                seasonStartDate,
                seasonEndDate,
            };
        case 'chooseSeasonStartDate': {
            const dayjs = require('dayjs');
            const dayjs_date = dayjs(payload);
            const date = dayjs_date.date();
            const month = dayjs_date.month() + 1;
            const year = dayjs_date.year();
            const dateFilterValue = year + '-' + month + '-' + date;
            return {
                ...state,
                seasonStartDate: dateFilterValue,
                // fromDate: payload,
                fromDate: dateFilterValue,
            };
        }
        case 'chooseSeasonEndDate': {
            const dayjs = require('dayjs');
            const dayjs_date = dayjs(payload);
            const date = dayjs_date.date();
            const month = dayjs_date.month() + 1;
            const year = dayjs_date.year();
            const dateFilterValue = year + '-' + month + '-' + date;
            return {
                ...state,
                seasonEndDate: dateFilterValue,
                toDate: dateFilterValue,
            };
        }
        case 'chooseCountry': {
            let tempContrydetails = {
                countryCode: payload,
                countryName: '',
            };
            return {
                ...state,
                countryDetails: { ...tempContrydetails },
            };
        }
        case 'chooseState': {
            let tempState = { geolevel1Code: payload, geolevel1Name: '' };
            return {
                ...state,
                geolevel1Details: { ...tempState },
            };
        }
        case 'setAllGeolevels': {
            let tempArray = payload;
            return { ...state, allGeoLevels: [...tempArray] };
        }
        case 'firstOrResetData': {
            let tempArray = payload;
            return { ...state, firstOrResetData: [...tempArray] };
        }
        case 'reset': {
            return {
                ...INITIAL_STATE,
                allGeoLevels: [...state.firstOrResetData],
                firstOrResetData: [...state.firstOrResetData],
                defaultCropList: [...state.defaultCropList],
            };
        }
        case 'resetData': {
            let tempArray = payload;
            return { ...state, resetData: [...tempArray] };
        }

        case 'RESET':
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default createCropReducer;
