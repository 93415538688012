/** @format */
import React, { useState, useEffect } from 'react';
import {
    InputLabel,
    styled,
    InputBase,
    Grid,
    Button,
} from '@mui/material';
import UserGeoLevelDropdown from './UserGeoLevelDropdown';
import {
    getCountryDetails,
    locationInputValuesProvided,
    resetLocationInputs,
} from '../../../redux/actions/createUser/create';
import { renderSvg, svgIconName } from '../../../assets/img/imageExt';

//Import CSS
import './LocationDetails.scss';
import { connect } from 'react-redux';
import { getRequest } from '../../../services/apiUtils';
import { getLocalStorageData } from '../../../utils/useLocalStorage';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '0.5px solid #919191',
        fontSize: 13,
        width: '180px',
        padding: '10px 12px',
        fontFamily:'appMedium',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

function LocationDetails(props: any) {
    const inputFields = [
        { label: 'Address', id: 'address', type: 'text' },
        { label: 'Zip-Code', id: 'zipCode', type: 'number' },
    ];
    const [err, setErr] = useState<string>('');

    useEffect(() => {
        getCountryData()
    }, []);

    function getCountryData(){
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);
        let lob = loggedUser.lineofbusiness;
        getRequest({
            url: 'lookup/country/config',
            method: 'GET'
        }).then((response: any) => {
            if (response?.body?.data) {
               let countries = response.body.data.countries
               countries.forEach((country:any)=>{
                    if (country.countrycode === loggedUser?.countrycode) {
                        let countryName = country.country;
                        let countryCode = country.countrycode;
                        props.getDefaultCountryDetails(countryCode, countryName, lob);
                    }
               })
            }
        });
    }
    const handlePageNext = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);
        let emptyGeolevelsArray = props.locationMapping.allgeolevels.filter(
            (level: any) => {
                if (
                    ( loggedUser?.countrycode !== 'TH' &&  level.name !== 'geolevel4code')
                    &&
                    (level.selectedValue === '' ||
                    level.selectedValue === 'ALL')
                ) {
                    return level.name;
                }
            }
        );
        if (
            emptyGeolevelsArray.length === 0 &&
            props.locationMapping.address.trim() !== '' &&
            props.locationMapping.zipCode !== ''
        ) {
            props.handleGoToNextPage(2);
        } else {
            setErr('*All fields are mandatory');
        }
    };
    return (
        <div>
            <div className='sub-header'> Location Mapping </div>
            <label style={{ color: '#7E7E7E' }}>
                Enter your personal information of the user you are trying to
                create.
            </label>
            <div className='containerDiv'>
                <div style={{ marginBottom: '50px' }}>
                    <InputLabel className='label-header'>Country</InputLabel>

                    <BootstrapInput
                        value={props.locationMapping.countryName}
                        disabled
                    />
                </div>

                <Grid container spacing={4}>
                    <UserGeoLevelDropdown
                        nextButtonClicked={props.nextButtonClicked}
                    />
                    {inputFields.map((inputObj: any) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={inputObj.label}>
                                <InputLabel className='label-header'>
                                    {inputObj.label}
                                </InputLabel>
                                <BootstrapInput
                                    value={
                                        inputObj.id === 'address'
                                            ? props.locationMapping.address
                                            : props.locationMapping.zipCode
                                    }
                                    placeholder={`Enter the ${inputObj.label}`}
                                    onChange={(e) => {
                                        props.changeLocationInputValues(
                                            inputObj.id,
                                            e.target.value
                                        );
                                    }}
                                    className='dropdown-values'
                                    type={inputObj.type}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
            {err && <div className='compulsory'>{err}</div>}

            <div className='threeButtonsContainer'>
                <span style={{ display: 'flex' }}>
                    <span
                        className='role_def_back_icon'
                        style={{ transform: 'rotate(90deg)' }}>
                        {renderSvg(svgIconName.down_arrow, 10)}
                    </span>
                    <a
                        onClick={() => {
                            props.goBackOnePage(0);
                        }}
                        className='roleDef_goBack_text'>
                        Go back
                    </a>
                </span>

                <span className='threeButtonsReset'>
                    <Button
                        disabled={props.disableFields}
                        style={{
                            width: '181px',
                            height: '35px',
                        }}
                        onClick={() => {
                            props.resetLocationInputs();
                        }}
                        className='roleDef_reset_btn'>
                        Reset
                    </Button>
                </span>

                <span>
                    <Button
                        onClick={handlePageNext}
                        style={{
                            borderRadius: '20px',
                            backgroundColor: '#7eb343',
                            width: '181px',
                            height: '35px',
                            border: 0,
                            boxShadow: '0px 3px 6px #c7c7c729',
                        }}
                        className='roleDef_next_btn'>
                        Next
                    </Button>
                </span>
            </div>
        </div>
    );
}

const mapStateToProps = ({ createUser: { locationMapping } }: any) => ({
    locationMapping,
});

const mapDispatchToProps = {
    getDefaultCountryDetails: (countryCode:any,countryName:any,lob:any)=>getCountryDetails(countryCode,countryName,lob),
    changeLocationInputValues: locationInputValuesProvided,
    resetLocationInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails);
