/** @format */

import { Fragment, ChangeEvent } from 'react';
import SearchBar from './SearchBar';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import '../../assets/scss/filter.scss';
import PopupFilter from './PopupFilter';
import { Badge } from '@mui/material';
import CreateNewAdvisory from '../../pages/service-alerts/custom-advisory/CreateNewAdvisory';
import SyncVendor from './sync-vendor/SyncVendor';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import html2canvas from 'html2canvas';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: 1,
    cursor: 'pointer',
}));
interface StackProps {
    userList?: any;
    isDownloadIcon?: boolean;
    isFilterIcon?: boolean;
    isCaptureIcon?: boolean;
    children?: any;
    closeFlag?: any;
    onClose?: any;
    displayCreateNew: boolean;
    handleOnClickDownload?: () => void;
    customHeight: any;
    isBadgeEnabled?: boolean;
    isCreateAdvisory?: boolean;
    setMakeAPICall?: any;
    sync?: boolean;
    onVendor?: (vendorName: string) => void;
    vendor?: string[];
}

function DirectionStack({
    isDownloadIcon = false,
    isFilterIcon = false,
    isCaptureIcon = false,
    children,
    onClose,
    closeFlag,
    displayCreateNew = false,
    handleOnClickDownload,
    customHeight,
    isBadgeEnabled = false,
    isCreateAdvisory = false,
    setMakeAPICall,
    sync = false,
    onVendor,
    vendor,
}: StackProps) {

    const capture = () => {
        html2canvas(document.body, { allowTaint: true, useCORS: true }).then(
            function (canvas) {
                const a = document.createElement('a');
                a.href = canvas
                    .toDataURL('..assets/image/jpeg',1.0)
                    .replace('image/jpeg',1.0);
                a.download = 'Dashboard-Map.jpeg';
                a.click();
            }
        );
    };

    return (
        <div>
            <Stack direction='row' spacing={2}>
                {isCreateAdvisory && (
                    <CreateNewAdvisory
                    setMakeAPICall={setMakeAPICall}
                    />
                )}
                {sync && <SyncVendor onVendor={onVendor} />}
                {isCaptureIcon && 
                    <button 
                        style={{
                            padding:'6px' , 
                            cursor:'pointer'
                        }} 
                        onClick={capture}
                    >
                        <CameraEnhanceIcon />
                    </button>
                }
                {isFilterIcon && !isBadgeEnabled && (
                    <Badge badgeContent={''} color='default'>
                        <PopupFilter
                            onClose={onClose}
                            customHeight={customHeight}
                            closeFlag={closeFlag}>
                            {children}
                        </PopupFilter>
                    </Badge>
                )}
                {isFilterIcon && isBadgeEnabled && (
                    <Badge badgeContent={''} color='secondary' variant='dot'>
                        <PopupFilter
                            onClose={onClose}
                            customHeight={customHeight}
                            closeFlag={closeFlag}>
                            {children}
                        </PopupFilter>
                    </Badge>
                )}
                {isDownloadIcon && (
                    <Item>
                        <FileDownloadOutlinedIcon
                            onClick={handleOnClickDownload}
                        />
                    </Item>
                )}
            </Stack>
        </div>
    );
}

interface Props {
    alertType?: any;
    isDownloadIcon?: boolean;
    isFilterIcon?: boolean;
    isCaptureIcon?: boolean;
    placeHolder?: any;
    searchOption1?: any;
    searchOption2?: any;
    onUserInput?: (querySearch: string) => void;
    onInvaildUserInput?: (invalidInput: string) => void;
    children?: any;
    searchText?: any;
    handleSearch?: (event: ChangeEvent<HTMLInputElement>) => any;
    onClose?: any;
    closeFlag?: any;
    displayCreateNew?: boolean;
    handleOnClickDownload?: () => void;
    onKeyDown?: (event: any) => void;
    customHeight?: any;
    isBadgeEnabled?: boolean;
    isCreateAdvisory?: boolean;
    setMakeAPICall?: any;
    sync?: boolean;
    onVendor?: (vendorName: string) => void;
}

// const userListContext = createContext<any>(null)
/**
 *Filter Functional Component
 * @param props
 * @returns
 */
const Filter: React.FC<Props> = ({
    alertType,
    isDownloadIcon = false,
    isFilterIcon = false,
    isCaptureIcon = false,
    placeHolder,
    searchOption1,
    searchOption2,
    onUserInput,
    children,
    closeFlag,
    onInvaildUserInput,
    searchText,
    handleSearch,
    onClose,
    displayCreateNew = false,
    handleOnClickDownload,
    customHeight,
    onKeyDown,
    isBadgeEnabled = false,
    isCreateAdvisory = false,
    setMakeAPICall,
    sync = false,
    onVendor,
}) => {
    return (
        <Fragment>
            <div
                className={
                    alertType !== 'RAINFALL_ALERT'
                        ? 'main-filter'
                        : 'weather_alert_filter'
                }>
                
                {alertType !== 'RAINFALL_ALERT' &&  (
                    <SearchBar
                        value={searchText}
                        name='searchInput'
                        onChange={handleSearch}
                        placeHolder={placeHolder}
                        id='search-input'
                        subtitle1={searchOption1}
                        subtitle2={searchOption2}
                        onKeyDown={onKeyDown}
                    />
                )}
                {/* </div> */}
                <DirectionStack
                    isDownloadIcon={isDownloadIcon}
                    isFilterIcon={isFilterIcon}
                    isCaptureIcon={isCaptureIcon}
                    displayCreateNew={displayCreateNew}
                    onClose={onClose}
                    closeFlag={closeFlag}
                    customHeight={customHeight}
                    handleOnClickDownload={handleOnClickDownload}
                    isBadgeEnabled={isBadgeEnabled}
                    isCreateAdvisory={isCreateAdvisory}
                    setMakeAPICall={setMakeAPICall}
                    sync={sync}
                    onVendor={onVendor}>
                    {children}
                </DirectionStack>
            </div>
        </Fragment>
    );
};

export default Filter;
