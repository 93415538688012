/** @format */

// Importing scss files to use across application
import './assets/scss/global.scss';
import './assets/scss/fonts.scss';

//To handle remember me
import { getLocalStorageData } from './utils/useLocalStorage';
import Authorization from './utils/authorization';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import Layout from './components/layouts';
import {  Routes, BrowserRouter, Route } from 'react-router-dom';
import Loader from './components/ui/Loader';
import { store } from './redux/store/index';
import { PrivateRoute } from './utils/privateRoutes';
import { ToastContainer } from 'react-toastify';
import { PublicRoute } from './utils/publicRoutes';
import PestAlertNotification from './pages/service-alerts/pest-alert-notification/index';
import UserList from './pages/User/List';
import AddUser from './pages/Add';
import UserManagement from './pages/user-management/index';
//import Stageinfo from './pages/add-crop/crop-details/stageinfo';
//import BasicInfo from './pages/add-crop/basic-info/BasicInfo';
import NewCrop from './pages/add-crop/index';
import { Provider } from 'react-redux';
// MSAL imports
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './pages/auth/authConfig';
import CustomAdvisory from './pages/service-alerts/custom-advisory';
import CropCalender from './pages/cropCalender/cropCalender';
import CropWiseStage from './pages/cropstage-advisory';



const FrontComponent = React.lazy(() => import('./pages/auth/index'));
const Dashboard = React.lazy(() => import('./pages/dashboard/index'));
const WeatherAlertNotification = React.lazy(() =>
import('./pages/service-alerts/weather-alerts-notification/index')
);
const DiseaseAlertNotification = React.lazy(() =>
  import('./pages/service-alerts/disease-alert-notification/index')
);

const DeviceManagement = React.lazy(() =>
    import('./pages/device-management/index')
);



const MapView = React.lazy(() => import('./pages/dashboard/mapView/mapView'));

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const theme = createTheme();
// const history = createBrowserHistory();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const isRemember = () => {
    const data: any = getLocalStorageData('userData');
    let userinfo = JSON.parse(data);
    if (!sessionStorage.userLoggedIn) {
        if (userinfo?.isRememberme === false) {
            Authorization.logOut();
        }
    }
};
isRemember();


root.render(
    <div className='App'>
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <MsalProvider instance={msalInstance}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <Layout>
                                <Suspense fallback={<Loader />}>
                                    <ToastContainer />
                                    <Routes>
                                        <Route
                                            path='/'
                                            element={
                                                <PublicRoute>
                                                    <FrontComponent />
                                                </PublicRoute>
                                            }
                                        />
                                        <Route
                                            path='/dashboard'
                                            element={
                                                <PrivateRoute>
                                                    <Dashboard />
                                                </PrivateRoute>
                                            }
                                        />

                                       <Route
                                            path='/dashboard/map-view'
                                            element={
                                                <PrivateRoute>
                                                    <MapView />
                                                </PrivateRoute>
                                            }
                                        />

                                      
                                        <Route
                                            path='/users'
                                            element={
                                                <PrivateRoute>
                                                    <UserList />
                                                </PrivateRoute>
                                            }
                                        />
                                     <Route
                                            path='/add-user'
                                            element={
                                                <PrivateRoute>
                                                    <AddUser />
                                                </PrivateRoute>
                                            }
                                        /> 
                                        <Route
                                            path='/user-management'
                                            element={
                                                <PrivateRoute>
                                                    <UserManagement />
                                                </PrivateRoute>
                                            }
                                        /> 
                                        <Route
                                            path='/device'
                                            element={
                                                <PrivateRoute>
                                                    <DeviceManagement />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path='/service-alerts/weather'
                                            element={
                                                <PrivateRoute>
                                                    <WeatherAlertNotification />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path='/service-alerts/'
                                            element={
                                                <PrivateRoute>
                                                    <DiseaseAlertNotification />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                           path='/service-alerts/disease'
                                            element={
                                                <PrivateRoute>
                                                    <DiseaseAlertNotification />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path='/service-alerts/pest'
                                            element={
                                                <PrivateRoute>
                                                    <PestAlertNotification />
                                                </PrivateRoute>
                                            }
                                        /> 
                                        <Route
                                            path='/service-alerts/advisory'
                                            element={
                                                <PrivateRoute>
                                                    <CustomAdvisory />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path='/crop-calendar'
                                            element={
                                                <PrivateRoute>
                                                    <CropCalender/>
                                                </PrivateRoute>
                                            }
                                        />
                                          <Route
                                            path='/cswa'
                                            element={
                                                <PrivateRoute>
                                                    <CropWiseStage />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path='/add-crop'
                                            element={
                                                <PrivateRoute>
                                                    <NewCrop />
                                                </PrivateRoute>
                                            }
                                        />
                                    </Routes>
                                </Suspense>
                            </Layout>
                        </BrowserRouter>
                    </Provider>
                </MsalProvider>
            </StyledEngineProvider>
        </ThemeProvider>
    </div>
);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
