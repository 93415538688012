/** @format */

import { Fragment, useEffect, useState, ChangeEvent, React } from 'react';
import CustomTabs from '../../../components/ui/CustomTabs';
import Filter from '../../../components/ui/Filter';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
    Chip as MuiChip,
    ChipProps as MuiChipProps,
    MenuItem,
    Select,
} from '@mui/material';
import LocationSvg from '../../../assets/img/svg/reusable/map_pin.svg';
import Stack from '@mui/material/Stack';
import AvatarGroup from '@mui/material/AvatarGroup';
import SensorsIcon from '@mui/icons-material/Sensors';
import FarmerDetails from './Details';
import Tooltip from '@mui/material/Tooltip';
import {
    assignedNull,
    download,
    findCropImg,
    optionList,
    tableParams,
    anyKey,
} from '../../../utils/helper';
import { getRequest } from '../../../services/apiUtils';
import { Alert } from '../../../utils/toastr';
import Loader from '../../../components/ui/Loader';
import FarmerImg from '../../../assets/img/svg/farmer-page-icons/farmer.svg';
import TopHeader from '../../../components/layouts/TopHeader';
import LeftArrow from '../../../assets/img/svg/farmer-page-icons/left_arrow.svg';
import _ from 'lodash';
import { CustomButton } from '../../../components/form/CustomButton';
//scss
import './index.scss';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import GeoLevelDropdown from '../../../components/ui/GeoLevelDropdown';
import Footer from '../../../components/layouts/Footer';
import Pagination from '../../../components/ui/Pagination';
import {
    useSearchParams,
    useLocation,
    useParams,
    useNavigate,
} from 'react-router-dom';
import ReactSelect from '../../../components/form/ReactSelect';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Divider from '@mui/material/Divider';

const Item = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    // maxWidth: 350,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #54545429',
    border: '1px solid #B7B7B7',
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));
const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    padding: theme.spacing(1),
    '& .MuiCardHeader-title': {
        color: '#10384F',
    },
    '& .MuiCardHeader-subheader': {
        color: '#696969',
    },
    '& .MuiTypography-root': {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '0.85rem',
        },
    },
}));
const StyledBox = styled(Box)(({ theme }) => ({
    overflowY: 'auto',
    [theme.breakpoints.up('xs')]: {
        height: '63vh',
    },
    [theme.breakpoints.up('sm')]: {
        height: '74vh',
    },
    [theme.breakpoints.up('md')]: {
        height: '78vh',
    },
    [theme.breakpoints.up('lg')]: {
        height: '64vh',
    },
    [theme.breakpoints.up('xl')]: {
        height: '85vh',
    },
}));
interface ChipProps extends MuiChipProps {
    status?: string;
    activefilter?: string;
}

const condStatus: any = {
    'ACTIVE': {
        color: '#89D329',
        border: '1px solid #E3F1D0',
        backgroundColor: '#E3F1D0',
    },
    'INACTIVE': {
        color: '#FFFFFF',
        border: '1px solid #FD513B',
        backgroundColor: '#FD513B',
    },
    'PENDING': {
        color: '#ff9d00',
        border: '1px solid #ff9d00',
        backgroundColor: '#ffebcc',
    },
};
const Chip = styled(MuiChip)<ChipProps>(({ status, activefilter, theme }) => ({
    ...(status && {
        ...condStatus[status],
    }),
    ...(activefilter === 'true' && {
        backgroundColor: '#10384F',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#10384F !important',
            color: '#ffffff',
        },
    }),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '0.75rem',
    },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '0.75rem',
    },
}));
const tabData = [
    { label: 'All', value: 'ALL' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
    { label: 'Pending (Awaiting Consent)', value: 'PENDING' },
];
interface ChipData {
    value: string;
    label: string;
}

interface Props {}
interface FilterTypes {
    season: string;
    cropname: string;
    vendor: string;
    geolevel0code: string;
    geolevel1code: string;
    geolevel2code: string;
    geolevel3code: string;
    geolevel4code: string;
    geolevel5code: null;
    sortby: string;
}

/**
 *User List Functional Component
 * @param props
 * @returns
 */
const UserList: React.FC<Props> = () => {
    let closeToggle: any;
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    const dayjs = require('dayjs');
    const [search, setSearch] = useState('');
    // const [status, setTabStatus] = useState('ACTIVE');
    const [status, setTabStatus] = useState('ALL');
    const [isShowDetails, setIsShowDetails] = useState(false);
    const [params, setParams] = useState(tableParams());
    const [userList, setUserList] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({});
    const [firstPageLoad, setFirstPageLoad] = useState<boolean>(true);
    const [applyflag, setapplyflag] = useState<any>(false);
    const [isClosed, setIsClosed] = useState<any>(false);
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [errorForSeason, setErrorForSeason] = useState<any>('');
    const [filter, setFilter] = useState<any>({
        season: 'ALL',
        cropname: 'ALL',
        vendor: 'ALL',
        geolevel0code: loggedUser?.countrycode,
        geolevel1code: 'ALL',
        geolevel2code: 'ALL',
        geolevel3code: 'ALL',
        geolevel4code: 'ALL',
        geolevel5code: null,
        sortby: 'createddate',
        fromdate: '',
        todate: '',
        seasonYear: dayjs(null),
    });
    const [isAPICall, setIsAPICall] = useState<boolean>(false);
    const [chipSeasonData, setChipSeasonData] = useState<readonly ChipData[]>([]);
    const [chipVendorData, setChipVendorData] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);
    const [chipCropData, setChipCropData] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);
    const [chipSortByData, setSortByChipData] = useState<readonly ChipData[]>([
        { value: 'createddate', label: 'Created Date' },
        { value: 'firstname', label: 'Farmer Name' },
    ]);
    const [geoLevelList, setGeoLevelList] = useState<any>([]);
    const [geoLoader, setGeoLoader] = useState<boolean>(true);
    const [isClearGeoLevel, setIsClearGeoLevel] = useState<boolean>(false);
    const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
    const [dataPerPage, setDataPerPage] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState<number>();
    const [runHandleSearch, setRunHandleSearch] = useState<boolean>(false);
    const [event, setEvent] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [selectPageOne, setSelectPageOne] = useState(true);
    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(false);
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];
    const [isBackClicked, setIsBackClicked] = useState<boolean>(false);
    const [farmerCurrentPage, setFarmerCurrentPage] = useState<number>();
    const [cropval, setCropval] = useState<any>('ALL');
    const [seasonval, setSeasonval] = useState<any>('ALL');
    const [yearval, setYearval] = useState<any>('');
    const [vendorval, setVendorval] = useState<any>('ALL');
    const [current, setCurrent] = useState(0);
    const [totaldeviceCount, setTotalDeviceCount] = useState<any[]>([]);
    const [geolevel, setgeolevel] = useState<any>({ ...filter });
    const [currOptions, setcurrOptions] = useState<any>([]);
    const [fromValue, setFromValue] = useState<any>(null);
    const [toValue, setToValue] = useState<any>(null);
    const [appliedFromDate, setAppliedFromDate] = useState<Dayjs | null>(null);
    const [appliedToDate, setAppliedToDate] = useState<Dayjs | null>(null);
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);
    const [minDate, setMinDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(2, 'year');
        return currentDate;
    });
    const [maxDate, setMaxDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().add(2, 'year');
        return currentDate;
    });
    const [minDateSeason, setMinDateSeason] = useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(1, 'year');
        return currentDate;
    });
    const [maxDateSeason, setMaxDateSeason] = useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().add(1, 'year');
        return currentDate;
    });
    const [errormsg, setErrormsg] = useState<any>('');
    //Initial API call
    const location = useLocation();

    useEffect(() => {
        getLookup();
        getRecords();
        setFirstPageLoad(false);
        if (location.state !== null && location.state.isDeviceDetail) {
            setIsShowDetails(true);
            setUser(location.state.farmer);
            window.history.replaceState({}, document.title);
        }
    }, []);

    /**
     * Get status tab value
     * @param value
     */
    const getTabsValue = (value: string) => {
        setTabStatus(value);

        setParams(tableParams());
        setSelectPageOne(true);
        searchParams.append('status', value);
        setSearchParams({ ...Object.fromEntries([...searchParams]) });
        // search.length ? setSearchParams({search, status}) : setSearchParams({status: value})
    };

    /**
     * Get records in farmer list
     */
    const getRecords = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);
        let obj: any = {
            ...params,
            countrycode: loggedUser?.countrycode,
            customertype: 'FARMER',
            // status,
            search: search || null,
            lob: loggedUser?.lineofbusiness,
            isfiltered: isFiltered,
        };

        if (
            specificPersona.includes(loggedUser.roleid) &&
            loggedUser.assignedloc.length > 0
        ) {
            let location = loggedUser.assignedloc.map(
                (loc: any) => loc.lochiergeocode
            );
            obj = {
                ...obj,
                ['isfiltered']: true,
                [obj.countrycode === 'TH'
                    ? 'geolevel3code'
                    : 'geolevel4code']: location.join(','),
            };
        }

        if (status !== 'ALL') {
            obj = { ...obj, status };
        }

        if (isFiltered) {
            if (
                specificPersona.includes(loggedUser.roleid) &&
                loggedUser.assignedloc.length > 0
            ) {
                let location = loggedUser.assignedloc.map(
                    (loc: any) => loc.lochiergeocode
                );
                obj.countrycode === 'TH'
                    ? (filter.geolevel3code = location.join(','))
                    : (filter.geolevel4code = location.join(','));
            }

            let condFilter = assignedNull(filter);
            obj = { ...obj, ...condFilter };
        }

        setLoader(true);
        getRequest({
            url: 'customer/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    setUserList(response?.body?.data);
                    setTotalCount(response?.body?.totalcount);
                    setTotalDeviceCount(response?.body?.data?.devices);
                } else {
                    setUserList([]);
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setUserList([]);
                Alert('error', error?.message);
                setLoader(false);
                setUserList([]);
            });
    };
    /**
     * Get records are season list and crop list
     */
    const getLookup = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggeduser = JSON.parse(lsData);

        let obj: any = {
            countrycode: loggeduser?.countrycode,
            lob: loggeduser?.lineofbusiness,
        };
        setLoader(true);
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let vendorDataOrg = response?.body?.data?.vendor;

                    if (vendorDataOrg.length > 0) {
                        let vendorFilter = vendorDataOrg.map((vendor: any) => {
                            return {
                                label: vendor,
                                value: vendor,
                            };
                        });
                        setChipVendorData([...chipVendorData, ...vendorFilter]);
                    } else {
                        setChipVendorData([...chipVendorData]);
                    }

                    const cropData = response?.body?.data?.croplist;

                    if (cropData?.length > 0) {
                        const filterOption = optionList(
                            cropData,
                            'cropcode',
                            'cropname'
                        );
                        setChipCropData([...chipCropData, ...filterOption]);
                    } else {
                        setChipCropData([...chipCropData]);
                    }
                }

                setLoader(false);
            })
            .catch((error: any) => {
                setChipCropData([...chipCropData]);
                setChipVendorData([...chipVendorData]);
                Alert('error', error?.message);
                setLoader(false);
            });
    };

    const getSeasonLookup = (dateValue:any) => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let seasonobj: any = {
            countrycode: loggedUser?.countrycode,
            lob:
                loggedUser?.roleid === 'ADMIN' ||
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
            year: dateValue.year(),
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: seasonobj,
        }).then((res: any) => {
            if (res?.body?.data) {
                const seasonDataOrg = res.body.data.seasonlist;
                if (seasonDataOrg.length > 0 && seasonDataOrg !== 0) {
                    setErrormsg('');
                    const filt = optionList(seasonDataOrg, 'season', 'season');
                    chipSeasonData.length === 1
                        ? setChipSeasonData([...chipSeasonData, ...filt])
                        : setChipSeasonData([...filt]);
                } else {
                    setChipSeasonData([]);
                    setErrormsg('No seasons available for selected year');
                }
            }
        });
    };

    useEffect(() => {
        if (firstPageLoad != true) {
            getRecords();
            setIsAPICall(false);
        }
    }, [isAPICall]);

    const getOneUser = (row: Object) => {
        setIsShowDetails(true);
        setUser(row);
    };

    const handleResetFilter = () => {
        setIsClearGeoLevel(true);
        setIsFiltered(false);
        setapplyflag(false);
        setIsApplyFilter(false);
        setParams(tableParams());
        setSelectPageOne(true);
        setFilter({
            season: 'ALL',
            cropname: 'ALL',
            vendor: 'ALL',
            geolevel0code: loggedUser?.countrycode,
            geolevel4code: 'ALL',
            geolevel5code: null,
            sortby: 'createddate',
            fromdate: '',
            todate: '',
            seasonYear: dayjs(null),
        });
        closeToggle && closeToggle();
        setFromValue(null);
        setToValue(null);
        setSelectPageOne(true);
        setBadgeEnabled(false);
        setErrorForSeason('');
        setErrormsg('');
    };
    const handleApplyFilter = (event: any) => {
        var error = [];

        if (
            filter.seasonYear !== null &&
            !isNaN(filter.seasonYear.year()) &&
            filter.season === 'ALL'
        ) {
            error.push('please select a season after selecting an year');
        }
        if (error.length === 0) {
            event.preventDefault();
            setapplyflag(true);
            setParams(tableParams());
            setSelectPageOne(true);
            setIsClearGeoLevel(false);
            setIsFiltered(true);
            setIsApplyFilter(true);
            closeToggle && closeToggle();
            setSelectPageOne(true);
            setBadgeEnabled(true);
            setCropval(filter.cropname);
            setSeasonval(filter.season);
            setYearval(filter.seasonYear);
            setVendorval(filter.vendor);
            setAppliedFromDate(filter.fromdate);
            setAppliedToDate(filter.todate);
            let selectedFilter: any = { ...filter };
            let temparr: any = [];
            if (geoLevelList?.length) {
                geoLevelList.forEach((level: any) => {
                    selectedFilter = {
                        ...selectedFilter,

                        [level.name]: level.selectedValue,
                    };
                    temparr = [...temparr, level.options];
                });
                setcurrOptions([...temparr]);
                setgeolevel({ ...selectedFilter });
            }
        } else {
            if (error.length > 0) {
                let errors = '';
                error.forEach((value: any, index: any) => {
                    if (index === error.length - 1) {
                        errors += value;
                    } else {
                        errors += value + ',';
                    }
                });
                if (!errormsg) {
                    setErrorForSeason(errors);
                    return true;
                }
            }
        }
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let search = event.target.value;
        setSearch(search);
        setRunHandleSearch(true);
        setSelectPageOne(true);
    };

    const keyHandler = (event: any) => {
        setEvent(event);
        anyKey(
            event,
            search,
            setIsAPICall,
            setSearchParams,
            searchParams,
            setParams,
            status
        );
    };

    if (runHandleSearch) {
        anyKey(
            event,
            search,
            setIsAPICall,
            setSearchParams,
            searchParams,
            setParams,
            status
        );
        setRunHandleSearch(false);
    }

    /**
     * This method handle filters params
     * @param value
     * @param name
     */
    const handleFilter = (value: any, name: string) => {
        setFilter({ ...filter, [name]: value });
    };
    const handleGetGeoLevelData = (array: any[]) => {
        setGeoLevelList([...array]);
    };
    useEffect(() => {
        let selectedFilter: any = { ...filter };
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,
                    [level.name]: level.selectedValue,
                };
            });

            setFilter({ ...selectedFilter });
        }
    }, [geoLevelList]);

    const toPagi = (currentPosts: any[], noOfPages: number) => {
        setDataPerPage(currentPosts);
    };
    // const pageNoSelected = () => {
    //     setIsFiltered(false);
    // };

    const pageNoSelected = (pageNo: number, postsPerPage: number) => {
        // setIsFiltered(false);

        setFarmerCurrentPage(pageNo);
        pageNo === 1 ? setSelectPageOne(true) : setSelectPageOne(false);
        let offset = (pageNo - 1) * postsPerPage;
        setParams((current) => {
            return { ...current, offset: offset };
        });
    };

    useEffect(() => {
        if (firstPageLoad != true) {
            getRecords();
        }
        setFirstPageLoad(false);
    }, [params]);

    // //
    const parameterName = new URLSearchParams(window.location.search);

    //
    // const params1 = useParams()
    //

    const navigate = useNavigate();
    //

    // const value= tabData.forEach((obj) => {obj.value === status})
    let tabValue: number = 0;
    for (let i = 0; i < tabData.length; i++) {
        if (tabData[i].value === status) {
            tabValue = i;
        }
    }

    const onCloseHandler = (node: any) => {
        closeToggle = node;
        if (!applyflag && isClosed) {
            setIsClearGeoLevel(true);
            setFilter({
                ...filter,
                cropname: 'ALL',
                season: 'ALL',
                vendor: 'ALL',
                fromdate: '',
                todate: '',
                seasonYear: dayjs(null),
            });
            setFromValue(null);
            setToValue(null);
            setIsClosed(false);
            setGeoLoader(true);
            setErrorForSeason('');
            setErrormsg('');
        } else if (applyflag && isClosed) {
            setFilter({
                season: seasonval,
                cropname: cropval,
                vendor: vendorval,
                geolevel0code: loggedUser?.countrycode,
                geolevel1code: geolevel.geolevel1code,
                geolevel2code: geolevel.geolevel2code,
                geolevel3code: geolevel.geolevel3code,
                geolevel4code: geolevel.geolevel4code,
                geolevel5code: null,
                fromdate: appliedFromDate,
                todate: appliedToDate,
                seasonYear: yearval,
            });
            setFromValue(appliedFromDate);
            setToValue(appliedToDate);
            geoLevelList.map((geo: any) => {
                if (geo.name === 'geolevel1code') {
                    geo.selectedValue = geolevel.geolevel1code;
                    geo.options = currOptions[0];
                }
                if (geo.name === 'geolevel2code') {
                    geo.selectedValue = geolevel.geolevel2code;
                    geo.options = currOptions[1];
                }
                if (geo.name === 'geolevel3code') {
                    geo.selectedValue = geolevel.geolevel3code;
                    geo.options = currOptions[2];
                }
                if (geo.name === 'geolevel4code') {
                    geo.selectedValue = geolevel.geolevel4code;
                    geo.options = currOptions[3];
                }
            });
            setIsClosed(false);
            setGeoLoader(true);
            setErrorForSeason('');
            setErrormsg('');
        }
    };

    const closeFlagHandler = (bool: any) => {
        setIsClosed(bool);
    };

    const handleFromDate = (newValue: Dayjs | null) => {
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);

        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = year + '-' + month + '-' + date;
        setFromValue(newValue);
        setFromDate(dateFilterValue);
        setFilter({ ...filter, ['fromdate']: dateFilterValue });
    };

    const handleToDate = (newValue: Dayjs | null) => {
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);

        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = year + '-' + month + '-' + date;
        setToValue(newValue);
        setToDate(dateFilterValue);
        setFilter({ ...filter, ['todate']: dateFilterValue });
    };

    return (
        <Fragment>
            <TopHeader>
                {!isShowDetails ? (
                    <h3>Farmer</h3>
                ) : (
                    <div className='farmer-details'>
                        <img
                            src={LeftArrow}
                            onClick={() => {
                                setIsShowDetails(false);
                                // setTabStatus(status)

                                setIsBackClicked(true);
                            }}
                            alt='Left arrow'
                        />
                        <h3>Farmer Details</h3>
                    </div>
                )}
            </TopHeader>
            <div className={`${!isShowDetails && 'middle-container'}`}>
                {loader ? <Loader /> : null}
                {!isShowDetails && (
                    <Filter
                        isDownloadIcon
                        isFilterIcon
                        placeHolder='Search Farmer (min 3 letters)'
                        searchOption1='Farmer Name'
                        searchOption2='Farmer Phone Number'
                        handleSearch={handleSearch}
                        searchText={search}
                        customHeight={450}
                        onClose={onCloseHandler}
                        closeFlag={closeFlagHandler}
                        handleOnClickDownload={() => {
                            download(
                                search,
                                isFiltered,
                                'customer',
                                undefined,
                                filter,
                                status,
                                'FARMER'
                            );
                        }}
                        onKeyDown={keyHandler}
                        isBadgeEnabled={badgeEnabled}>
                        <div>
                            <div
                                style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    paddingBottom: '5px',
                                }}>
                                {errorForSeason}
                            </div>
                            {!geoLoader && (
                                <>
                                    <div className='flex-user-display'>
                                        <div className='rowvendor'>
                                            <div className='title-label'>
                                                Vendor
                                            </div>
                                            <div className='list'>
                                                <Select
                                                    className='select-user-filter'
                                                    value={filter.vendor}
                                                    onChange={(event: any) =>
                                                        handleFilter(
                                                            event.target.value,
                                                            'vendor'
                                                        )
                                                    }>
                                                    {chipVendorData.map(
                                                        (data: any) => (
                                                            <MenuItem
                                                                value={
                                                                    data.value
                                                                }>
                                                                {data.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className='rowcrop'>
                                            <div className='title-label'>
                                                Crop
                                            </div>
                                            <div className='list'>
                                                <Select
                                                    className='select-user-crop-filter'
                                                    value={filter.cropname}
                                                    onChange={(event: any) =>
                                                        handleFilter(
                                                            event.target.value,
                                                            'cropname'
                                                        )
                                                    }>
                                                    {chipCropData.map(
                                                        (data: any) => (
                                                            <MenuItem
                                                                value={
                                                                    data.value
                                                                }>
                                                                {data.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='rowcalendar'>
                                        <div>
                                            <div className='title-label'>
                                                Calendar Year
                                            </div>
                                            <span>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        views={['year']}
                                                        value={
                                                            filter.seasonYear
                                                        }
                                                        minDate={minDateSeason}
                                                        maxDate={maxDateSeason}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            setFilter({
                                                                ...filter,
                                                                seasonYear: newValue,
                                                            });
                                                            getSeasonLookup(
                                                                newValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                className='myDatePickeruser'
                                                                type='tel'
                                                                helperText={
                                                                    null
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </span>
                                        </div>
                                        <div className='sowingDate_divideruser'>
                                            <Divider
                                                orientation='horizontal'
                                                variant='middle'
                                                flexItem
                                                style={{
                                                    borderColor: '#1A1A1A',
                                                }}
                                            />
                                        </div>
                                        <div className='listUser'>
                                            {errormsg.length > 0 && (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '13px',
                                                        paddingBottom: '5px',
                                                    }}>
                                                    {errormsg}
                                                </span>
                                            )}

                                            <div>Season</div>
                                            <Select
                                                className='select-user-vendor-filter'
                                                value={filter.season}
                                                onChange={(event: any) =>
                                                    handleFilter(
                                                        event.target.value,
                                                        'season'
                                                    )
                                                }>
                                                {chipSeasonData.map(
                                                    (data: any) => (
                                                        <MenuItem
                                                            value={data.value}>
                                                            {data.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </div>
                                    </div>
                                </>
                            )}

                            <GeoLevelDropdown
                                filter={filter}
                                handleFilter={handleFilter}
                                getGeoLevelData={handleGetGeoLevelData}
                                geoLevelList={geoLevelList}
                                isClearGeoLevel={isClearGeoLevel}
                                handleLoader={(isLoader: boolean) =>
                                    setGeoLoader(isLoader)
                                }
                            />
                            {!geoLoader && (
                                <>
                                    <div className='row'>
                                        <div className='title-label'>
                                            Sowing Date
                                        </div>
                                        <div className='sowingDate_div'>
                                            <span>
                                                <div>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDayjs
                                                        }>
                                                        <DatePicker
                                                            value={fromValue}
                                                            minDate={minDate}
                                                            maxDate={
                                                                toValue
                                                                    ? toValue
                                                                    : maxDate
                                                            }
                                                            onChange={
                                                                handleFromDate
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    type='tel'
                                                                    title='From date'
                                                                    placeholder='mm/dd/yyyy'
                                                                    helperText={
                                                                        null
                                                                    }
                                                                    sx={{
                                                                        width: 202,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </span>
                                            <span className='sowingDate_divider'>
                                                <Divider
                                                    orientation='horizontal'
                                                    variant='middle'
                                                    flexItem
                                                    style={{
                                                        borderColor: '#1A1A1A',
                                                    }}
                                                />
                                            </span>
                                            <span>
                                                <div className='toDate_div'>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDayjs
                                                        }>
                                                        <DatePicker
                                                            value={toValue}
                                                            onChange={
                                                                handleToDate
                                                            }
                                                            minDate={
                                                                fromValue
                                                                    ? fromValue
                                                                    : minDate
                                                            }
                                                            maxDate={maxDate}
                                                            views={[
                                                                'year',
                                                                'month',
                                                                'day',
                                                            ]}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    type='tel'
                                                                    title='To date'
                                                                    placeholder='mm/dd/yyyy'
                                                                    helperText={
                                                                        null
                                                                    }
                                                                    sx={{
                                                                        width: 198,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {!geoLoader && (
                            <div className='row filterFooter'>
                                <CustomButton
                                    label={'Reset'}
                                    style={{
                                        width: '200px',
                                        height: '35px',
                                    }}
                                    className='cus-btn-filter reset'
                                    handleClick={handleResetFilter}
                                />
                                <CustomButton
                                    label={'Apply'}
                                    style={{
                                        borderRadius: '20px',
                                        backgroundColor: '#7eb343',
                                        width: '200px',
                                        height: '35px',
                                        border: 0,
                                        boxShadow: '0px 3px 6px #c7c7c729',
                                    }}
                                    className='cus-btn'
                                    handleClick={handleApplyFilter}
                                />
                            </div>
                        )}
                    </Filter>
                )}

                {isShowDetails && (
                    <FarmerDetails
                        selectedUser={user}
                        setIsAPICall={setIsAPICall}
                    />
                )}
                {/* ( */}
                {!isShowDetails && (
                    <Fragment>
                        <CustomTabs
                            tabData={tabData}
                            getSeletedValue={getTabsValue}
                            myValue={tabValue}
                        />

                        <StyledBox sx={{ flexGrow: 1, mt: 1 }}>
                            <Grid
                                container
                                spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
                                columns={{
                                    xs: 2,
                                    sm: 8,
                                    md: 8,
                                    lg: 12,
                                    xl: 12,
                                }}>
                                {userList?.length > 0 ? (
                                    userList.map((user: any, index: number) => {
                                        return (
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={3}
                                                key={index}>
                                                <Item
                                                    onClick={() =>
                                                        getOneUser(user)
                                                    }>
                                                    <StyledCardHeader
                                                        avatar={
                                                            <img
                                                                src={FarmerImg}
                                                                alt='farmer'
                                                            />
                                                        }
                                                        action={
                                                            // <IconButton aria-label='settings'>
                                                            <Chip
                                                                label={_.capitalize(
                                                                    user?.status
                                                                )}
                                                                variant='outlined'
                                                                status={
                                                                    user?.status
                                                                }
                                                                sx={{
                                                                    fontFamily:
                                                                        'appRegular',
                                                                    // 'appMedium',
                                                                }}
                                                            />
                                                            // </IconButton>
                                                        }
                                                        title={(
                                                            user?.firstname +
                                                            ' ' +
                                                            user?.lastname
                                                        ).replace(
                                                            /\w+/g,
                                                            _.capitalize
                                                        )}
                                                        subheader={
                                                            loggedUser.countrycode ===
                                                            'TH'
                                                                ? `+66 ${user?.phonenumber}`
                                                                : `+91 ${user?.phonenumber}`
                                                        }
                                                    />
                                                    <CardContent>
                                                        <Stack
                                                            direction='row'
                                                            spacing={2}
                                                            alignItems='baseline'
                                                            mb={1}>
                                                            <img
                                                                src={
                                                                    LocationSvg
                                                                }
                                                                alt='location'
                                                            />
                                                            <StyledTypography
                                                                variant='body2'
                                                                color='text.secondary'
                                                                className='userlistFarmerAddress'
                                                                sx={
                                                                    {
                                                                        // fontSize:
                                                                        //     '0.75rem',
                                                                    }
                                                                }>
                                                                {loggedUser.countrycode ===
                                                                'TH' ? (
                                                                    <>
                                                                        {`${user?.geolevel3}, ${user?.geolevel2}, ${user?.geolevel1}`.replace(
                                                                            /\w+/g,
                                                                            _.capitalize
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {`${user?.geolevel4}, ${user?.geolevel3}, ${user?.geolevel2}, ${user?.geolevel1}`.replace(
                                                                            /\w+/g,
                                                                            _.capitalize
                                                                        )}{' '}
                                                                    </>
                                                                )}
                                                            </StyledTypography>
                                                        </Stack>
                                                        <Stack
                                                            direction='row'
                                                            spacing={2}
                                                            alignItems='center'>
                                                            <StyledTypography
                                                                variant='body2'
                                                                color='text.secondary'>
                                                                Crop (
                                                                {
                                                                    user
                                                                        ?.cropsgrown
                                                                        ?.length
                                                                }
                                                                )
                                                            </StyledTypography>
                                                            <AvatarGroup className='group-crop'>
                                                                {user
                                                                    ?.cropsgrown
                                                                    ?.length &&
                                                                    user.cropsgrown.map(
                                                                        (
                                                                            crop: any,
                                                                            index: number
                                                                        ) => {
                                                                            let condImg = findCropImg(
                                                                                crop?.cropname
                                                                            );
                                                                            return (
                                                                                <img
                                                                                    src={
                                                                                        condImg
                                                                                    }
                                                                                    style={{
                                                                                        paddingRight:
                                                                                            '5px',
                                                                                    }}
                                                                                    key={
                                                                                        crop.cropid
                                                                                    }
                                                                                    alt='crop'
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                            </AvatarGroup>
                                                        </Stack>
                                                    </CardContent>

                                                    <CardActions
                                                        disableSpacing
                                                        sx={{
                                                            marginTop: 'auto',
                                                        }}>
                                                        <IconButton aria-label='add to favorites'>
                                                            <SensorsIcon />
                                                        </IconButton>
                                                        <div>
                                                            <Stack className='serialnum-alignment'>
                                                                {user?.devices &&
                                                                user?.devices
                                                                    ?.length >
                                                                    2 ? (
                                                                    <>
                                                                        <Tooltip
                                                                            title={
                                                                                user
                                                                                    ?.devices[0]
                                                                                    ?.vendor
                                                                            }
                                                                            arrow>
                                                                            <Chip
                                                                                className='vendor-farmer'
                                                                                key={
                                                                                    0
                                                                                }
                                                                                label={
                                                                                    user
                                                                                        ?.devices[0]
                                                                                        ?.deviceserialno
                                                                                }
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'appMedium',
                                                                                    color:
                                                                                        '#6F6F6F',
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                user
                                                                                    ?.devices[1]
                                                                                    ?.vendor
                                                                            }
                                                                            arrow>
                                                                            <Chip
                                                                                className='vendor-farmer'
                                                                                key={
                                                                                    0
                                                                                }
                                                                                label={
                                                                                    user
                                                                                        ?.devices[1]
                                                                                        ?.deviceserialno
                                                                                }
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'appMedium',
                                                                                    color:
                                                                                        '#6F6F6F',
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                        {'+ ' +
                                                                            user
                                                                                ?.devices
                                                                                ?.length +
                                                                            ' Devices'}
                                                                    </>
                                                                ) : (
                                                                    user?.devices.map(
                                                                        (
                                                                            device: any,
                                                                            index: number
                                                                        ) => (
                                                                            <Tooltip
                                                                                title={
                                                                                    device?.vendor
                                                                                }
                                                                                arrow
                                                                                key={
                                                                                    device.deviceserialno
                                                                                }>
                                                                                <Chip
                                                                                    className='vendor-farmer'
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    label={`${device?.deviceserialno}`}
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'appMedium',
                                                                                        color:
                                                                                            '#6F6F6F',
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        )
                                                                    )
                                                                )}
                                                            </Stack>
                                                            {/*  <Stack
                                                            direction='row'
                                                            spacing={2}>
                                                            {user?.cropsgrown
                                                                ?.length
                                                                ? user?.cropsgrown.map(
                                                                      (
                                                                          crops: any,
                                                                          index: number
                                                                      ) => (
                                                                          <Chip
                                                                              key={
                                                                                  index
                                                                              }
                                                                              label={`${crops?.vendor}`}
                                                                             
                                                                              sx={{
                                                                                  fontFamily:
                                                                                      'appMedium',
                                                                                  color:
                                                                                      '#6F6F6F',
                                                                              }}
                                                                          />
                                                                      )
                                                                  )
                                                                            : 'NA'} 
                                                        </Stack>*/}
                                                        </div>
                                                    </CardActions>
                                                </Item>
                                            </Grid>
                                        );
                                    })
                                ) : (
                                    <Grid item sx={{ width: '100%' }}>
                                        {/* <Stack
                                            alignItems='center'
                                            justifyContent='center'>
                                            No records found!
                                        </Stack> */}
                                        <div className='user_error_msg'>
                                            No records found!
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </StyledBox>
                    </Fragment>
                )}
            </div>
            {!isShowDetails && userList.length ? (
                <Footer>
                    <Pagination
                        postsTitle='Farmer(s)'
                        posts={userList}
                        onIndex={toPagi}
                        postsPerPage={10}
                        onIndexPageNo={pageNoSelected}
                        totalCountAvailable={totalCount}
                        selectPageOne={selectPageOne}
                        isBackClicked={isBackClicked}
                        farmerCurrentPage={farmerCurrentPage}
                    />
                </Footer>
            ) : null}
            {/* </Fragment> */}
        </Fragment>
    );
};

export default UserList;
