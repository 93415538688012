/** @format */

import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
const drawerWidth: number = 240;

export const Drawer = styled(
    MuiDrawer
    //     {
    //     shouldForwardProp: (prop) => prop !== 'open',
    // }
)(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'fixed',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: '#F6F8FA',
        color: '#6E6E6E',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
