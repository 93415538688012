/** @format */

import React, { useState } from 'react';
import './index.scss';

import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useNavigate,createSearchParams,useLocation} from 'react-router-dom';
import  { Dayjs } from 'dayjs';

import { ReactComponent as TickIcon } from '../../../assets/img/svg/country-config/tick_icon.svg';
import { getLocalStorageData } from '../../../utils/useLocalStorage';

const ServiceAlertDropDownPage = (props: any) => {
    const history = useNavigate();

    var searchCriteria = useLocation();


    const [dropDown, setDropDown] = useState(
        props.alertType === 'DISEASE_PREDICTION'
            ? 'disease'
            : props.alertType === 'PEST_PREDICTION'
            ? 'pest'
            :props.alertType === 'RAINFALL_ALERT' ?
             'weather'
            : 'advisory'
    );

    const [diseaseStyle, setDiseaseStyle] = useState(
        props.alertType === 'DISEASE_PREDICTION' ? 'text_bold' : 'text_non_bold'
    );
    const [pestStyle, setPestStyle] = useState(
        props.alertType === 'PEST_PREDICTION' ? 'text_bold' : 'text_non_bold'
    );
    const [weatherStyle, setWeatherStyle] = useState(
        props.alertType === 'RAINFALL_ALERT' ? 'text_bold' : 'text_non_bold'
    );
    const [advisoryStyle, setAdvisoryStyleStyle] = useState(
        props.alertType === 'Custom_Advisory' ? 'text_bold' : 'text_non_bold'
    );

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'box-shadow',
            ]),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
            },
        },
    }));

    const handleChange = (event: SelectChangeEvent<string>) => {

        const dayjs = require('dayjs');
        const currentDate = dayjs(Dayjs);

        const month = currentDate.month() + 1;
        const year = currentDate.year();
        const dateFilterValue = month + '-' + year;

        var urlSearchParams = new URLSearchParams(searchCriteria.search);
        
        var pageToRedirect: string = '';
        var params = {}
        if (event.target.value === 'disease' || event.target.value === '') {

            pageToRedirect = '/service-alerts/disease';
            params = {
                isfiltered:false,
                risktype:'ALL',
                status:'ALL'
            }
            if(urlSearchParams.get('monthyearfilter') !== null && urlSearchParams.get('monthyearfilter') !== ''){
                params = {
                    ...params,
                    ...{monthyearfilter:urlSearchParams.get('monthyearfilter')}
                }
            }

        } else if (event.target.value === 'weather') {
            pageToRedirect = '/service-alerts/weather';
            params = {
                isfiltered:false,
                monthyearfilter:dateFilterValue,
                risktype:'ALL',
            }
            if(urlSearchParams.get('monthyearfilter') !== null && urlSearchParams.get('monthyearfilter') !== ''){
                params = {
                    ...params,
                    ...{monthyearfilter:urlSearchParams.get('monthyearfilter')}
                }
            }
        } else if (event.target.value === 'pest') {
            pageToRedirect = '/service-alerts/pest';
            params = {
                isfiltered:false,
                monthyearfilter:dateFilterValue,
                risktype:'ALL',
                status:'ALL'
            }
            if(urlSearchParams.get('monthyearfilter') !== null && urlSearchParams.get('monthyearfilter') !== ''){
                params = {
                    ...params,
                    ...{monthyearfilter:urlSearchParams.get('monthyearfilter')}
                }
            }
        }else if (event.target.value === 'advisory') {
            pageToRedirect = '/service-alerts/advisory';
            params = {
                monthyearfilter:dateFilterValue,
            }    
        }
        history(
            {
                pathname: pageToRedirect,
                search: `?${createSearchParams(params)}`,
            }
        );
        
    };

    
    return (
        <div>
            <div className='header_dropdown'>
                <div className='headerlist'>List of</div>
                <div className='Header_part'>
                    <Select
                        value={dropDown}
                        variant='standard'
                        input={<BootstrapInput />}
                        onChange={handleChange}
                        className='selectTag'>
                        <MenuItem value='disease' className={diseaseStyle}>
                            {dropDown === 'disease' ? (
                                <span>
                                    <TickIcon
                                        className='tickIcon'
                                        width='10px'
                                        height='7px'
                                        style={{ marginRight: '5px' }}
                                    />
                                    Disease Alerts
                                </span>
                            ) : (
                                'Disease Alerts'
                            )}
                        </MenuItem>
                        <Divider />
                        <MenuItem value='pest' className={pestStyle}>
                            {dropDown === 'pest' ? (
                                <span>
                                    <TickIcon
                                        className='tickIcon'
                                        width='10px'
                                        height='7px'
                                        style={{ marginRight: '5px' }}
                                    />{' '}
                                    Pest Alerts
                                </span>
                            ) : (
                                'Pest Alerts'
                            )}
                        </MenuItem>
                        <Divider />
                        <MenuItem value='weather' className={weatherStyle}>
                            {dropDown === 'weather' ? (
                                <span>
                                    <TickIcon
                                        className='tickIcon'
                                        width='10px'
                                        height='7px'
                                        style={{ marginRight: '5px' }}
                                    />
                                    Weather Alerts
                                </span>
                            ) : (
                                'Weather Alerts'
                            )}
                        </MenuItem>
                        <Divider />
                       
                        <MenuItem value='advisory' className={advisoryStyle}>
                            {dropDown === 'advisory' ? (
                                <span>
                                    <TickIcon
                                        className='tickIcon'
                                        width='10px'
                                        height='7px'
                                        style={{ marginRight: '5px' }}
                                    />
                                    Custom Advisory
                                </span>
                            ) : (
                                'Custom Advisory'
                            )}
                        </MenuItem>
                        
                    </Select>
                </div>
            </div>
        </div>
    );
};

export default ServiceAlertDropDownPage;
