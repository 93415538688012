import React, { Fragment, useState,ChangeEvent, useEffect } from 'react';
import FarmerImg from '../../assets/img/svg/farmer-page-icons/farmer.svg';
import generateRandomColor from '../../components/ui/HelperFunction';
import CropStageStepper from './cropStageStepper';
import { getRequest } from '../../services/apiUtils';
import { download } from '../../utils/helper';
import { cropName, renderSvg, svgIconName } from '../../assets/img/imageExt';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import Filter from '../../components/ui/Filter';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const CropStageWise = (selectedcrop:any) => {

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    var searchCriteria = useLocation().search;
    var urlSearchParams = new URLSearchParams(searchCriteria);

    const [cropDetails, setCropDetails] = useState<any>()
    const [selectedCropName, setSelectedCropName] = useState<string>('')
    const [todayDate, setTodayDate] = useState<any>(()=> {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const monthYear:any = month + '-' + year
        return monthYear;
    })
    const [currentYear, setCurrentYear] = useState<any>(()=> {
        const today = new Date();
        const year = today.getFullYear();
        return year;
    })
    const [minDate, setMinDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(1, 'year');
        return currentDate;
    });
    const [maxDate, setMaxDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().add(1, 'year');
        return currentDate;
    });
    const [yearValue, setYearValue] = useState<any>(() => {
        if (urlSearchParams.get('monthyearfilter')) {
            var dateArr = urlSearchParams.get('monthyearfilter').split('-');
            const dayjs = require('dayjs');
            var date = dayjs()
                .year(dateArr[1])
            return date;
        }
    });


    useEffect(()=>{
        if(selectedcrop.selectedcrop) {
            getCropDetailRecords()
            setSelectedCropName(selectedcrop.selectedcrop.cropname)
        }
    },[])

    useEffect(()=> {
        getCropDetailRecords()
    }, [yearValue])

    const getCropDetailRecords = () => {
        let obj = {
            countrycode: loggedUser?.countrycode,
            cropid: selectedcrop.selectedcrop.cropid,
            lineofbusiness: loggedUser?.lineofbusiness,
            year: currentYear
        }
        getRequest({
            url: 'cropstage/details',
            method: 'GET',
            queryParams: obj,
        })
            .then((resp:any)=>{
                if(resp?.body?.data) {
                    setCropDetails(resp.body.data)
                }else if(resp?.code && resp?.code !== '200') {
                    Alert('error', resp.statusmessage)
                    setCropDetails(null)
                }
            })
            .catch((err:any)=> {
                Alert('error', err)
            })
    }


    const renderCropImage = (name:any) => {
        if (name === 'chilli') {
            return renderSvg(cropName.chilli, 18);
        } else if (name === 'rice') {
            return renderSvg(cropName.rice, 18);
        } else if (name === 'tomato') {
            return renderSvg(cropName.tomato, 18);
        } else if (name === 'corn'){
            return renderSvg(cropName.corn, 18);
        }
    }

    const history = useNavigate();
    

    const handleMaximize = (url:any) => {
        history(`/${url}`);
    }

    const handleYearChange = (newValue: Dayjs | null) => {
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);
        const year = dayjs_date.year();
        setYearValue(newValue)
        setCurrentYear(year)
    }

    return (
        <div>
            <div>
                <div className='selectedCrop_div'>
                    <div>
                    <span className='selectedCrop_name'>
                        {selectedcrop.selectedcrop.cropname} - <span>Crop</span>
                    </span>
                    <span className='selectedCrop_year'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={yearValue}
                                    onChange={handleYearChange}
                                    minDate={ minDate}
                                    maxDate={maxDate}
                                    views={['year']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            title='Year'
                                            placeholder='yyyy'
                                            helperText={null}
                                            sx={{  
                                                borderBottom: 1, 
                                                width: 105,
                                                borderBottomColor: 'black'  
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                    </span>
                    </div>
                    <div style={{cursor:'pointer'}}>
                        <FileDownloadOutlinedIcon
                            onClick={() => {
                                download(
                                    null,
                                    false,
                                    'cropstage',
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    selectedcrop.selectedcrop.cropid,
                                    currentYear                                    
                                );
                            }}
                        />
                    </div>
                </div>
                
                {cropDetails &&
                    <div className='cropstage_cards_maindiv'>
                        <>
                        <div
                            style={{borderColor: generateRandomColor()}}
                            className='cropstage_cards_div'>
                            <div className='cards_subdiv'>
                                <span className='cards_header_title'>Famers</span>
                                <span>
                                    {renderCropImage(selectedCropName.toLowerCase())}
                                </span>
                            </div>
                            <div style={{paddingTop:'11px'}}>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_number'>
                                        { cropDetails.farmerinfo.selected}
                                    </span>
                                    <span className='cards_subdiv_count_number'>
                                        {cropDetails.farmerinfo.overall}
                                    </span>
                                </div>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_title'>Selected</span>
                                    <span className='cards_subdiv_count_title'>Overall</span>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{borderColor: generateRandomColor()}}
                            className='cropstage_cards_div'>
                            <div className='cards_subdiv'>
                                <span className='cards_header_title'>Diseases</span>
                                <span>
                                    {renderCropImage(selectedCropName.toLowerCase())}
                                </span>
                            </div>
                            <div style={{paddingTop:'11px'}}>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_number'>
                                    
                                        { cropDetails.diseaseinfo.monitored}
                                        <span 
                                            style={{paddingLeft:'2px', cursor:'pointer'}}
                                            onClick={()=>handleMaximize(`service-alerts/disease?isfiltered=false&monthyearfilter=${todayDate}&risktype=ALL&status=ALL`)}
                                        >
                                            {renderSvg(svgIconName.maximize, 8)}
                                        </span>
                                    </span>
                                    <span className='cards_subdiv_count_number'>
                                    { cropDetails.diseaseinfo.active}
                                    </span>
                                </div>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_title'>Selected</span>
                                    <span className='cards_subdiv_count_title'>Overall</span>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{borderColor: generateRandomColor()}}
                            className='cropstage_cards_div'>
                            <div className='cards_subdiv'>
                                <span className='cards_header_title'>Pests</span>
                                <span>
                                    {renderCropImage(selectedCropName.toLowerCase())}
                                </span>
                            </div>
                            <div style={{paddingTop:'11px'}}>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_number'>
                                    { cropDetails.pestinfo.monitored}
                                        <span 
                                            style={{paddingLeft:'2px', cursor:'pointer'}}
                                            onClick={()=>handleMaximize(`service-alerts/pest?isfiltered=false&monthyearfilter=${todayDate}&risktype=ALL&status=ALL`)}
                                        >
                                            {renderSvg(svgIconName.maximize, 8)}
                                        </span>
                                    </span>
                                    <span className='cards_subdiv_count_number'>
                                     
                                        { cropDetails.pestinfo.active}
                                    </span>
                                </div>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_title'>Selected</span>
                                    <span className='cards_subdiv_count_title'>Overall</span>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{borderColor: generateRandomColor()}}
                            className='cropstage_cards_div'>
                            <div className='cards_subdiv'>
                                <span className='cards_header_title'>Notification Sent</span>
                                <span>
                                    {renderCropImage(selectedCropName.toLowerCase())}
                                </span>
                            </div>
                            <div style={{paddingTop:'11px'}}>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_number'>
                                        { cropDetails.notificationinfo.active}
                                    </span>
                                    <span className='cards_subdiv_count_number'>
                                        { cropDetails.notificationinfo.overall}
                                    </span>
                                </div>
                                <div className='cards_subdiv_count'>
                                    <span className='cards_subdiv_count_title'>Selected</span>
                                    <span className='cards_subdiv_count_title'>Overall</span>
                                </div>
                            </div>
                        </div>
                        </>
                    </div>
                }
            </div>
            { cropDetails &&
                <div>
                    {cropDetails.cropstages.length > 0 && 
                        <>
                            <div className='cropstage_title'>Crop Stages</div>
                            <div>
                                <CropStageStepper 
                                    stepperobj = {cropDetails?.cropstages}
                                />
                            </div>
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default CropStageWise;