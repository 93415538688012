/** @format */

import { useEffect,useState } from 'react';
import { getRequest } from '../../services/apiUtils';
import { Alert } from '../../utils/toastr';
import ReactSelect from '../form/ReactSelect';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { CircularProgress } from '@mui/material';

interface IProps {
    filter: any;
    handleFilter: any;
    getGeoLevelData: (array: any[]) => void;
    geoLevelList: any[];
    isClearGeoLevel: boolean;
    handleLoader?: (isLoader: boolean) => void;
}
const defaultAll = { value: 'ALL', label: 'ALL' };
/**
 *GeoLevelDropdown reusable component
 * @param param0
 * @returns
 */
const GeoLevelDropdown = ({
    filter,
    handleFilter,
    getGeoLevelData,
    geoLevelList,
    isClearGeoLevel,
    handleLoader,
}: IProps) => {
    const [allGeoLevels, setAllGeoLevels] = useState<any[]>([]);
    const [firstData, setFirstData] = useState<any[]>([]);
    const [lastData, setLastData] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false)

    let lsData: any = getLocalStorageData('userData');

    let loggeduser = JSON.parse(lsData);

    const specificPersona = ['FS', 'PO'];

    //Initial rendering api call
    useEffect(() => {
        if (geoLevelList?.length > 0 && !isClearGeoLevel) {
            // update parent props
            setAllGeoLevels([...geoLevelList]);
            getGeoLevelsFirst(true);
        } else {
            //not getting parent props after call method
            getGeoLevelsFirst();
        }
    }, []);

    useEffect(() => {
        // pass data child to parent
        getGeoLevelData(allGeoLevels);
    }, [allGeoLevels]);

    /**
     * Get all three levels of geolevel
     * @param isCondUpdate (param is getting false value update initial data in first time)
     */
    const getGeoLevelsFirst = (isCondUpdate?: boolean) => {
        let obj: any = {
            countrycode: loggeduser?.countrycode,
            lob: loggeduser?.lineofbusiness,
        };

        if (loggeduser.roleid !== 'ADMIN' && loggeduser.roleid !== 'SUPER_ADMIN' ) {
            obj = {
                ...obj,
                userid: loggeduser?.userid,
                roleid: loggeduser.roleid,
            };
        }
        handleLoader?.(true)
        setLoader(true)
        getRequest({
            url: 'lookup/geolevel123',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    const data = response?.body?.data;
                    setFirstData(data);

                    let firstLevel: any = {
                        label: '',
                        options: [defaultAll],
                        selectedValue: 'ALL',
                        name: 'geolevel1code',
                    };
                    let secondLevel: any = {
                        label: '',
                        options: [defaultAll],
                        selectedValue: 'ALL',
                        name: 'geolevel2code',
                    };
                    let thirdLevel: any = {
                        label: '',
                        options: [defaultAll],
                        selectedValue: 'ALL',
                        name: 'geolevel3code',
                    };
                    let fourthLevel: any = {
                        label: 'Village',
                        options: [defaultAll],
                        selectedValue: 'ALL',
                        name: 'geolevel4code',
                    };

                    let locationhierlevel1codes: any = [];
                    let locationhierlevel2codes: any = [];
                    let locationhierlevel3codes: any = [];

                    data?.length > 0 &&
                        data.forEach((level: any) => {
                            // if (level?.locationhierlevel1code) {
                            let bool1 = locationhierlevel1codes.includes(
                                level?.locationhierlevel1code
                            );
                            if (level?.locationhierlevel1code && !bool1) {
                                let first = {
                                    value: level?.locationhierlevel1code, //01 or 02
                                    label: level?.locationhierlevel1desc, //HP or Telan
                                    title: level?.locationhierlevel1name, //state
                                };

                                firstLevel = {
                                    ...firstLevel, //label,options,selectedvalue,name
                                    label: level?.locationhierlevel1name, //state
                                    options: [
                                        ...firstLevel.options, //Now has 2 objects
                                        { ...first },
                                    ],
                                };
                                locationhierlevel1codes.push(
                                    level?.locationhierlevel1code
                                );
                            }
                            let bool2 = locationhierlevel2codes.includes(
                                level?.locationhierlevel2code
                            );
                            if (level?.locationhierlevel2code && !bool2) {
                                //dist code
                                let second = {
                                    value: level?.locationhierlevel2code, //dist code
                                    label: level?.locationhierlevel2desc, //dist name
                                    title: level?.locationhierlevel2name, //Dist
                                };
                                secondLevel = {
                                    ...secondLevel,
                                    label: level?.locationhierlevel2name, //Dist
                                    options: [
                                        ...secondLevel.options,
                                        { ...second },
                                    ],
                                };
                                locationhierlevel2codes.push(
                                    level?.locationhierlevel2code
                                );
                            }
                            let bool3 = locationhierlevel3codes.includes(
                                level?.locationhierlevel3code
                            );
                            if (level?.locationhierlevel3code && !bool3) {
                                let third = {
                                    value: level?.locationhierlevel3code,
                                    label: level?.locationhierlevel3desc,
                                    title: level?.locationhierlevel3name, //Sub_dist
                                };
                                thirdLevel = {
                                    ...thirdLevel,
                                    label: level?.locationhierlevel3name,
                                    options: [
                                        ...thirdLevel.options,
                                        { ...third },
                                    ],
                                };
                                locationhierlevel3codes.push(
                                    level?.locationhierlevel3code
                                );
                            }
                        });

                    const mergedData: any = [
                        firstLevel,
                        secondLevel,
                        thirdLevel,
                        fourthLevel,
                    ];


                    if (!isCondUpdate) {
                        const advanceAllGeoLevels = mergedData.map(
                            (level: any) => {
                                return level.options.length === 2
                                    ? {
                                          ...level,
                                          options: [level.options[1]],
                                          selectedValue: level.options[1].value,
                                      }
                                    : level;
                            }
                        );
                        // setAllGeoLevels(mergedData);
                        setAllGeoLevels(advanceAllGeoLevels);

                        let result: boolean = false;
                        for (
                            let i = 0;
                            i < advanceAllGeoLevels.length - 1;
                            i++
                        ) {
                            if (advanceAllGeoLevels[i].options.length > 1) {
                                result = true;
                                break;
                            }
                        }
                        if (!result && loggeduser?.countrycode !== 'TH') {
                            getGeoLevelLast(
                                `${advanceAllGeoLevels[0].selectedValue},${advanceAllGeoLevels[1].selectedValue},${advanceAllGeoLevels[2].selectedValue}`,
                                advanceAllGeoLevels
                            );
                        }
                    }
                    handleLoader?.(false)
                    setLoader(false)
                }
                
            })
            .catch((error: any) => {
                Alert('error', error?.message);
                handleLoader?.(false)
                setLoader(false)
            });
    };

    /**
     * Get all geo level of three and four only
     * @param level
     * @param geoLevel
     */

    const getGeoLevelLast = (level: string, geoLevel: any[]) => {
        let obj: any = {
            countrycode: loggeduser?.countrycode,
            level,
            lob: loggeduser?.lineofbusiness,
        };
        if (specificPersona.includes(loggeduser?.roleid)) {
            obj['userid'] = loggeduser.userid;
            obj['roleid'] = loggeduser.roleid;
        }
        getRequest({
            url: 'lookup/geolevel45',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    const data = response?.body?.data;
                    setLastData(data);
                    let fourthLevel: any = {
                        label: 'Village',
                        options: [defaultAll],
                        selectedValue: 'ALL',
                        name: 'geolevel4code',
                    };
                    data?.length > 0 &&
                        data.forEach((level: any) => {
                            if (level?.locationhierlevel4code) {
                                let fourth = {
                                    value: level?.locationhierlevel4code, //village code
                                    label: level?.locationhierlevel4desc, //village name
                                    title: level?.locationhierlevel4name, //village
                                };
                                fourthLevel = {
                                    ...fourthLevel,
                                    label: level?.locationhierlevel4name,
                                    options: [
                                        ...fourthLevel.options,
                                        { ...fourth },
                                    ],
                                };
                                // fourthLevel.options.length === 2 &&
                                //     (fourthLevel = {
                                //         ...fourthLevel,
                                //         options: [fourthLevel.options[1]],
                                //         selectedValue:
                                //             fourthLevel.options[1].value,
                                //     });
                                // 
                            }
                        });
                    fourthLevel.options.length === 2 &&
                        (fourthLevel = {
                            ...fourthLevel,
                            options: [fourthLevel.options[1]],
                            selectedValue: fourthLevel.options[1].value,
                        });
                    
                    const updatedData =
                        geoLevel?.length > 0
                            ? geoLevel.map((value: any) => {
                                  if (value?.name === 'geolevel4code') {
                                      return (value = {
                                          ...value,
                                          ...fourthLevel,
                                      });
                                  }
                                  return value;
                              })
                            : [];
                    setAllGeoLevels([...updatedData]);
                } else {
                }
                handleLoader?.(false)
                setLoader(false)
            })
            .catch((error: any) => {
                Alert('error', error?.message);
                handleLoader?.(false)
                setLoader(false)
            });
    };
    /**
     * Handle geolevel dropdown options list
     * @param value
     * @param name
     * @param index
     */
    const handleDynamicOptions = (value: any, name: any, index: number) => {
        const geoLevelName: any = findGeoLevelName(name);
        if (name === 'geolevel3code') {
            if (
                filter?.geolevel1code &&
                filter?.geolevel2code &&
                filter?.geolevel3code
            ) {
                if (value === 'ALL') {
                    const updatedAllList: any[] = setNextLevelAll(
                        geoLevelName.currentGeo
                    );
                    setAllGeoLevels([...updatedAllList]);
                } else {
                    const updatedData =
                        allGeoLevels?.length > 0
                            ? allGeoLevels.map((level: any) => {
                                  if (level?.name === geoLevelName.currentGeo) {
                                      //   return (level = {
                                      return {
                                          ...level,
                                          selectedValue: value,
                                      };
                                      //   });
                                  } else {
                                      return level;
                                  }
                              })
                            : [];
                    setAllGeoLevels(updatedData);
                    // const threeLevel = `${filter?.geolevel1code},${filter?.geolevel2code},${filter?.geolevel3code}`;
                    const threeLevel = `${filter?.geolevel1code},${filter?.geolevel2code},${value}`;
                    if(loggeduser?.countrycode !== 'TH'){
                        getGeoLevelLast(threeLevel, updatedData);
                    }
                    
                }
            }
        } else {
            if (value === 'ALL') {
                const updatedAllList: any[] = setNextLevelAll(
                    geoLevelName.currentGeo
                );
                setAllGeoLevels([...updatedAllList]);
            } else {
                let condLevel: any = {
                    label: '',
                    options: [defaultAll],
                    selectedValue: 'ALL',
                    name: geoLevelName.name, //'geolevel2code' i.e. nextlevelcode
                };
                let defaultALL: any[] = [];
                let codes: any = [];
                firstData?.length > 0 &&
                    firstData.forEach((level: any) => {
                        let bool = codes.includes(
                            level?.[`${geoLevelName.second}code`]
                        );
                        if (level[geoLevelName.first] === value && !bool) {
                            //locationhierlevelcode  i.e. locationhierlevel1code
                            let obj = {
                                value: level?.[`${geoLevelName.second}code`], //locationhierlevel code of dist
                                label: level?.[`${geoLevelName.second}desc`], // dist name
                                title: level?.[`${geoLevelName.second}name`], // Dist
                            };
                            condLevel = {
                                ...condLevel,
                                label: obj.title,
                                options: [...condLevel.options, { ...obj }],
                            };
                            codes.push(obj.value);
                        }
                    });
                let secondLevelIndex = 0;
                const lastIndex = allGeoLevels?.length - 1;
                const lastLevel = allGeoLevels[lastIndex];
                const updatedData =
                    allGeoLevels?.length > 0
                        ? allGeoLevels.map((level: any, i) => {
                              if (
                                  geoLevelName.name !== lastLevel.name &&
                                  level?.name === geoLevelName.name
                              ) {
                                  secondLevelIndex = i;
                                  // second level added
                                  return (level = { ...level, ...condLevel });
                              }
                              // first level
                              if (level?.name === geoLevelName.currentGeo) {
                                  return (level = {
                                      ...level,
                                      selectedValue: value,
                                  });
                              }
                              if (
                                  name !== lastLevel.name &&
                                  secondLevelIndex < i &&
                                  level.selectedValue !== 'ALL'
                              ) {
                                  let obj = {
                                      ...level,
                                      selectedValue: 'ALL',
                                      options: [defaultAll],
                                  };
                                  return (level = { ...level, ...obj });
                              }

                              return level;
                          })
                        : [];
                setAllGeoLevels(updatedData);
            }
        }
    };
    /**
     * This method is used to set default data for next level order
     * If getting value is ALL after next level of order set default value(defaultAll)
     * @param condGeoLevelName (Selected Geo Level name)
     * @returns
     */
    const setNextLevelAll = (condGeoLevelName: string) => {
        const objIndex = allGeoLevels.findIndex(
            (obj) => obj.name === condGeoLevelName
        );
        const list =
            allGeoLevels?.length > 0
                ? allGeoLevels.map((level: any, i: number) => {
                      if (objIndex === -1) {
                          return level;
                      } else if (objIndex < i) {
                          let obj = {
                              //for geol4
                              ...level,
                              selectedValue: 'ALL',
                              options: [defaultAll],
                          };
                          return (level = { ...level, ...obj });
                      } else {
                          if (condGeoLevelName === level.name) {
                              return (level = {
                                  ...level,
                                  selectedValue: 'ALL',
                              });
                          } else {
                              return level;
                          }
                      }
                  })
                : [];
        return list;
    };

    const findGeoLevelName = (value: string) => {
        switch (value) {
            case 'geolevel1code':
                return {
                    currentGeo: 'geolevel1code',
                    first: 'locationhierlevel1code',
                    second: 'locationhierlevel2',
                    name: 'geolevel2code',
                };
            case 'geolevel2code':
                return {
                    currentGeo: 'geolevel2code',
                    first: 'locationhierlevel2code',
                    second: 'locationhierlevel3',
                    name: 'geolevel3code',
                };
            case 'geolevel3code':
                return {
                    currentGeo: 'geolevel3code',
                    first: 'locationhierlevel3code',
                    second: 'locationhierlevel4',
                    name: 'geolevel4code',
                };
            case 'geolevel4code':
                return {
                    currentGeo: 'geolevel4code',
                    first: 'locationhierlevel4code',
                    second: 'locationhierlevel5',
                    name: 'geolevel5code',
                };
            case 'geolevel5code':
                return {
                    first: 'locationhierlevel5code',
                    second: 'locationhierlevel5',
                    name: 'geolevel6code',
                };
            default:
                return 'NA';
        }
    };


    const checkLabel = (label: any) => {
        let bool: boolean = false;
        for (let i = 0; i < label.length; i++) {
            if (label[i] === '_') {
                bool = true;
            }
        }
        if (bool) {
            const arr = label.split('_');

            const newArr = arr.map(
                (substr: any) =>
                    substr.charAt(0).toUpperCase() + substr.slice(1)
            );

            const correctLabel = newArr.join(' ');
            // 
            return correctLabel;
        } else {
            let correctLabel = label.charAt(0).toUpperCase() + label.slice(1);
            // 
            return correctLabel;
        }
    };


    return (
        <div className='row'>
            {loader ? 
                <div 
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </div> 
            : 
                <div className='row-dropdown'>
                    {allGeoLevels?.length > 0
                        ? allGeoLevels.map((list: any, i: number) => {
                            if(loggeduser?.countrycode !== 'TH'){
                                return (
                                    <div className='row-list' key={list.name}>
                                        <div className='title-label'>
                                            {checkLabel(list.label)}
                                        </div>
                                        <ReactSelect
                                            name={list.name}
                                            value={list.selectedValue}
                                            handleChange={
                                                (selectedOptions: any, e: any) => {
                                                    handleFilter(
                                                        selectedOptions.value,
                                                        list.name
                                                    );
                                                    handleDynamicOptions(
                                                        selectedOptions.value,
                                                        list.name,
                                                        i
                                                    );
                                                }
                                            }
                                            options={list.options}
                                            defaultValue='ALL'
                                            id='prodGroup-test'
                                            dataTestId='prodGroup-test'
                                            isDisabled={list.options.length === 1}
                                        />
                                    </div>
                                );
                            }else{
                                if(loggeduser?.countrycode === 'TH' && list.name !== 'geolevel4code'){
                                    return (
                                        <div className='row-list' key={list.name}>
                                            <div className='title-label'>
                                                {checkLabel(list.label)}
                                            </div>
                                            <ReactSelect
                                                name={list.name}
                                                value={list.selectedValue}
                                                handleChange={
                                                    (selectedOptions: any, e: any) => {
                                                        handleFilter(
                                                            selectedOptions.value,
                                                            list.name
                                                        );
                                                        handleDynamicOptions(
                                                            selectedOptions.value,
                                                            list.name,
                                                            i
                                                        );
                                                    }
                                                }
                                                options={list.options}
                                                defaultValue='ALL'
                                                id='prodGroup-test'
                                                dataTestId='prodGroup-test'
                                                isDisabled={list.options.length === 1}
                                            />
                                        </div>
                                    );
                                }
                            }
                        })
                        : null}
                </div>
            }
        </div>
    );
};

export default GeoLevelDropdown;
