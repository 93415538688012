/** @format */

import TopHeader from '../../components/layouts/TopHeader';
import Box from '@mui/material/Box';
import StepperComponent from '../countryConfig/countryConfig/Stepper';
import React, {  useState } from 'react';
import BasicInfo from './basic-info/BasicInfo';
import LocationDetails from './location-details/LocationDetails';
import LandCropInfo from './land-crop-info/LandCropInfo';
import ConsentForm from './consent-form/ConsentForm';
import { connect } from 'react-redux';
import { saveOrUpdateRequest } from '../../services/apiUtils';
import { Alert } from '../../utils/toastr';
import { useNavigate } from 'react-router-dom';
import {
    resetBasicInfo,
    resetLocationInputs,
    resetInfo,
} from '../../redux/actions/createUser/create';

const steps = [
    'Basic Information',
    'Location Details',
    'Land & Crop Info',
    'Consent Form',
];


const AddUser = (props: any) => {
    const history = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [basicInfoNextButtonClicked, setBasicInfoNextButtonClicked] =
        useState(false);

    const handleNextPage = (page: any, nextButtonClicked = false) => {
        setActiveStep(page);
        if (nextButtonClicked) {
            setBasicInfoNextButtonClicked(true);
        }
    };

    const handlePrevPage = (page: any) => {
        setActiveStep(page);
    };

    const handleSubmitConsent = () => {
        handleSendConsent();
    };

    const capitaliseFirstLetter = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const handleSendConsent = () => {
        let inputsArray: any = props.basicInfo.inputFields.map(
            (inputField: any) => ({
                id: inputField.id,
                value: inputField.value,
            })
        );

        let inputInfo = {
            'countrycode': props.locationMapping.countryCode,
            // 'farmerlabel': 'F810',
            'firstname': capitaliseFirstLetter(inputsArray[0].value.trim()),
            'lastname': capitaliseFirstLetter(inputsArray[1].value.trim()),
            'phonenumber': inputsArray[2].value,
            'email': inputsArray[3].value.trim(),
            'vendorcode': inputsArray[4].value.trim(),
            'customertype': props.basicInfo.role,
            'status': props.dataPrivacyConsent === true?'ACTIVE':'PENDING',
            'defaultlanguage': props.basicInfo.languageSelected,
            'lob': props.basicInfo.lob,
            'smssubscription': props.basicInfo.smsSubscription,
            'whatsappsubscription': props.basicInfo.whatsAppSubscription,
            'dailyforecastalert':props.basicInfo.dailyforecastalert,
            'yesterdayforecastalert':props.basicInfo.yesterdayforecastalert,
            'gender': props.basicInfo.genderSelected,
            'address': props.locationMapping.address.trim(),
            'zipcode': props.locationMapping.zipCode,
            'geolevel0code': props.locationMapping.countryCode,
            'geolevel1code': props.locationMapping.geolevelCodes[0],
            'geolevel2code': props.locationMapping.geolevelCodes[1],
            'geolevel3code': props.locationMapping.geolevelCodes[2],
            'geolevel4code': props.locationMapping.geolevelCodes[3],
            'geolevel5code':
                props.locationMapping.geolevelCodes[4] === undefined
                    ? ''
                    : props.locationMapping.geolevelCodes[4],
            'totalacerage': parseInt(props.totalAcreage),
            'dataconsentreceived': props.dataConsent,
            'privacyconsentreceived': props.dataPrivacyConsent,
            'cropsinfo': props.landcropobj.map((obj: any) => ({
                'cropid': parseInt(obj.cropid),
                'variety': obj.variety,
                'season': obj.season,
                'latitude': obj.latitude,
                'longitude': obj.longitude,
                'acerage': parseInt(obj.acreage),
                'sowingdate': obj.sowingdate,
                'previousseasonyield': parseInt(obj.previousseasonyield),
            })),
        };

        saveOrUpdateRequest({
            url: 'customer/create',
            method: 'POST',
            data: inputInfo,
        }).then((response: any) => {
            if (response.statusflag) {
                Alert(
                    'success',
                    `Farmer, ${inputInfo.firstname}  ${inputInfo.lastname}, 
                    has been successfully onboarded into Gravity application`
                );
                history('/users');
                props.resetBasicInfo();
                props.resetLocationInputs();
                props.resetlandcropinfo();
            } else {
                Alert('error', response.statusmessage);
            }
        });
    };

    return (
        <>
            <TopHeader>
                <h3>CREATE NEW USER</h3>
            </TopHeader>
            <Box>
                <StepperComponent
                    direction='horizontal'
                    currentStepNumber={activeStep}
                    steps={steps}
                    stepColor='#89D329'
                />
                <React.Fragment>
                    {activeStep === 0 && (
                        <BasicInfo handleGoToNextPage={handleNextPage} />
                    )}
                    {activeStep === 1 && (
                        <LocationDetails
                            goBackOnePage={handlePrevPage}
                            nextButtonClicked={basicInfoNextButtonClicked}
                            handleGoToNextPage={handleNextPage}
                        />
                    )}
                    {activeStep === 2 && (
                        <LandCropInfo
                            goBackOnePage={handlePrevPage}
                            handleGoToNextPage={handleNextPage}
                        />
                    )}
                    {activeStep === 3 && (
                        <ConsentForm
                            goBackOnePage={handlePrevPage}
                            handleSendConsent={handleSubmitConsent}
                        />
                    )}
                    {/* </Paper> */}
                </React.Fragment>
            </Box>
        </>
    );
};

const mapStateToProps = ({
    createUser: { landCropObj, acreage, basicInfo, locationMapping,dataPrivacyConsent,dataConsent},
}: any) => {
    return {
        basicInfo: basicInfo,
        locationMapping: locationMapping,
        landcropobj: landCropObj,
        totalAcreage: acreage,
        dataPrivacyConsent:dataPrivacyConsent,
        dataConsent:dataConsent
    };
};

const mapDispatchToProps = {
    resetBasicInfo: resetBasicInfo,
    resetLocationInputs: resetLocationInputs,
    resetlandcropinfo: resetInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
