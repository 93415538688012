/** @format */

import React from 'react';
import Modal from '../../../components/ui/Modal';
import { Farmers } from '../ServiceAlertDataTypes/ServiceAlertDataTypes';

interface ModalPopupProps {
    data?: any[];
    messageId: string;
    status: string;
    farmers: Farmers[];
    consultants: any;
    alertMessage: string;
    notificationType: string;
    comm?: string;
    prod?: any;
    handleOnClose: () => void;
    loadData: () => void;
    lookUpData: string[];
    approvers: any;
    lastaction?: any;
    rejecteddate?: any;
    approveddate?: any;
    sprayname?: any;
}

const ModalPopup: React.FC<ModalPopupProps> = ({
    messageId,
    status,
    farmers,
    consultants,
    alertMessage,
    notificationType,
    comm,
    prod,
    handleOnClose,
    loadData,
    lookUpData,
    approvers,
    lastaction,
    rejecteddate,
    approveddate,
    sprayname,
}) => {
    return (
        <div>
            <Modal
                messageId={messageId}
                status={status}
                farmers={farmers}
                consultants={consultants}
                alertMessage={alertMessage}
                notificationType={notificationType}
                comm={comm}
                prod={prod}
                handleOnClose={handleOnClose}
                loadData={loadData}
                lookUpData={lookUpData}
                approvers={approvers}
                lastaction={lastaction}
                rejecteddate={rejecteddate}
                approveddate={approveddate}
                sprayname={sprayname}
            />
        </div>
    );
};

export default ModalPopup;
