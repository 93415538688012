/** @format */

import React, { ChangeEvent, useEffect, useState } from 'react';
import TopHeader from '../../components/layouts/TopHeader';
import Filter from '../../components/ui/Filter';
import { useLocation } from 'react-router-dom';
import { getRequest } from '../../services/apiUtils';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { Table } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import './EditUserDetails';
import EditUserDetails from './EditUserDetails';
import Footer from '../../components/layouts/Footer';
import Pagination from '../../components/ui/Pagination';
import { tableParams } from '../../utils/helper';
import CreateNewUser from './createNewUser';
import { CustomButton } from '../../components/form/CustomButton';
import '../../assets/scss/filter.scss';
import { Select, MenuItem, Button } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { downloadUserManagement, downloadSuperAdmin } from '../../utils/helper';
import CreateNewSuperAdmin from './createNewSuperAdmin';
import EditSuperAdminDetails from './EditSuperAdminDetails';
import Loader from '../../components/ui/Loader';

function UserManagement() {
    let closeToggle: any;

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const styles = makeStyles((theme) =>
        createStyles({
            paper: {
                borderLeftStyle: 'solid',
                borderLeftColor: '#89D329',
                borderLeftWidth: 5,
                '&:hover': {
                    backgroundColor: 'lightgray !important',
                },
            },
            paper2: {
                borderLeftStyle: 'solid',
                borderLeftColor: '#ff9d00',
                borderLeftWidth: 5,
                '&:hover': {
                    backgroundColor: 'lightgray !important',
                },
            },
            table: {
                minWidth: 600,
                width: '99%',
                borderCollapse: 'separate',
                borderSpacing: '0 1em',
            },
            resize: {
                fontSize: 14,
                fontFamily: 'appRegular',
            },

            resizeChannel: {
                fontSize: 14,
                fontFamily: 'appRegular',
                width: '11rem',
                height: 20,
            },
            thead: {
                backgroundColor: 'lightgray',
                '& th:first-child': {
                    borderRadius: '0.5em 0 0 0.5em',
                },
                '& th:last-child': {
                    borderRadius: '0 0.5em 0.5em 0',
                },
            },
        })
    );

    const classes = styles();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#10384f',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableErrorCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'none',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderBottom: 'none',
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const StyledTableErrorRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: 'none',
        },
    }));

    const [lobArray, setLobArray] = useState<any[]>([]);
    const [statusArray, setstatusArray] = useState<any[]>([]);
    const [userTypeArray, setUserTypeArray] = useState<any[]>([]);
    const [languageArray, setlanguageArray] = useState<any[]>([]);
    const [valueMap, setValueMap] = useState<Map<any, any>>();
    const [lobRoles, setLobRoles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    const [lobMap, setLobMap] = useState<Map<any, any>>();
    const [langMap, setLangMap] = useState<Map<any, any>>();
    const [countryMap, setCountryMap] = useState<Map<any, any>>();

    const [countryAdminArray, setCountryAdminArray] = useState<any[]>([]);
    const [roleAdminArray, setRoleAdminArray] = useState<any[]>([]);

    const [errorMsgAdmin, setErrorMsgAdmin] = useState<string>('');

    var searchCriteria = useLocation().search;

    var urlSearchParams = new URLSearchParams(searchCriteria);

    const [isFiltered, setIsFiltered] = useState<boolean>(
        urlSearchParams.get('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : false
    );

    const [userDetails, setUserDetails] = useState<any[]>([]);

    const [userRoleSelected, setUserRoleSelected] = useState<any>('ALL');
    const [editUserDetailsModal, setEditUserDetailsModal] = useState(false);

    const [fetchData, setFetchData] = useState<boolean>(false);
    const [dataPerPage, setDataPerPage] = useState<any[]>([]);
    const [selectPageOne, setSelectPageOne] = useState<boolean>(true);
    const [params, setParams] = useState(tableParams());
    const [totalCount, setTotalCount] = useState<number>(0);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [isClosed, setIsClosed] = useState<any>(false);
    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(false);
    const [locationArray, setLocationArray] = useState<any[]>([]);
    const [villageArray, setVillageArray] = useState<any[]>([]);
    const [subDistrictArray, setSubDistrictArray] = useState<any[]>([]);
    const [adminCountry, setAdminCountry] = useState<any[]>([]);

    useEffect(() => {
        if (loggedUser.roleid === 'ADMIN') {
            userListData();
            userMasterData();
            getStateData();
            getVillageData();
        } else if (loggedUser.roleid === 'SUPER_ADMIN') {
            AdminListData();
            userMasterData();
            userMasterCountry();
        }
    }, []);

    useEffect(() => {}, []);

    useEffect(() => {
        if (fetchData) {
            if (loggedUser.roleid === 'ADMIN') {
                userListData();
                setFetchData(false);
            } else if (loggedUser.roleid === 'SUPER_ADMIN') {
                AdminListData();
                setFetchData(false);
            }
        }
    }, [fetchData]);

    function userMasterData() {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: loggedUser?.countrycode,
        };

        getRequest({
            url: 'lookup/master/role',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setLobArray(loggedUser?.allowedlob);
                    setstatusArray(response.body.data.status);
                    setUserTypeArray(response.body.data.usertype);
                    setlanguageArray(response.body.data.languages);
                    let tempmap = new Map<any, any>();
                    response.body.data.lob.forEach((value: any) => {
                        tempmap.set(value.lineofbusiness, value.roles);
                    });
                    setValueMap(tempmap);
                    let role = tempmap?.get(loggedUser?.lineofbusiness);
                    let allArray = [
                        { rolelabel: 'ALL', roledescription: 'ALL' },
                    ];
                    setLobRoles([...allArray, ...role]);
                    
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    }

    const getStateData = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
        };

        getRequest({
            url: 'lookup/geolevel123',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let tempLocationMap = new Map();
                    response.body.data.forEach((location: any) => {
                        tempLocationMap.set(location.locationhierlevel1code, {
                            locationCode: location.locationhierlevel1code,
                            locationDesc: location.locationhierlevel1desc,
                        });
                    });
                    let tempLocationArray: any[] = [];
                    tempLocationMap.forEach((values, keys) => {
                        
                        
                        tempLocationArray.push(values);
                    });

                    setLocationArray(tempLocationArray);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getVillageData = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: loggedUser?.countrycode,
        };

        getRequest({
            url: 'lookup/geomapping',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let tempVillageMap = new Map();
                    let tempVillageArray: any[] = [];
                    response.body.data.forEach((location: any) => {
                        tempVillageMap.set(location.locationhierlevel4code, {
                            locationCode: location.locationhierlevel4code,
                            locationDesc: location.locationhierlevel4desc,
                        });
                    });

                    let tempSubDistrictMap = new Map();
                    let tempSubDistrictArray: any[] = [];
                    response.body.data.forEach((location: any) => {
                        tempSubDistrictMap.set(
                            location.locationhierlevel3code,
                            {
                                locationCode: location.locationhierlevel3code,
                                locationDesc: location.locationhierlevel3desc,
                            }
                        );
                    });

                    tempVillageMap.forEach((values, keys) => {
                        tempVillageArray.push(values);
                    });

                    setVillageArray(tempVillageArray);

                    tempSubDistrictMap.forEach((values,keys) => {
                        tempSubDistrictArray.push(values)
                    });
                    setSubDistrictArray(tempSubDistrictArray)
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const userListData = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            search: search || null,
            isfiltered: isFiltered,
            ...params,
        };

        if (isFiltered && userRoleSelected !== 'ALL') {
            obj = {
                ...obj,
                'role': userRoleSelected,
            };
        }

        getRequest({
            url: 'user/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.data) {
                    setUserDetails(response?.body?.data?.data);
                    setTotalCount(response?.body?.data.totalcount);
                    setLoader(false);
                } else {
                    setErrorMsg('No data found for current User.');
                    setUserDetails([]);
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
                setUserDetails([]);
            });
    };

    const AdminListData = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: 'NA',
            loggeduserrole: loggedUser?.roleid,
            search: search || null,
            ...params,
        };

        getRequest({
            url: 'superadmin/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setUserDetails(response?.body?.data);
                    setAdminCountry(response?.body?.data?.assignedcountries);
                    setTotalCount(response?.body?.data.totalcount);
                    setLoader(false);
                } else {
                    setErrorMsg('No data found for current User.');
                    setUserDetails([]);
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
                setUserDetails([]);
            });
    };

    const userMasterCountry = () => {
        getRequest({
            url: 'superadmin/lookup/master',
            method: 'GET',
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    let lobArray = response.body.data.lineofbusiness;
                    let tempLobMap = new Map();
                    lobArray.forEach((lob: any) =>
                        tempLobMap.set(lob.countrycode, lob.lobs)
                    );
                    setLobMap(tempLobMap);
                    let tempLanguageMap = new Map();
                    lobArray.forEach((lang: any) =>
                        tempLanguageMap.set(lang.countrycode, lang.languages)
                    );
                    setLangMap(tempLanguageMap);
                    let tempCountryMap = new Map();
                    let CountryArray = response.body.data.countries;
                    CountryArray.forEach((countries: any) => {
                        tempCountryMap.set(
                            countries.countrycode,
                            countries.country
                        );
                    });
                    setCountryMap(tempCountryMap);
                    setCountryAdminArray(response.body.data.countries);
                    setRoleAdminArray(response.body.data.role);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const [runHandleSearch, setRunHandleSearch] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [event, setEvent] = useState<any>();
    const [selectedRole, setSelectedRole] = useState<any>('ALL');
    const [applyflag, setapplyflag] = useState<any>(false);
    const [selectedUser, setSelectedUser] = useState<any>();

    const toPagi = (currentPosts: any[], noOfPages: number) => {
        setDataPerPage(currentPosts);
    };

    const pageNoSelected = (pageNo: number, postsPerPage: number) => {
        pageNo === 1 ? setSelectPageOne(true) : setSelectPageOne(false);
        let offset = (pageNo - 1) * postsPerPage;
        setParams((current) => {
            return { ...current, offset: offset };
        });
        setFetchData(true);
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let searchtext = event.target.value;
        setSearch(searchtext);
        setRunHandleSearch(true);
    };

    const enterKeyHandler = (event: KeyboardEvent) => {
        setEvent(event);
        if (loggedUser.roleid === 'ADMIN') {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    userListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    userListData();
                }
            }
        } else if (loggedUser.roleid === 'SUPER_ADMIN') {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    AdminListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    AdminListData();
                }
            }
        }
    };

    if (runHandleSearch) {
        if (loggedUser.roleid === 'ADMIN') {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    userListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    userListData();
                }
            }

            setRunHandleSearch(false);
        } else if (loggedUser.roleid === 'SUPER_ADMIN') {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (search.length === 0 || search === '') {
                    setLoader(true);
                    AdminListData();
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (search.length >= 3) {
                    setLoader(true);
                    AdminListData();
                }
            }

            setRunHandleSearch(false);
        }
    }

    const handleRoleChange = (roleid: any) => {
        setSelectedRole(roleid);
    };

    const onCloseHandler = (node: any) => {
        closeToggle = node;
        if (!applyflag && isClosed) {
            
            setSelectedRole('ALL');
            setIsClosed(false);
        } else if (applyflag && isClosed) {
            setSelectedRole(userRoleSelected);
            setIsClosed(false);
        }
    };
    const closeFlagHandler = (bool: any) => {
        
        setIsClosed(bool);
    };
    const handleResetFilter = () => {
        setIsFiltered(false);
        setapplyflag(false);
        // setIsApplyFilter(false);
        setParams(tableParams());
        closeToggle && closeToggle();
        setSelectPageOne(true);
        setBadgeEnabled(false);
        setSelectedRole('ALL');
        setFetchData(true);
    };
    const handleApplyFilter = (event: any) => {
        event.preventDefault();
        if (selectedRole !== 'ALL') {
            setapplyflag(true);
            setParams(tableParams());
            setSelectPageOne(true);
            setIsFiltered(true);
            closeToggle && closeToggle();
            setBadgeEnabled(true);
            // setSelectedRole(selectedRole)
            setUserRoleSelected(selectedRole);
            setFetchData(true);
        } else {
            setParams(tableParams());
            setSelectPageOne(true);
            setIsFiltered(true);
            closeToggle && closeToggle();
            setFetchData(true);
            setIsFiltered(false);
        }
    };

    return (
        <div>
            <TopHeader>
                <h3>USER MANAGEMENT</h3>
            </TopHeader>
            <div>
                <div className='usermgt-filter'>
                    <Filter
                        isDownloadIcon
                        isFilterIcon={
                            loggedUser.roleid === 'ADMIN' ? true : false
                        }
                        placeHolder='Search Name (min 3 letters)'
                        searchOption1='First Name'
                        searchOption2='Last Name'
                        handleSearch={handleSearch}
                        searchText={search}
                        onKeyDown={enterKeyHandler}
                        onClose={onCloseHandler}
                        closeFlag={closeFlagHandler}
                        isBadgeEnabled={badgeEnabled}
                        handleOnClickDownload={() =>
                            loggedUser.roleid === 'ADMIN'
                                ? downloadUserManagement(
                                      search,
                                      isFiltered,
                                      'user-management',
                                      userRoleSelected
                                  )
                                : loggedUser.roleid === 'SUPER_ADMIN'
                                ? downloadSuperAdmin(search, 'SUPER_ADMIN')
                                : () => {}
                        }>
                        <div className='row' style={{ paddingBottom: '20px' }}>
                            <div className='title_label'>Roles</div>
                            <Select
                                className='usermgt_select'
                                value={selectedRole}
                                onChange={(e: any) =>
                                    handleRoleChange(e.target.value)
                                }>
                                {lobRoles &&
                                    lobRoles.map((role: any) => (
                                        <MenuItem
                                            className='usermgt_select_dropdown'
                                            value={role.rolelabel}>
                                            {role.roledescription}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                        <div className='row filterFooter'>
                            <CustomButton
                                label={'Reset'}
                                style={{
                                    width: '200px',
                                    height: '35px',
                                }}
                                className='cus-btn-filter reset'
                                handleClick={handleResetFilter}
                            />
                            <CustomButton
                                label={'Apply'}
                                style={{
                                    borderRadius: '20px',
                                    backgroundColor: '#7eb343',
                                    width: '200px',
                                    height: '35px',
                                    border: 0,
                                    boxShadow: '0px 3px 6px #c7c7c729',
                                }}
                                className='cus-btn'
                                handleClick={handleApplyFilter}
                            />
                        </div>
                    </Filter>
                </div>
                <div className='status-bar-usermgt'>
                    <div className='top-usermgt-div'></div>
                    <div className='createnew_btn'>
                        {loggedUser.roleid === 'ADMIN' ? (
                            <CreateNewUser
                                classes={classes}
                                lobArray={lobArray}
                                statusArray={statusArray}
                                userTypeArray={userTypeArray}
                                valueMap={valueMap}
                                locationArray={locationArray}
                                villageArray={villageArray}
                                userListData={userListData}
                                userMasterCountry={userMasterCountry}
                                languageArray={languageArray}
                                subDistrictArray={subDistrictArray}
                            />
                        ) : loggedUser.roleid === 'SUPER_ADMIN' ? (
                            <CreateNewSuperAdmin
                                classes={classes}
                                userTypeArray={userTypeArray}
                                languageArray={languageArray}
                                statusArray={statusArray}
                                userMasterData={userMasterData}
                                countryAdminArray={countryAdminArray}
                                lobMap={lobMap}
                                langMap={langMap}
                                countryMap={countryMap}
                                roleAdminArray={roleAdminArray}
                                AdminListData={AdminListData}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>

            <div className='usermgt-middlecontainer'>
                {loader ? <Loader /> : null}
                <Table className={classes.table} aria-label='simple table'>
                    <TableHead
                        classes={{ root: classes.thead }}
                        sx={{
                            backgroundColor: '#d6d6d6',
                            fontWeight: 900,
                            fontFamily: 'bold',
                            borderRadius: 20,
                        }}>
                        <TableRow>
                            <StyledTableCell align='left'>
                                First Name
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Last Name
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Email
                            </StyledTableCell>
                            {loggedUser.roleid === 'ADMIN' ? (
                                <StyledTableCell align='left'>
                                    Phone Num
                                </StyledTableCell>
                            ) : (
                                ''
                            )}

                            <StyledTableCell align='left'>
                                Role Name
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Status
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {userDetails.length ? (
                            userDetails.map((userDetail: any) => (
                                <StyledTableRow hover className={classes.paper}>
                                    <StyledTableCell
                                        align='left'
                                        scope='userDetail'
                                        className={
                                            userDetail.status === 'ACTIVE'
                                                ? classes.paper
                                                : classes.paper2
                                        }>
                                        {userDetail.firstname
                                            .charAt(0)
                                            .toUpperCase() +
                                            userDetail.firstname.slice(1)}
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        {userDetail.lastname
                                            .charAt(0)
                                            .toUpperCase() +
                                            userDetail.lastname.slice(1)}
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        {userDetail.email}
                                    </StyledTableCell>
                                    {loggedUser.roleid === 'ADMIN' ? (
                                        <StyledTableCell align='left'>
                                            {userDetail.phonenumber}
                                        </StyledTableCell>
                                    ) : (
                                        ''
                                    )}

                                    <StyledTableCell align='left'>
                                        {userDetail.rolename}
                                    </StyledTableCell>

                                    <StyledTableCell align='left'>
                                        {userDetail.status === 'ACTIVE' ? (
                                            <div className='active'>
                                                {userDetail.status}
                                            </div>
                                        ) : (
                                            <div className='inactive'>
                                                {userDetail.status}
                                            </div>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        <Button
                                            className='edit-button-usermgt'
                                            onClick={() => {
                                                setEditUserDetailsModal(true);
                                                setSelectedUser(userDetail);
                                                setErrorMsgAdmin('');
                                            }}>
                                            <EditNoteIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableErrorRow>
                                <StyledTableErrorCell
                                    align='left'
                                    scope='userDetail'></StyledTableErrorCell>
                                <StyledTableErrorCell align='left'></StyledTableErrorCell>
                                <StyledTableErrorCell align='left'></StyledTableErrorCell>

                                <StyledTableErrorCell align='left'>
                                    <div className='usermgt_error_msg'>
                                        {errorMsg}
                                    </div>
                                </StyledTableErrorCell>

                                <StyledTableErrorCell align='left'></StyledTableErrorCell>

                                <StyledTableErrorCell align='left'></StyledTableErrorCell>
                                <StyledTableErrorCell align='left'></StyledTableErrorCell>
                            </StyledTableErrorRow>
                        )}
                    </TableBody>
                </Table>
                {loggedUser.roleid === 'ADMIN'
                    ? selectedUser && (
                          <EditUserDetails
                              userDetail={selectedUser}
                              StyledTableRow={StyledTableRow}
                              StyledTableCell={StyledTableCell}
                              classes={classes}
                              lobArray={lobArray}
                              statusArray={statusArray}
                              userTypeArray={userTypeArray}
                              languageArray={languageArray}
                              valueMap={valueMap}
                              locationArray={locationArray}
                              villageArray={villageArray}
                              userListData={userListData}
                              editUserDetailsModal={editUserDetailsModal}
                              setEditUserDetailsModal={setEditUserDetailsModal}
                              setErrorMsgAdmin={setErrorMsgAdmin}
                              errorMsgAdmin={errorMsgAdmin}
                              subDistrictArray={subDistrictArray}
                          />
                      )
                    : loggedUser.roleid === 'SUPER_ADMIN'
                    ? selectedUser && (
                          <EditSuperAdminDetails
                              userDetail={selectedUser}
                              StyledTableRow={StyledTableRow}
                              StyledTableCell={StyledTableCell}
                              classes={classes}
                              statusArray={statusArray}
                              userTypeArray={userTypeArray}
                              roleAdminArray={roleAdminArray}
                              AdminListData={AdminListData}
                              editUserDetailsModal={editUserDetailsModal}
                              setEditUserDetailsModal={setEditUserDetailsModal}
                              countryAdminArray={countryAdminArray}
                              lobMap={lobMap}
                              countryMap={countryMap}
                              setErrorMsgAdmin={setErrorMsgAdmin}
                              errorMsgAdmin={
                                  errorMsgAdmin
                              }></EditSuperAdminDetails>
                      )
                    : ''}
            </div>
            <div className='footer_advisory'>
                {userDetails.length ? (
                    <Footer>
                        <Pagination
                            postsTitle='User(s)'
                            posts={userDetails}
                            onIndex={toPagi}
                            postsPerPage={10}
                            onIndexPageNo={pageNoSelected}
                            totalCountAvailable={totalCount}
                            selectPageOne={selectPageOne}
                        />
                    </Footer>
                ) : null}
            </div>
        </div>
    );
}

export default UserManagement;
