/** @format */

import React, { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import { illusName, renderSvg, svgIconName } from '../../assets/img/imageExt';
import TopHeader from '../../components/layouts/TopHeader';
import Filter from '../../components/ui/Filter';
import Popover from '@mui/material/Popover';
import './cropCalender.scss';
import GravityLogo from '../../assets/img/logo/app_logo.svg';
import Box from '@mui/material/Box';
import { Card, Modal, Button, Grid, Icon, Table } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { getRequest } from '../../services/apiUtils';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';
import InputLabel from '@mui/material/InputLabel';
import { CalenderIcon } from '../../utils/customSvg';
import Divider from '@mui/material/Divider';
import { anyKey, tableParams, titleCase } from '../../utils/helper';
import CropCalendarModal from './cropCalendarModal';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import cropdemoimage from '../../assets/img/jpg/cropdemoimage.jpg';
import { useSearchParams } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp';
import Stack from '@mui/material/Stack';
import { getCropCalendarDownoad } from '../../utils/helper';
import TablePagination from '@mui/material/TablePagination';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) =>
    createStyles({
        paper: {
            '&:hover': {
                backgroundColor: 'lightgray !important',
                color: 'white',
                cursor: 'pointer',
            },
        },
    })
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#10384f',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const CropCalender = () => {
    const dayjs = require('dayjs');
    const classes = styles();

    var utc = require('dayjs/plugin/utc');
    var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault('Asia/Bangkok');

    const [open, setOpen] = useState(false);
    const [filterApplied, setFilterApplied] = useState<boolean>(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(3);
    const [cropArrayLength, setCropArrayLength] = useState(0);

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const [cropArray, setCropArray] = React.useState<any[]>([]);
    const [cropData, setCropData] = React.useState<any[]>([]);

    const [modalErrMsg, setModalErrMsg] = React.useState<String>('');

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [modalFilterOpen, setModalFilterOpen] = React.useState<boolean>(
        false
    );

    const [modalSubStageOpen, setModalSubStageOpen] = React.useState<boolean>(
        false
    );
    const [modalNotification, setModalNotification] = React.useState<boolean>(
        false
    );

    const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams({});

    const [runHandleSearch, setRunHandleSearch] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [event, setEvent] = useState<any>();

    const [params, setParams] = useState(tableParams());

    const [cropNotification, setCropNotification] = React.useState<any[]>([]);

    const [seasonArray, setSeasonArray] = React.useState<any[]>([]);
    const [cropVarietyArray, setCropVarietyArray] = React.useState<any[]>([]);
    const [stateArray, setStateArray] = React.useState<any[]>([]);

    const [cropStageArray, setCropStageArray] = React.useState<any[]>([]);
    const [CropStageIDArray, setCropStageIDArray] = React.useState<any[]>([]);

    const [cropSubStageMap, setCropSubStageMap] = React.useState<
        Map<any, any>
    >();

    const [cropSubstageFilter, setCropSubstageFilter] = React.useState<any[]>(
        []
    );

    const [selectedSubStage, setSelectedSubStage] = React.useState<
        any[] | null
    >();

    const [selectedSeason, setSelectedSeason] = React.useState<any | null>(
        null
    );
    const [selectedVariety, setSelectedVariety] = React.useState<any | null>(
        null
    );

    const [selectedListSeason, setSelectedListSeason] = React.useState<
        any | null
    >(null);
    const [selectedListVariety, setSelectedListVariety] = React.useState<
        any | null
    >(null);

    const [selectedListState, setSelectedListState] = React.useState<
        any | null
    >(null);

    const [createdDate, setCreatedDate] = React.useState<any | null>(null);
    const [selectedState, setSelectedState] = React.useState<any | null>(null);
    const [selectedCrop, setSelectedCrop] = React.useState<any | null>(null);
    const [selectedCropName, setSelectedCropName] = React.useState<string>('');

    const [selectedCropStage, setSelectedCropStage] = React.useState<any>(0);
    const [notification, setNotification] = React.useState<any>([]);

    const [substageDescription, setSubStageDescription] = React.useState<any>(
        []
    );
    const [cropCalenLabel, setCropCalenLabel] = React.useState<any>([]);

    const [cropCalenID, setCropCalenID] = React.useState<any>([]);

    const handleStateChange = (event: any) => {
        setSelectedListState(event.target.value);
    };
    const handleVarietyChange = (event: any) => {
        setSelectedListVariety(event.target.value);
    };
    const handleSeasonChange = (event: any) => {
        setSelectedListSeason(event.target.value);
    };

    const handleCropClick = (cropid: any, cropname: any) => {
        setModalOpen(true);
        setSelectedListSeason(null);
        setSelectedListVariety(null);
        setSelectedListState(null);
        setSelectedCrop(cropid);
        getSelectedStageData(cropid);
        setSelectedCropName(cropname);
        setSelectedSubStage(null);
        setFilterApplied(false);
        getCropCalenderFilterList(
            cropid,
            selectedListSeason,
            selectedListVariety,
            selectedListState
        );
    };

    const handleModalCropClose = () => {
        setModalFilterOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalNotifClose = () => {
        setModalNotification(false);
        getCropCalenderFilterList(
            selectedCrop,
            selectedSeason,
            selectedVariety,
            selectedState
        );
    };

    const handleFilterReset = () => {
        setSelectedListSeason(null);
        setSelectedListVariety(null);
        setSelectedListState(null);
        getCropCalenderFilterList(selectedCrop, null, null, null);
    };

    const handleApplyFilter = () => {
        if (selectedListSeason && selectedListState) {
            setModalFilterOpen(false);
            setModalErrMsg('');
            getCropCalenderFilterList(
                selectedCrop,
                selectedListSeason,
                selectedListVariety,
                selectedListState
            );
        } else {
            setModalErrMsg('Please select both season and state');
        }
    };

    const handleListClick = (
        createddate: any,
        cropid: any,
        variety: any,
        season: any,
        geolevel1code: any,
        geolevel1: any,
        cropcalendarid: any
    ) => {
        setSelectedSeason(season);
        setSelectedVariety(variety);
        setSelectedState({
            code: geolevel1code,
            description: geolevel1,
        });
        setCreatedDate(createddate);
        setModalOpen(false);
        setFilterApplied(true);

        getFilterClickList(
            createddate,
            cropid,
            variety,
            season,
            geolevel1code,
            cropcalendarid
        );
    };

    const handleOpenNotification = (
        notifications: any,
        subStageDescription: any
    ) => {
        setNotification(notifications);
        setSubStageDescription(subStageDescription);
        setModalNotification(true);
    };

    const openSubStageHandler = (selectedCropSubStage: any) => {
        setModalSubStageOpen(true);
        setSelectedSubStage(cropSubStageMap?.get(selectedCropSubStage));
        setSelectedCropStage(selectedCropSubStage);
    };

    function getCardColor(cropStage: any) {
        if (cropStage === selectedCropStage) {
            return 'cropcalen-cardspace-selected';
        }

        return 'cropcalen-cardspace';
    }

    function getColor(crop: any) {
        if (crop === selectedCrop) {
            return 'cropcalen-crop-card-selected';
        }

        return 'cropcalen-crop-card';
    }

    useEffect(() => {
        getCropCalenderData();
        getStateData();
    }, []);

    const getFilterClickList = (
        createddate: any,
        cropid: any,
        variety: any,
        season: any,
        geolevel1code: any,
        cropcalendarid: any
    ) => {
        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            isfiltered: true,
            createddate: createddate,
        };
        if (cropid !== '') {
            obj = {
                ...obj,
                ...{ cropid: cropid },
            };
        }

        if (season !== '') {
            obj = {
                ...obj,
                ...{ season: season },
            };
        }

        if (variety !== '') {
            obj = {
                ...obj,
                ...{ variety: variety },
            };
        }

        if (geolevel1code && geolevel1code.code !== '') {
            obj = {
                ...obj,
                ...{ geolevel1code: geolevel1code.code },
            };
        }

        if (createddate !== '') {
            obj = {
                ...obj,
                ...{
                    createddate: dayjs(createddate)
                        .toISOString()
                        .substring(0, 10),
                },
            };
        }

        if (cropcalendarid !== '') {
            obj = {
                ...obj,
                ...{ cropcalendarid: cropcalendarid },
            };
        }

        getRequest({
            url: 'cropcalendar/list',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                setCropStageIDArray(response.body.data);
                let tempmap = new Map<any, any>();
                response.body.data.forEach((value: any) => {
                    tempmap.set(value.cropstagesid, value.substages);
                });
                setCropSubStageMap(tempmap);
                setCropNotification(response.body.data.notifications);
            } else {
                Alert('warning', 'Data not found');
                setCropSubStageMap(new Map());
                setCropNotification([]);
                setSelectedSubStage(null);
                setCropStageIDArray([]);
            }
        });
    };

    const getCropCalenderFilterList = (
        cropid: any,
        season: any,
        variety: any,
        geolevel1code: any
    ) => {
        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            isfiltered: true,
        };
        if (cropid !== '') {
            obj = {
                ...obj,
                ...{ cropid: cropid },
            };
        }

        if (season !== '') {
            obj = {
                ...obj,
                ...{ season: season },
            };
        }

        if (variety !== '') {
            obj = {
                ...obj,
                ...{ variety: variety },
            };
        }

        if (geolevel1code && geolevel1code.code !== '') {
            obj = {
                ...obj,
                ...{ geolevel1code: geolevel1code.code },
            };
        }

        getRequest({
            url: 'cropcalendar/list',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response.body) {
                let subStageFilterMap = new Map();
                response.body.data.forEach((value: any) => {
                    value.substages.forEach((substage: any) => {
                        let mapKey =
                            value.cropname +
                            '-' +
                            value.createddate +
                            '-' +
                            substage.variety +
                            '-' +
                            substage.season +
                            '-' +
                            substage.geolevel1;
                        subStageFilterMap.set(mapKey, {
                            cropname: value.cropname,
                            createddate: value.createddate,
                            variety: substage.variety,
                            season: substage.season,
                            geolevel1: substage.geolevel1,
                            geolevel1code: substage.geolevel1code,
                            cropid: value.cropid,
                            cropcalendarid: value.cropcalendarid,
                            cropcalendarlabel: value.cropcalendarlabel,
                        });
                    });
                });
                setCropSubstageFilter(Array.from(subStageFilterMap.values()));
            } else {
                Alert('warning', 'Data not found');
                setCropSubstageFilter([]);
            }
        });
    };

    const getCropCalenderData = () => {
        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            search: search || null,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setSeasonArray(response?.body?.data?.seasonlist);
                    const cropsList = response?.body?.data?.croplist;
                    let tempCropArray: any[] = [];
                    cropsList.forEach((crop: any) => {
                        tempCropArray.push({
                            cropid: crop.cropid,
                            cropname: crop.cropname,
                        });
                    });
                    setCropArray(tempCropArray);
                    setCropArrayLength(tempCropArray.length);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getStateData = () => {
        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
        };

        getRequest({
            url: 'lookup/geolevel123',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    let tempStateMap = new Map();
                    response.body.data.forEach((state: any) => {
                        tempStateMap.set(
                            state.locationhierlevel1code,
                            state.locationhierlevel1desc
                        );
                    });
                    let tempStateArray: any[] = [];
                    tempStateMap.forEach((key: any, value: any) => {
                        tempStateArray.push({
                            description: key,
                            code: value,
                        });
                    });

                    setStateArray(tempStateArray);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getSelectedStageData = (cropid: any) => {
        let selectedcropVariety: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
            loggeduserrole: loggedUser?.roleid,
            cropid: cropid,
        };

        if (cropid !== '') {
            selectedcropVariety = {
                ...selectedcropVariety,
                ...{ cropid: cropid },
            };
        }

        getRequest({
            url: 'cropcalendar/lookup/master',
            method: 'GET',
            queryParams: selectedcropVariety,
        })
            .then((response: any) => {
                setCropVarietyArray(response.body.data.cropvariety);
                setCropStageArray(response.body.data.cropstages);
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    function getCropImage(cropname: string) {
        if (cropname === 'CORN') {
            return renderSvg(svgIconName.corn, 20);
        }

        if (cropname === 'RICE') {
            return renderSvg(svgIconName.rice, 20);
        }

        if (cropname === 'CHILLI') {
            return renderSvg(svgIconName.chilli, 20);
        }

        if (cropname === 'TOMATO') {
            return renderSvg(svgIconName.tomato, 20);
        }
    }

    function prev() {
        if (start > 0) {
            setStart(start - 1);
            setEnd(end - 1);
        }
    }

    function next() {
        if (end < cropArrayLength - 1) {
            setStart(start + 1);
            setEnd(end + 1);
        }
    }

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let searchtext = event.target.value;
        setSearch(searchtext);
        setRunHandleSearch(true);
    };

    const enterKeyHandler = (event: KeyboardEvent) => {
        setEvent(event);
        if (event.key === 'Backspace' || event.key === 'Delete') {
            if (search.length === 0 || search === '') {
                getCropCalenderData();
            }
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (search.length >= 3) {
                getCropCalenderData();
            }
        }
    };

    if (runHandleSearch) {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            if (search.length === 0 || search === '') {
                getCropCalenderData();
            }
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (search.length >= 3) {
                getCropCalenderData();
            }
        }

        setRunHandleSearch(false);
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        lineHeight: 1,
    }));

    const handleClick = () => {
        setModalFilterOpen(true);
    };

    const handleClose = () => {
        setModalFilterOpen(false);
    };

    const openanchor = Boolean(modalFilterOpen);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className='crop_container'>
            <div>
                <TopHeader>
                    <h3>CROP CALENDAR</h3>
                </TopHeader>
                <Filter
                    isDownloadIcon={true}
                    isFilterIcon={false}
                    placeHolder='Search crop (min 3 letters)'
                    searchOption1='Crop'
                    handleSearch={handleSearch}
                    searchText={search}
                    onKeyDown={enterKeyHandler}
                    handleOnClickDownload={() => {
                        getCropCalendarDownoad(
                            createdDate
                                ? dayjs(createdDate)
                                      .toISOString()
                                      .substring(0, 10)
                                : createdDate,
                            selectedCrop,
                            selectedSeason,
                            selectedVariety,
                            selectedState,
                            search
                        );
                    }}
                />
            </div>

            <div className='cropcalender-crop-slider'>
                <div className='cropcalen-crop-slider-arrow' onClick={prev}>
                    {renderSvg(illusName.circularBackEnabled, '60px')}
                </div>
                {cropArray &&
                    cropArray
                        .filter(
                            (value: any, index: any) =>
                                index >= start && index <= end
                        )
                        .map((crop: any) => {
                            return (
                                <Card
                                    sx={{ cursor: 'pointer' }}
                                    className={getColor(crop.cropid)}
                                    onClick={() =>
                                        handleCropClick(
                                            crop.cropid,
                                            crop.cropname
                                        )
                                    }>
                                    <div className='image-cropcalen'>
                                        {getCropImage(crop.cropname)}
                                    </div>
                                    <div className='cropcalen-cropdata'>
                                        <div className='font-crop-style'>
                                            {crop.cropname}
                                        </div>
                                        <div className='font-cropcalen'>
                                            {titleCase(crop.cropname)}
                                        </div>
                                    </div>
                                </Card>
                            );
                        })}
                <div className='cropcalen-crop-slider-arrow' onClick={next}>
                    {renderSvg(illusName.circularNextEnabled, '60px')}
                </div>
            </div>
            {filterApplied && (
                <div>
                    <div className='cropcalen-filterdisplay'>
                        <div>{getCropImage(selectedCropName)}</div>
                        <Divider
                            orientation='vertical'
                            variant='middle'
                            flexItem
                        />
                        <div>
                            {selectedState !== null
                                ? selectedState.description
                                : ''}
                        </div>
                        <Divider
                            orientation='vertical'
                            variant='middle'
                            flexItem
                        />
                        <div>
                            {selectedVariety !== null ? selectedVariety : ''}
                        </div>
                        <Divider
                            orientation='vertical'
                            variant='middle'
                            flexItem
                        />
                        <div>
                            {selectedSeason !== null ? selectedSeason : ''}
                        </div>
                    </div>
                </div>
            )}

            <Modal open={modalOpen}>
                <Box className='box-model-cropcalen'>
                    <div className='crop-close-icon'>
                        <span className='cropcalen_modal_header'>
                            List View
                        </span>
                        <div className='createcropcalen-icon-closefilter'>
                            <Item
                                onClick={(e: any) => handleClick()}
                                sx={{
                                    cursor: 'pointer',
                                    width: 43,
                                    display: 'contents',
                                }}>
                                <Stack
                                    justifyContent='flex-end'
                                    alignItems='flex-end'
                                    sx={{ paddingRight: '2%' }}>
                                    <FilterListSharpIcon
                                        onClick={() => handleClose()}
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Stack>
                            </Item>
                            <div>
                                <CloseIcon
                                    className='cropcalen_modalclose_icon'
                                    onClick={handleModalClose}
                                />
                            </div>
                        </div>
                    </div>

                    <Popover
                        id={id}
                        open={openanchor}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <Modal open={modalFilterOpen}>
                            <Box className='box-model-cropcalenfilter'>
                                <div>
                                    <div className='crop-close-icon'>
                                        <span className='cropcalen_modal_header'>
                                            Please select the below filters
                                        </span>
                                        <div>
                                            <CloseIcon
                                                className='cropcalen_close_icon'
                                                onClick={handleModalCropClose}
                                            />
                                        </div>
                                    </div>
                                    <div className='cropcalen-error'>{modalErrMsg}</div>
                                    <div className='box-dropdown-cropcalen'>
                                        <FormControl
                                            size='small'
                                            sx={{ m: 1, width: 220 }}>
                                            <InputLabel className='cropcalen-label'>
                                                State
                                            </InputLabel>
                                            <Select
                                                className='box-alignnment-cropcalen'
                                                value={selectedListState}
                                                onChange={handleStateChange}>
                                                {stateArray &&
                                                    stateArray.map(
                                                        (
                                                            dropdownState: any
                                                        ) => (
                                                            <MenuItem
                                                                value={
                                                                    dropdownState
                                                                }>
                                                                {
                                                                    dropdownState.description
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            size='small'
                                            sx={{ m: 1, width: 220 }}>
                                            <InputLabel className='cropcalen-label'>
                                                Variety
                                            </InputLabel>

                                            <Select
                                                className='box-alignnment-cropcalen'
                                                value={selectedListVariety}
                                                onChange={handleVarietyChange}>
                                                {cropVarietyArray &&
                                                    cropVarietyArray.map(
                                                        (
                                                            dropdownvariety: any
                                                        ) => (
                                                            <MenuItem
                                                                value={
                                                                    dropdownvariety.variety
                                                                }>
                                                                {
                                                                    dropdownvariety.variety
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </FormControl>

                                        <FormControl
                                            size='small'
                                            sx={{ m: 1, width: 220 }}>
                                            <InputLabel className='cropcalen-label'>
                                                Season
                                            </InputLabel>

                                            <Select
                                                className='box-alignnment-cropcalen'
                                                value={selectedListSeason}
                                                onChange={handleSeasonChange}>
                                                {seasonArray &&
                                                    seasonArray.map(
                                                        (
                                                            dropdownseason: any
                                                        ) => (
                                                            <MenuItem
                                                                value={
                                                                    dropdownseason.season
                                                                }>
                                                                {
                                                                    dropdownseason.season
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='buttton_layout-cropcalen'>
                                        <div className='reset-button'>
                                            <div className='arrow-button'>
                                                <Button
                                                    className='button-style-advisory'
                                                    style={{
                                                        color: '#484848',
                                                        columnGap: 20,
                                                        textTransform: 'none',
                                                    }}
                                                    onClick={handleFilterReset}>
                                                    Reset
                                                </Button>
                                            </div>
                                        </div>
                                        <div className='create-button'>
                                            <Button onClick={handleApplyFilter}>
                                                Apply
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </Popover>

                    <Table>
                        <TableHead
                            sx={{
                                backgroundColor: '#d6d6d6',
                                fontWeight: 900,
                                fontFamily: 'bold',
                            }}>
                            <TableRow>
                                <StyledTableCell align='left'>
                                    Crop
                                </StyledTableCell>
                                <StyledTableCell align='left'>
                                    Calendar Name
                                </StyledTableCell>
                                <StyledTableCell align='left'>
                                    Created Date
                                </StyledTableCell>
                                <StyledTableCell align='left'>
                                    Season
                                </StyledTableCell>
                                <StyledTableCell align='left'>
                                    Variety
                                </StyledTableCell>
                                <StyledTableCell align='left'>
                                    State
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cropSubstageFilter &&
                                cropSubstageFilter
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((cropSubstage: any) => (
                                        <TableRow
                                            hover
                                            className={classes.paper}
                                            onClick={() =>
                                                handleListClick(
                                                    cropSubstage.createddate,
                                                    cropSubstage.cropid,
                                                    cropSubstage.variety,
                                                    cropSubstage.season,
                                                    cropSubstage.geolevel1code,
                                                    cropSubstage.geolevel1,
                                                    cropSubstage.cropcalendarid
                                                )
                                            }>
                                            <TableCell align='left'>
                                                {cropSubstage.cropname}
                                            </TableCell>
                                            <TableCell align='left'>
                                                {cropSubstage.cropcalendarlabel}
                                            </TableCell>
                                            <TableCell align='left'>
                                                {dayjs(cropSubstage.createddate)
                                                    .tz(
                                                        loggedUser?.countrycode ===
                                                            'TH'
                                                            ? 'Asia/Bangkok'
                                                            : 'Asia/Kolkata'
                                                    )
                                                    .format('DD MMM | LT')}
                                            </TableCell>
                                            <TableCell align='left'>
                                                {cropSubstage.season}
                                            </TableCell>
                                            <TableCell align='left'>
                                                {cropSubstage.variety}
                                            </TableCell>
                                            <TableCell align='left'>
                                                {cropSubstage.geolevel1}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={cropSubstageFilter.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Modal>
            {!filterApplied ? (
                <div className='cropcalen-maincard'>
                    <div className='crop-banner'>
                        <div className='crocalen-letter-style'>
                            <div>
                                <img
                                    src={GravityLogo}
                                    height='90'
                                    alt='Gravity Logo'
                                />
                            </div>
                            <div>
                                <div className='cropcalen-name'>CROP</div>
                                <div className='cropcalen-name1'>CALENDAR</div>
                            </div>
                        </div>
                        <div className='cropcalen-baseword'>
                            Please select a crop to view it’s respective stages
                        </div>
                    </div>
                </div>
            ) : (
                <div className='cropcalen-maincard1'>
                    <div className='crop-stage-timeline'>
                        <div>
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                    },
                                }}>
                                {CropStageIDArray &&
                                    CropStageIDArray.map((elem: any) => {
                                        return (
                                            <TimelineItem>
                                                <TimelineSeparator className='timeline-cropcalen'>
                                                    <TimelineConnector
                                                        sx={{
                                                            backgroundColor:
                                                                'green',
                                                        }}
                                                    />
                                                    <TimelineDot
                                                        color='primary'
                                                        variant='outlined'>
                                                        <Icon>
                                                            {getCropImage(
                                                                selectedCropName
                                                            )}
                                                        </Icon>
                                                    </TimelineDot>
                                                    <TimelineConnector
                                                        sx={{
                                                            backgroundColor:
                                                                'green',
                                                        }}
                                                    />
                                                </TimelineSeparator>
                                                <TimelineContent className='timelineitem'>
                                                    <Card
                                                        sx={{
                                                            cursor: 'pointer',
                                                        }}
                                                        className={getCardColor(
                                                            elem.cropstagesid
                                                        )}
                                                        onClick={() =>
                                                            openSubStageHandler(
                                                                elem.cropstagesid
                                                            )
                                                        }>
                                                        <div className='cropcalen-stagefont'>
                                                            {
                                                                elem.cropstagedescription
                                                            }
                                                        </div>
                                                        <Divider></Divider>
                                                        <div className='cropcalen-data'>
                                                            <CalenderIcon
                                                                fillColor={
                                                                    ''
                                                                }></CalenderIcon>
                                                            <div>
                                                                {elem.startdate}{' '}
                                                                days
                                                            </div>
                                                            to
                                                            <div>
                                                                {elem.enddate}{' '}
                                                                days
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </TimelineContent>
                                            </TimelineItem>
                                        );
                                    })}
                            </Timeline>
                        </div>
                    </div>

                    <div className='crop-substage-display'>
                        {selectedSubStage ? (
                            <div>
                                <div className='cropcalen-sub-head'>
                                    Sub-Stage(s)
                                </div>

                                <Grid
                                    container
                                    spacing={{ xs: 1, md: 2 }}
                                    columns={{
                                        xs: 3,
                                        sm: 3,
                                        md: 9,
                                        lg: 12,
                                        xl: 15,
                                    }}>
                                    {selectedSubStage.map(
                                        (value: any, index: any) => (
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                lg={4}
                                                xl={5}
                                                key={index}>
                                                <Card
                                                    sx={{ cursor: 'pointer' }}
                                                    className='cropcalen-substage-card'
                                                    onClick={() =>
                                                        handleOpenNotification(
                                                            value.notifications,
                                                            value.cropsubstagedescription
                                                        )
                                                    }>
                                                    <div className='cropcalen-substagedescription'>
                                                        {
                                                            value.cropsubstageslabel
                                                        }
                                                    </div>
                                                    <img
                                                        src={cropdemoimage}
                                                        alt='Gravity Logo'
                                                        className='demoimage'
                                                    />
                                                    <div className='cropcalen-substagedate'>
                                                        <CalenderIcon
                                                            fillColor={
                                                                ''
                                                            }></CalenderIcon>
                                                        <div className='cropcalen-substagefromdate'>
                                                            {value.startdate}{' '}
                                                            days
                                                        </div>
                                                        to
                                                        <div className='cropcalen-substagetodate'>
                                                            {value.enddate} days
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </div>
                        ) : (
                            <div>
                                <div className='crocalen-letter-style'>
                                    <div>
                                        <img
                                            src={GravityLogo}
                                            height='90'
                                            alt='Gravity Logo'
                                        />
                                    </div>
                                    <div>
                                        <div className='cropcalen-name'>
                                            CROP
                                        </div>
                                        <div className='cropcalen-name1'>
                                            CALENDAR
                                        </div>
                                    </div>
                                </div>
                                <div className='cropcalen-baseword'>
                                    Please select a crop to view it’s respective
                                    stages
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <Modal open={modalNotification}>
                <Box className='box-model-cropcalen'>
                    <div>
                        <div className='notif-icon '>
                            <div className='cropcalen-notif-head'>
                                Sub Stage Description
                            </div>
                            <CloseIcon onClick={handleModalNotifClose} />
                        </div>
                        <div className='description-cropcalen'>
                            {substageDescription}
                        </div>
                    </div>
                    <div className='cropcalen-notif-head'>Notification</div>

                    {notification.map((not: any, index: any) => (
                        <CropCalendarModal
                            not={not}
                            description={substageDescription}
                            setNotification={setNotification}
                            notificationArray={notification}
                        />
                    ))}
                </Box>
            </Modal>
        </div>
    );
};

export default CropCalender;
