/** @format */

import React, { Fragment, useState, ChangeEvent, useEffect } from 'react';
import { CustomButton } from '../../components/form/CustomButton';
import TopHeader from '../../components/layouts/TopHeader';
import Filter from '../../components/ui/Filter';
import GeoLevelDropdown from '../../components/ui/GeoLevelDropdown';
import Loader from '../../components/ui/Loader';
import {
    anyKey,
    assignedNull,
    download,
    tableParams,
} from '../../utils/helper';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import '../User/List/index.scss';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { CardContent, Grid, Stack } from '@mui/material';
import FarmerImg from '../../assets/img/svg/farmer-page-icons/farmer.svg';
import LeftArrow from '../../assets/img/svg/farmer-page-icons/left_arrow.svg';
import './cropStageWise.scss';
import CropStageWise from './cropStageWise';
import { getRequest } from '../../services/apiUtils';
import { cropName, renderSvg } from '../../assets/img/imageExt';
import { Alert } from '../../utils/toastr';
import { useSearchParams } from 'react-router-dom';
import '../User/List/index.scss';

const CropWiseStage = () => {
    const StyledBox = styled(Box)(({ theme }) => ({
        overflowY: 'auto',
        [theme.breakpoints.up('xs')]: {
            height: '63vh',
        },
        [theme.breakpoints.up('sm')]: {
            height: '74vh',
        },
        [theme.breakpoints.up('md')]: {
            height: '78vh',
        },
        [theme.breakpoints.up('lg')]: {
            height: '64vh',
        },
        [theme.breakpoints.up('xl')]: {
            height: '85vh',
        },
    }));

    const Item = styled(Card)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        // maxWidth: 350,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 3px 6px #00000029',
        border: '0.30000001192092896px solid #C9C9C9',
        borderRadius: 5,
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }));

    const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
        padding: theme.spacing(1),
        '& .MuiCardHeader-title': {
            color: '#000000',
            fontFamily: 'AppMedium',
            fontSize: '15px !important',
        },
        '& .MuiCardHeader-subheader': {
            color: '#0F0F0F',
            fontFamily: 'AppRegular',
            fontSize: '13px !important',
        },
        '& .MuiTypography-root': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.2rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.1rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.85rem',
            },
        },
    }));

    let closeToggle: any;
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    const [isShowStages, setIsShowStages] = useState<any>(false);
    const [runHandleSearch, setRunHandleSearch] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<any>({
        geolevel0code: loggedUser?.countrycode,
        geolevel1code: 'ALL',
        geolevel2code: 'ALL',
        geolevel3code: 'ALL',
        geolevel4code: 'ALL',
        geolevel5code: null,
    });
    const [geoLevelList, setGeoLevelList] = useState<any>([]);
    const [isClearGeoLevel, setIsClearGeoLevel] = useState<boolean>(false);
    const [applyflag, setapplyflag] = useState<any>(false);
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [params, setParams] = useState(tableParams());
    const [geolevel, setgeolevel] = useState<any>({ ...filter });
    const [currOptions, setcurrOptions] = useState<any>([]);
    const [isClosed, setIsClosed] = useState<any>(false);
    const [cropStageData, setCropStageData] = useState<any>([]);
    const [selectedCrop, setSelectedCrop] = useState<any>();
    const [loader, setLoader] = useState<boolean>(false);
    const [event, setEvent] = useState<any>();
    const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams({});
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];
    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(false);
    const [geoLoader, setGeoLoader] = useState<boolean>(true)

    const arr = [1, 2, 3, 4];

    useEffect(() => {
        getCropStageData();
    }, []);

    useEffect(() => {
        getCropStageData();
    }, [params]);

    useEffect(() => {
        let selectedFilter: any = { ...filter };
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,
                    [level.name]: level.selectedValue,
                };
            });

            setFilter({ ...selectedFilter });
        }
    }, [geoLevelList]);

    const getCropStageData = () => {

        setLoader(true);
        let obj: any = {
            ...params,
            countrycode: loggedUser?.countrycode,
            search: search || null,
            isfiltered: true,
        };

        if (
            specificPersona.includes(loggedUser.roleid) &&
            loggedUser.assignedloc.length > 0
        ) {
            let location = loggedUser.assignedloc.map(
                (loc: any) => loc.lochiergeocode
            );
            obj = {
                ...obj,
                ['isfiltered']: true,
                [obj.countrycode === 'TH' ? 'geolevel3code' : 'geolevel4code']:
                    location.join(','),
            };
        }

        if (isFiltered) {
            // if (
            //     specificPersona.includes(loggedUser.roleid) &&
            //     loggedUser.assignedloc.length > 0
            // ) {
            //     let location = loggedUser.assignedloc.map(
            //         (loc: any) => loc.lochiergeocode
            //     );
            //     filter.geolevel4code = location.join(',');
            // }
            const condFilter = assignedNull(filter);
            obj = { ...obj, ...condFilter };
        }


        getRequest({
            url: 'cropstage/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setCropStageData(response.body.data);
                    setLoader(false);
                } else {
                    setCropStageData([]);
                    setLoader(false);
                }
            })
            .catch((err: any) => {
                Alert('error', err);
            });
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let searchtext = event.target.value;
        setSearch(searchtext);
        setRunHandleSearch(true);
    };

    const handleFilter = (value: any, name: string) => {
        setFilter({ ...filter, [name]: value });
    };
    const handleGetGeoLevelData = (array: any[]) => {
        setGeoLevelList([...array]);
    };

    const handleResetFilter = () => {
        setIsClearGeoLevel(true);
        setIsFiltered(false);
        setapplyflag(false);
        setIsApplyFilter(false);
        setBadgeEnabled(false);
        setParams(tableParams());
        setFilter({
            geolevel0code: loggedUser?.countrycode,
            geolevel1code: 'ALL',
            geolevel2code: 'ALL',
            geolevel3code: 'ALL',
            geolevel4code: 'ALL',
            geolevel5code: null,
        });
        closeToggle && closeToggle();
    };
    const handleApplyFilter = (event: any) => {
        event.preventDefault();
        setapplyflag(true);
        setParams(tableParams());
        setIsClearGeoLevel(false);
        setIsFiltered(true);
        setIsApplyFilter(true);
        setBadgeEnabled(true);
        closeToggle && closeToggle();
        let selectedFilter: any = { ...filter };
        let temparr: any = [];
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,

                    [level.name]: level.selectedValue,
                };
                temparr = [...temparr, level.options];
            });
            setcurrOptions([...temparr]);
            setgeolevel({ ...selectedFilter });
        }
    };

    const onCloseHandler = (node: any) => {
        closeToggle = node;
        if (!applyflag && isClosed) {
            setIsClearGeoLevel(true);
            setFilter({
                ...filter,
            });
            setIsClosed(false);
            setGeoLoader(true)
        } else if (applyflag && isClosed) {
            setFilter({
                geolevel0code: loggedUser?.countrycode,
                geolevel1code: geolevel.geolevel1code,
                geolevel2code: geolevel.geolevel2code,
                geolevel3code: geolevel.geolevel3code,
                geolevel4code: geolevel.geolevel4code,
                geolevel5code: null,
            });
            geoLevelList.map((geo: any) => {
                if (geo.name === 'geolevel1code') {
                    geo.selectedValue = geolevel.geolevel1code;
                    geo.options = currOptions[0];
                }
                if (geo.name === 'geolevel2code') {
                    geo.selectedValue = geolevel.geolevel2code;
                    geo.options = currOptions[1];
                }
                if (geo.name === 'geolevel3code') {
                    geo.selectedValue = geolevel.geolevel3code;
                    geo.options = currOptions[2];
                }
                if (geo.name === 'geolevel4code') {
                    geo.selectedValue = geolevel.geolevel4code;
                    geo.options = currOptions[3];
                }
            });
            setIsClosed(false);
            setGeoLoader(true)
        }
    };

    const closeFlagHandler = (bool: any) => {
        setIsClosed(bool);
    };

    const handleCardClick = (crop: any) => {
        setSelectedCrop(crop);
        setIsShowStages(true);
    };

    const renderCropImage = (name: any) => {
        if (name === 'chilli') {
            return renderSvg(cropName.chilli, 20);
        } else if (name === 'rice') {
            return renderSvg(cropName.rice, 20);
        } else if (name === 'tomato') {
            return renderSvg(cropName.tomato, 20);
        } else if (name === 'corn') {
            return renderSvg(cropName.corn, 20);
        }
    };

    const keyHandler = (event: any) => {
        setEvent(event);
        anyKey(
            event,
            search,
            setIsApplyFilter,
            setSearchParams,
            searchParams,
            setParams
        );
    };

    if (runHandleSearch) {
        anyKey(
            event,
            search,
            setIsApplyFilter,
            setSearchParams,
            searchParams,
            setParams
        );
        setRunHandleSearch(false);
    }

    return (
        <Fragment>
            <TopHeader>
                {!isShowStages ? (
                    <h3>CROP STAGE WISE ADVISORY</h3>
                ) : (
                    <div className='farmer-details'>
                        <img
                            src={LeftArrow}
                            onClick={() => {
                                setIsShowStages(false);
                            }}
                            alt='Left arrow'
                        />
                        <h3>CROP STAGE WISE ADVISORY</h3>
                    </div>
                )}
            </TopHeader>
            <div className='middle-container'>
                {loader ? <Loader /> : null}
                {!isShowStages && (
                    <Filter
                        isDownloadIcon ={false}
                        isFilterIcon
                        placeHolder='Search a Crop'
                        searchOption1='Crop Name'
                        handleSearch={handleSearch}
                        searchText={search}
                        customHeight={450}
                        onClose={onCloseHandler}
                        closeFlag={closeFlagHandler}
                        onKeyDown={keyHandler}
                        isBadgeEnabled={badgeEnabled}
                        handleOnClickDownload={() => {
                            download(
                                search,
                                undefined,
                                'cropstage',
                                undefined,
                                'Crop'
                            );
                        }}>
                        <div>
                            <GeoLevelDropdown
                                filter={filter}
                                handleFilter={handleFilter}
                                getGeoLevelData={handleGetGeoLevelData}
                                geoLevelList={geoLevelList}
                                isClearGeoLevel={isClearGeoLevel}
                                handleLoader={(isLoader:boolean)=>setGeoLoader(isLoader)}
                            />
                        </div>
                        {!geoLoader && 
                            <div className='row filterFooter'>
                                <CustomButton
                                    label={'Reset'}
                                    style={{
                                        width: '200px',
                                        height: '35px',
                                    }}
                                    className='cus-btn-filter reset'
                                    handleClick={handleResetFilter}
                                />
                                <CustomButton
                                    label={'Apply'}
                                    style={{
                                        borderRadius: '20px',
                                        backgroundColor: '#7eb343',
                                        width: '200px',
                                        height: '35px',
                                        border: 0,
                                        boxShadow: '0px 3px 6px #c7c7c729',
                                    }}
                                    className='cus-btn'
                                    handleClick={handleApplyFilter}
                                />
                            </div>
                        }
                    </Filter>
                )}
                {isShowStages && <CropStageWise selectedcrop={selectedCrop} />}
                {!isShowStages &&
                    (cropStageData.length > 0 ? (
                        <Fragment>
                            <StyledBox sx={{ flexGrow: 1, mt: 1 }}>
                                <Grid
                                    container
                                    spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
                                    columns={{
                                        xs: 2,
                                        sm: 8,
                                        md: 8,
                                        lg: 12,
                                        xl: 12,
                                    }}>
                                    {cropStageData &&
                                        cropStageData.map((stage: any) => (
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={3}
                                                // key={index}
                                            >
                                                <Item
                                                    onClick={() =>
                                                        handleCardClick(stage)
                                                    }>
                                                    <StyledCardHeader
                                                        avatar={renderCropImage(
                                                            stage.cropname.toLowerCase()
                                                        )}
                                                        action={
                                                            <label>
                                                                <span className='cropstage_count_header'>
                                                                    Stages:
                                                                    <span className='cropstage_count'>
                                                                        {
                                                                            stage.stages
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        }
                                                        title={stage.cropname}
                                                        subheader={
                                                            stage.cropname
                                                        }
                                                    />
                                                    <CardContent>
                                                        <Stack
                                                            direction='row'
                                                            spacing={2}
                                                            alignItems='baseline'
                                                            mb={1}
                                                            display='flex'
                                                            justifyContent='space-between'>
                                                            <span className='cropstage_count_header'>
                                                                IoT Devices :
                                                                <span className='cropstage_count'>
                                                                    {
                                                                        stage.iotdevices
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className='cropstage_count_header'>
                                                                Focus Area :
                                                                <span className='cropstage_count'>
                                                                    {loggedUser.countrycode === 'TH'? `${stage.focusarea} Province(s)`: `${stage.focusarea} State(s)` }
                                                                </span>
                                                            </span>
                                                        </Stack>
                                                        <Stack
                                                            direction='row'
                                                            spacing={2}
                                                            alignItems='baseline'
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            paddingTop={1}>
                                                            <span className='cropstage_count_header'>
                                                                Monitored
                                                                Diseases :
                                                                <span className='cropstage_count'>
                                                                    {
                                                                        stage.monitoreddiseases
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className='cropstage_count_header'>
                                                                Monitored Pests
                                                                :
                                                                <span className='cropstage_count'>
                                                                    {
                                                                        stage.monitoredpests
                                                                    }
                                                                </span>
                                                            </span>
                                                        </Stack>
                                                    </CardContent>
                                                </Item>
                                            </Grid>
                                        ))}
                                </Grid>
                            </StyledBox>
                        </Fragment>
                    ) : (
                        <div className='cropstage_error_msg'>
                            No records found!
                        </div>
                    ))}
            </div>
        </Fragment>
    );
};

export default CropWiseStage;
