/** @format */

import { MenuItem, Select, styled, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import ServiceAlertHeader from '../../service-alerts/service-alerts-header/index';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import { CustomButton } from '../../../components/form/CustomButton';
import GeoLevelDropdown from '../../../components/ui/GeoLevelDropdown';
import { getGeoLevelsFirst } from '../../service-alerts/service-alert-utils/Utils';
import {
    assignedNull,
    downloadAdvisoryPage,
    optionList,
    tableParams,
} from '../../../utils/helper';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { getRequest } from '../../../services/apiUtils';
import Footer from '../../../components/layouts/Footer';
import Pagination from '../../../components/ui/Pagination';
import {
    createSearchParams,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import { Alert } from '../../../utils/toastr';
import CustomAdvisoryModal from './CustomAdvisoryModal';
import FromDateToDate from '../service-alerts-header/fromDateToDate';
import Loader from '../../../components/ui/Loader';

const CustomAdvisory = () => {
    const dayjs = require('dayjs');
    let closeToggle: any;

    const queryParamsFromURL = useLocation().search;
    let urlSearchParams = new URLSearchParams(queryParamsFromURL);
    let [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();

    interface ChipProps extends MuiChipProps {
        status?: string;
        activefilter?: string;
    }

    interface FilterTypes {
        alerttype: any;
        cropname: any;
        geolevel0code: any;
        geolevel1code: any;
        geolevel2code: any;
        geolevel3code: any;
        geolevel4code: any;
        geolevel5code: any;
        fromdate: any;
        todate: any;
    }

    const condStatus: any = {
        'ACTIVE': {
            color: '#89D329',
            border: '1px solid #E3F1D0',
            backgroundColor: '#E3F1D0',
        },
        'INACTIVE': {
            color: '#ff9d00',
            border: '1px solid #ff9d00',
            backgroundColor: '#ffebcc',
        },
    };

    const Chip = styled(MuiChip)<ChipProps>(
        ({ status, activefilter, theme }) => ({
            ...(status && {
                ...condStatus[status],
            }),
            ...(activefilter === 'true' && {
                backgroundColor: '#10384F',
                color: '#ffffff',
                '&:hover': {
                    backgroundColor: '#10384F !important',
                    color: '#ffffff',
                },
            }),
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.1rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.75rem',
            },
        })
    );

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const [valueDate, setValueDate] = useState<Dayjs | null>(() => {
        if (urlSearchParams.get('monthyearfilter')) {
            var dateArr = urlSearchParams.get('monthyearfilter').split('-');
            const dayjs = require('dayjs');
            const month: number = +dateArr[0];
            var date = dayjs()
                .year(dateArr[1])
                .month(month - 1);
            return date;
        }
    });

    const [dataPerPage, setDataPerPage] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [geoLoader, setGeoLoader] = useState<boolean>(true)
    const [data, setData] = useState<any[]>([]);
    const [params, setParams] = useState(tableParams());
    const [errorMsg, setErrorMsg] = useState<string>();
    const [selectPageOne, setSelectPageOne] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<any>(
        urlSearchParams.get('search') !== '' &&
            urlSearchParams.get('search') !== null
            ? urlSearchParams.get('search')
            : ''
    );
    const showPersona = ['TPL', 'TM_SAM', 'ADMIN','SUPER_ADMIN','TM_PL'];

    const [isClearGeoLevel, setIsClearGeoLevel] = useState<boolean>(false);
    const [currOptions, setcurrOptions] = useState<any>([]);

    const [geoLevelList, setGeoLevelList] = useState<any>([]);

    const [makeAPICall, setMakeAPICall] = useState<boolean>(false);
    const [filter, setFilter] = useState<FilterTypes>({
        alerttype: urlSearchParams.get('alerttype')
            ? urlSearchParams.get('alerttype')
            : 'ALL',
        cropname: urlSearchParams.get('cropname')
            ? urlSearchParams.get('cropname')
            : 'ALL',
        fromdate: null,
        todate: null,
        geolevel0code: loggedUser?.countrycode,
        geolevel1code: urlSearchParams.get('geolevel1code')
            ? urlSearchParams.get('geolevel1code')
            : 'ALL',
        geolevel2code: urlSearchParams.get('geolevel2code')
            ? urlSearchParams.get('geolevel2code')
            : 'ALL',
        geolevel3code: urlSearchParams.get('geolevel3code')
            ? urlSearchParams.get('geolevel3code')
            : 'ALL',
        geolevel4code: urlSearchParams.get('geolevel4code')
            ? urlSearchParams.get('geolevel4code')
            : 'ALL',
        geolevel5code: null,
    });

    const [geolevel, setgeolevel] = useState<any>({ ...filter });

    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];

    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(
        urlSearchParams.get('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : false
    );

    const [loader, setLoader] = useState(true);
    const [isClosed, setIsClosed] = useState<any>(false);

    const [isFiltered, setIsFiltered] = useState<boolean>(
        urlSearchParams.get('isfiltered')
            ? () => {
                  if (urlSearchParams.get('isfiltered') === 'true') {
                      return true;
                  } else {
                      return false;
                  }
              }
            : false
    );

    const handleResetFilter = () => {
        setIsClearGeoLevel(true);
        setFirstPageLoad(true);
        setFilter({
            alerttype: 'ALL',
            cropname: 'ALL',
            fromdate: '',
            todate: '',
            geolevel0code: loggedUser?.countrycode,
            geolevel1code: 'ALL',
            geolevel2code: 'ALL',
            geolevel3code: 'ALL',
            geolevel4code: 'ALL',
            geolevel5code: null,
        });
        setIsFiltered(false);
        setMakeAPICall(true);
        setBadgeEnabled(false);
        urlSearchParams.delete('cropname');
        urlSearchParams.delete('isfiltered');
        urlSearchParams.delete('geolevel0code');
        urlSearchParams.delete('geolevel1code');
        urlSearchParams.delete('geolevel2code');
        urlSearchParams.delete('geolevel3code');
        urlSearchParams.delete('geolevel4code');
        setParams(tableParams());
        setParamsBackToUrl(createSearchParams(urlSearchParams));
        closeToggle && closeToggle();
    };

    const handleApplyFilter = () => {
        setIsClearGeoLevel(false);
        setIsFiltered(true);
        setBadgeEnabled(true);
        
        urlSearchParams.set('cropname', filter.cropname);
        urlSearchParams.set('isfiltered', 'true');
        urlSearchParams.set('geolevel0code', filter.geolevel0code);
        urlSearchParams.set('geolevel1code', filter.geolevel1code);
        urlSearchParams.set('geolevel2code', filter.geolevel2code);
        urlSearchParams.set('geolevel3code', filter.geolevel3code);
        urlSearchParams.set('geolevel4code', filter.geolevel4code);
        setParams(tableParams());
        setParamsBackToUrl(createSearchParams(urlSearchParams));
        setMakeAPICall(true);
        let selectedFilter: any = { ...filter };
        let temparr: any = [];
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,

                    [level.name]: level.selectedValue,
                };
                temparr = [...temparr, level.options];
            });
            setcurrOptions([...temparr]);
            setgeolevel({ ...selectedFilter });
        }
        closeToggle && closeToggle();
    };

    const closeFlagHandler = (bool: any) => {
        setIsClosed(bool);
    };

    const onCloseHandler = (node: any) => {
        closeToggle = node;
        
        if (!isFiltered && isClosed) {
            setIsClearGeoLevel(true);
            setFilter({
                alerttype: 'ALL',
                cropname: 'ALL',
                fromdate: '',
                todate: '',
                geolevel0code: loggedUser?.countrycode,
                geolevel1code: 'ALL',
                geolevel2code: 'ALL',
                geolevel3code: 'ALL',
                geolevel4code: 'ALL',
                geolevel5code: null,
            });
            setIsClosed(false);
            setGeoLoader(true)
        } else if (isFiltered && isClosed) {
            setFilter({
                alerttype: filter.alerttype,
                cropname: filter.cropname,
                fromdate: filter.fromdate,
                todate: filter.todate,
                geolevel0code: loggedUser?.countrycode,
                geolevel1code: geolevel.geolevel1code,
                geolevel2code: geolevel.geolevel2code,
                geolevel3code: geolevel.geolevel3code,
                geolevel4code: geolevel.geolevel4code,
                geolevel5code: null,
            });

            geoLevelList.forEach((geo: any) => {
                if (geo.name === 'geolevel1code') {
                    geo.selectedValue = geolevel.geolevel1code;
                    geo.options = currOptions[0];
                }
                if (geo.name === 'geolevel2code') {
                    geo.selectedValue = geolevel.geolevel2code;
                    geo.options = currOptions[1];
                }
                if (geo.name === 'geolevel3code') {
                    geo.selectedValue = geolevel.geolevel3code;
                    geo.options = currOptions[2];
                }
                if (geo.name === 'geolevel4code') {
                    geo.selectedValue = geolevel.geolevel4code;
                    geo.options = currOptions[3];
                }
            });
            setGeoLoader(true)
            setIsClosed(false);
        }
    };

    const handleFilter = (value: any, name: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const [cropListLookup, setCropListLookup] = useState<readonly ChipData[]>([
        { value: 'ALL', label: 'ALL' },
    ]);
    const [alerttypeLookup, setAlerttypeLookup] = useState<any[]>([
        { value: 'ALL', label: 'ALL' },
        // { value: 'DISEASE', label: 'DISEASE' },
        // { value: 'PEST', label: 'PEST' },
        // { value: 'RAINFALL', label: 'RAINFALL' },
        // { value: 'OTHERS', label: 'OTHERS' },
    ]);

    const [firstPageLoad, setFirstPageLoad] = useState<boolean>(true);

    const [dateFilter, setDateFilter] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs(valueDate);

        const month = currentDate.month() + 1;
        const year = currentDate.year();
        const dateFilterValue = month + '-' + year;

        return dateFilterValue;
    });

    const [runSearchForCustomAdvisory, setRunSearchForCustomAdvisory] =
        useState<boolean>(
            urlSearchParams.get('search') !== null ||
                urlSearchParams.get('search') !== ''
                ? true
                : false
        );

    const [searchFeildKeyboardEvent, setSearchFeildKeyboardEvent] =
        useState<any>(new Event('Enter'));

    const minDate = dayjs().subtract(2, 'year').toDate();

    const maxDate = dayjs();

    useEffect(() => {
        loadCustomAdvisoryData();
        cropFilterLookup();
        setFirstPageLoad(false);
        if (
            urlSearchParams.get('geolevel0code') ||
            urlSearchParams.get('geolevel1code') ||
            urlSearchParams.get('geolevel2code') ||
            urlSearchParams.get('geolevel3code') ||
            urlSearchParams.get('geolevel4code')
        ) {
            getGeoLevelsFirst(setGeoLevelList, urlSearchParams, setcurrOptions);
        }
    }, []);

    useEffect(() => {
        if (makeAPICall) {
            loadCustomAdvisoryData();
            setMakeAPICall(false);
        }
    }, [makeAPICall]);

    useEffect(() => {
        let selectedFilter: any = { ...filter };

        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,

                    [level.name]: level.selectedValue,
                };
            });

            setFilter({ ...selectedFilter });
        }
    }, [geoLevelList]);

    const handleCalendarChange = (newValue: Dayjs | null) => {
        const dayjs_date = dayjs(newValue);
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = month + '-' + year;

        if (!isNaN(month) && !isNaN(year)) {
            setDateFilter(dateFilterValue);
            setValueDate(newValue);
        } else {
            setValueDate(null);
            setDateFilter(null);
        }

        urlSearchParams.set('monthyearfilter', dateFilterValue);
        setParamsBackToUrl(createSearchParams(urlSearchParams));

        setMakeAPICall(true);
    };

    const cropFilterLookup = () => {
        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob: loggedUser?.lineofbusiness,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                // const cropsList = response?.body?.data?.croplist;
                // setCropListLookup(cropsList);
                const cropDataOriginal = response?.body?.data?.croplist;
                if (cropDataOriginal?.length > 0) {
                    const filterOption = optionList(
                        cropDataOriginal,
                        'cropcode',
                        'cropname'
                    );
                    setCropListLookup([...cropListLookup, ...filterOption]);
                    // setLookUpData(response.body.data.products);
                } else {
                    setCropListLookup([...cropListLookup]);
                }

                const alertTypeOriginal = response?.body?.data?.customalerttypes;
                if (alertTypeOriginal?.length > 0) {
                    const filterOption = alertTypeOriginal.map((alert:any) => {
                        return {
                            label : alert,
                            value: alert
                        };
                    })
                    setAlerttypeLookup([...alerttypeLookup, ...filterOption])
                } else {
                    setAlerttypeLookup([...alerttypeLookup])
                }
                
            }
        });
    };

    const loadCustomAdvisoryData = () => {
        let customAdvisoryParams: any = {
            countrycode: loggedUser?.countrycode,
            loggeduserrole: loggedUser?.roleid,
            lob: loggedUser?.lineofbusiness,
            ...params,
            sortby: 'createddate',
            isfiltered: isFiltered,
        };

        if (dateFilter) {
            customAdvisoryParams = {
                ...customAdvisoryParams,
                monthyearfilter: dateFilter,
            };
        }

        if (searchTerm) {
            customAdvisoryParams = {
                ...customAdvisoryParams,
                search: searchTerm,
            };
        }

        if (
            specificPersona.includes(loggedUser.roleid) &&
            loggedUser.assignedloc.length > 0 
        ) {
            let location = loggedUser.assignedloc.map(
                (loc: any) => loc.lochiergeocode
            );
            customAdvisoryParams = {
                ...customAdvisoryParams,
                isfiltered: true,
                [customAdvisoryParams.countrycode === 'TH'
                    ? 'geolevel3code'
                    : 'geolevel4code']: location.join(','),
            };
        }

        if (isFiltered) {
            let condFilter = assignedNull(filter);

            customAdvisoryParams = {
                ...customAdvisoryParams,
                ...condFilter,
            };
        }

        setLoader(true);

        getRequest({
            url: 'advisory/list',
            method: 'GET',
            queryParams: customAdvisoryParams,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    setData(response?.body?.data);
                    setTotalCount(response?.body?.totalcount);
                } else {
                    setErrorMsg(
                        'No data found for current filter. Please change the filters and try again'
                    );
                    setData([]);
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setData([]);
                setLoader(false);
                Alert('error', error?.message);
            });
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let search = event.target.value;
        setSearchTerm(search);
        setRunSearchForCustomAdvisory(true);
    };

    const enterKeyHandler = (event: KeyboardEvent) => {
        setSearchFeildKeyboardEvent(event);
        if (event.key === 'Backspace' || event.key === 'Delete') {
            if (searchTerm.length === 0 || searchTerm === '') {
                urlSearchParams.delete('search');
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setFirstPageLoad(true);
                setMakeAPICall(true);
            }
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (searchTerm.length >= 3) {
                urlSearchParams.set('search', searchTerm);
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setMakeAPICall(true);
            }
        }
    };

    if (runSearchForCustomAdvisory) {
        if (
            searchFeildKeyboardEvent.key === 'Backspace' ||
            searchFeildKeyboardEvent.key === 'Delete'
        ) {
            if (searchTerm.length === 0 || searchTerm === '') {
                urlSearchParams.delete('search');
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setFirstPageLoad(true);
                setMakeAPICall(true);
            }
        } else if (searchFeildKeyboardEvent.key === 'Enter') {
            searchFeildKeyboardEvent.preventDefault();
            if (searchTerm.length >= 3) {
                urlSearchParams.set('search', searchTerm);
                setParams(tableParams());
                setParamsBackToUrl(createSearchParams(urlSearchParams));
                setMakeAPICall(true);
            }
        }

        setRunSearchForCustomAdvisory(false);
    }

    const toPagi = (currentPosts: any[], noOfPages: number) => {
        setDataPerPage(currentPosts);
    };

    const pageNoSelected = (pageNo: number, postsPerPage: number) => {
        pageNo === 1 ? setSelectPageOne(true) : setSelectPageOne(false);
        let offset = (pageNo - 1) * postsPerPage;
        setParams((current) => {
            return { ...current, offset: offset };
        });
        setMakeAPICall(true);
    };

    const handleGetGeoLevelData = (array: any[]) => {
        setGeoLevelList([...array]);
    };

    return (
        <div className='header-advisory'>
            <div className='header-container'>
                <ServiceAlertHeader
                    alertType={'CUSTOM_ADVISORY'}
                    alertDescription={'Device Serial No'}
                    alertDescription2={'Location'}
                    placeHolder='Search Action (min 3 letters)'
                    onCloseHandler={onCloseHandler}
                    closeFlag={closeFlagHandler}
                    isFiltered={isFiltered}
                    isBadgeEnabled={badgeEnabled}
                    filter={filter}
                    isDownloadIcon={true}
                    isFilterIcon={true}
                    isCreateAdvisory={showPersona.includes(loggedUser?.roleid)}
                    searchText={searchTerm}
                    handleSearch={handleSearch}
                    onKeyDown={enterKeyHandler}
                    handleOnClickDownload={() => {
                        downloadAdvisoryPage(
                            searchTerm,
                            dateFilter,
                            isFiltered,
                            filter
                        );
                    }}
                    children={
                        <div className='row-advisory'>
                            {!geoLoader && 
                                <>
                                <div className='advisory_filter_topdiv'>
                                    <div className='row'>
                                        <div className='title-label'>AlertType</div>
                                        <div style={{
                                            paddingTop: '5px',
                                            padddingBottom: '5px'
                                        }}>
                                            <Select
                                                className='advisory_dropdown'
                                                // variant='standard'
                                                defaultValue={
                                                    filter.alerttype
                                                }
                                                // style={customStyles}
                                                size='small'
                                                onChange={(
                                                    evt: any
                                                ) => {
                                                    handleFilter(
                                                        evt.target.value,
                                                        'alerttype'
                                                    )
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            '& .MuiMenuItem-root': {
                                                                fontSize: 11,
                                                                lineHeight: 1.5,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {alerttypeLookup &&
                                                    alerttypeLookup.map(
                                                        (
                                                            option: any
                                                        ) =>
                                                            option.label !==
                                                            filter?.alerttype ? (
                                                                <MenuItem
                                                                    value={
                                                                        option.label
                                                                    }>
                                                                    {
                                                                        option.label
                                                                    }
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem
                                                                    disabled
                                                                    value={
                                                                        option.label
                                                                    }>
                                                                    {
                                                                        option.label
                                                                    }
                                                                </MenuItem>
                                                            )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='title-label'>Crop</div>
                                        <div style={{
                                            paddingTop: '5px',
                                            padddingBottom: '5px'
                                        }}>
                                            <Select
                                                className='advisory_dropdown'
                                                // variant='standard'
                                                defaultValue={
                                                    filter.cropname
                                                }
                                                // style={customStyles}
                                                size='small'
                                                onChange={(
                                                    evt: any
                                                ) => {
                                                    handleFilter(
                                                        evt.target.value,
                                                        'cropname'
                                                    )
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            '& .MuiMenuItem-root': {
                                                                fontSize: 11,
                                                                lineHeight: 1.5,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {cropListLookup &&
                                                    cropListLookup.map(
                                                        (
                                                            option: any
                                                        ) =>
                                                            option.label !==
                                                            filter?.cropname ? (
                                                                <MenuItem
                                                                    value={
                                                                        option.label
                                                                    }>
                                                                    {
                                                                        option.label
                                                                    }
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem
                                                                    disabled
                                                                    value={
                                                                        option.label
                                                                    }>
                                                                    {
                                                                        option.label
                                                                    }
                                                                </MenuItem>
                                                            )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                </>
                            }
                            <GeoLevelDropdown
                                getGeoLevelData={handleGetGeoLevelData}
                                geoLevelList={geoLevelList}
                                isClearGeoLevel={isClearGeoLevel}
                                filter={filter}
                                handleFilter={handleFilter}
                                handleLoader={(isLoader:boolean)=>setGeoLoader(isLoader)}
                            />
                            {!geoLoader &&
                                <> 
                                <FromDateToDate
                                    handleFilter={handleFilter}
                                    filter={filter}
                                />
                                <div className='filter_buttondiv'>
                                    <CustomButton
                                        label={'Reset'}
                                        className='cus-btn-filter reset'
                                        handleClick={handleResetFilter}
                                    />
                                    <CustomButton
                                        label={'Apply'}
                                        style={{
                                            borderRadius: '20px',
                                            backgroundColor: '#7eb343',

                                            border: 0,
                                            boxShadow: '0px 3px 6px #c7c7c729',
                                        }}
                                        className='cus-btn'
                                        handleClick={handleApplyFilter}
                                    />
                                </div>
                                </>
                            }
                        </div>
                    }
                    setMakeAPICall={setMakeAPICall}
                />
            </div>
            {loader ? <Loader /> : null}
            <div className='calender-advisory'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['year', 'month']}
                        value={valueDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={handleCalendarChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className='myDatePicker'
                                variant='standard'
                                helperText={null}
                                sx={{ borderBottom: 1 }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
            <div className='advisory-middle-section'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{
                            xs: 1,
                            sm: 1,
                            md: 9,
                            lg: 12,
                            xl: 15,
                        }}>
                        {data.length ? (
                            data.map((item, index) => (
                                <Grid
                                    item
                                    xs={1}
                                    sm={1}
                                    md={3}
                                    lg={4}
                                    xl={5}
                                    key={index}>
                                    <CustomAdvisoryModal item={item} />
                                </Grid>
                            ))
                        ) : (
                            <div className='advisory_error_msg'>{errorMsg}</div>
                        )}
                    </Grid>
                </Box>
            </div>
            <div className='footer_advisory'>
                {data.length ? (
                    <Footer>
                        <Pagination
                            postsTitle='Custom Advisory(s)'
                            posts={data}
                            onIndex={toPagi}
                            postsPerPage={10}
                            onIndexPageNo={pageNoSelected}
                            totalCountAvailable={totalCount}
                            selectPageOne={selectPageOne}
                        />
                    </Footer>
                ) : null}
            </div>
        </div>
    );
};

export default CustomAdvisory;
